import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

import {
  AlarmViewModel,
  BinSchemeViewModel,
  CableSchemeViewModel,
  LocationKtxTemperatureRangeUpdatedHubMessage,
  SensorKtxSchemeViewModel,
  TelemetrySnapshotViewModel,
  TemperatureRangeViewModel
} from '../../../models';
import { BinTypeEnum, ColumnTypeEnum, MeasureEnum, SensorStatusEnum } from '../../../enums';
import { BasePopupBehavior } from '../common';
import { HubService, LocationsKtxDataSourceService } from '../../../services';
import { ConvertDataFunctions } from '../../../../common';

@Component({
  selector: 'greensleeves-ktx-bin-details-popup',
  templateUrl: './ktx-bin-details-popup.component.html',
})
export class KtxBinDetailsPopupComponent extends BasePopupBehavior implements OnInit, OnDestroy, AfterViewInit {
  private static WINDOW_FUNCTION_GET_SCHEME_NAME = 'getScheme';
  private static WINDOW_FUNCTION_GET_TELEMETRY_NAME = 'getTelemetry';
  private static WINDOW_FIELD_IS_WEB_NAME = 'isWeb';
  private static WINDOW_FUNCTION_DISPLAY_SCHEME_NAME = 'displayScheme';
  @ViewChild('model2d', { static: false }) iframe2d: ElementRef;
  @ViewChild('model3d', { static: false }) iframe3d: ElementRef;
  @ViewChild('fullScreenContainer', { static: false }) fullScreenContainer: ElementRef;
  _isHidden = true;
  _isSchemeHidden = true;
  _isSchemeLoading = true;
  _gridData: CableSchemeViewModel[] = [];
  _resistanceGridData: CableSchemeViewModel[] = [];
  _is2dDisplayMode = true;
  _currentBin: BinSchemeViewModel;
  _columnType = ColumnTypeEnum;
  _binTypeEnum = BinTypeEnum;
  _isShowScheme: boolean = false;
  _isLoading: boolean;
  onViewCableDetailsClick = new EventEmitter<BinSchemeViewModel>();
  _columnsView = [];
  _sortField = 'name';
  temperatureTelemetry: TelemetrySnapshotViewModel;
  resistanceTelemetry: TelemetrySnapshotViewModel;

  _frozenColumns = [{ header: 'Cable', columnType: ColumnTypeEnum.CableName, dataField: 'name', width: 100 }];
  private subscriptions: Subscription[] = [];
  private schemeData = new BehaviorSubject<{
    binScheme: BinSchemeViewModel,
    alarm: AlarmViewModel,
    temperatureRanges: TemperatureRangeViewModel[]
  }>(null);
  private telemetry = new BehaviorSubject<TelemetrySnapshotViewModel>(null);
  private displayScheme = new BehaviorSubject<boolean>(false);
  private isFahrenheitMeasureMode: boolean;
  private isTemperatureMeasureMode: boolean = true;
  private receivingTimestamp: string;
  _isNotifcationCurrentData: boolean = false;

  get _isFahrenheitMeasureMode() {
    return this.isFahrenheitMeasureMode;
  }
  set _isFahrenheitMeasureMode(value: boolean) {
    this.isFahrenheitMeasureMode = value;
  }
  get _isTemperatureMeasureMode() {
    return this.isTemperatureMeasureMode;
  }
  set _isTemperatureMeasureMode(value: boolean) {
    this.isTemperatureMeasureMode = value;

    if (this._currentBin.displaySchemes) {
      if (value === true) {
        this.telemetry.next(this.temperatureTelemetry);
      } else {
        this.telemetry.next(this.resistanceTelemetry);
      }
    }
  }
  get _frozenWidth() {
    return `${this._frozenColumns.reduce((a, v) => a + v.width, 0)}px`;
  }
  get _lastUpdatingStatus() {
    let statusText = "Last updating: ";
    if (this._isLoading) {
      statusText = statusText + "Receiving data";
    } else if (this.receivingTimestamp && !this._isLoading) {
      statusText = statusText + this.receivingTimestamp;
    } else {
      return '';
    }

    return statusText;
  }

  constructor(
    private _el: ElementRef,
    private _locationKtxDataSourceService: LocationsKtxDataSourceService,
    private _hubService: HubService,
    private _cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this._currentBin = new BinSchemeViewModel();
    this.temperatureTelemetry = new TelemetrySnapshotViewModel();
    this.resistanceTelemetry = new TelemetrySnapshotViewModel();

    window[KtxBinDetailsPopupComponent.WINDOW_FUNCTION_GET_SCHEME_NAME] = () => {
      return this.schemeData;
    };
    window[KtxBinDetailsPopupComponent.WINDOW_FUNCTION_GET_TELEMETRY_NAME] = () => {
      return this.telemetry;
    };
    window[KtxBinDetailsPopupComponent.WINDOW_FIELD_IS_WEB_NAME] = true;
    window[KtxBinDetailsPopupComponent.WINDOW_FUNCTION_DISPLAY_SCHEME_NAME] = () => {
      return this.displayScheme;
    }

    let telemetrySub = this._locationKtxDataSourceService.telemetry$.subscribe((telemetry) => {
      if (telemetry) {
        if (this._currentBin.displaySchemes) {
          this.telemetry.next(telemetry);
        }

        if (this.isTemperatureMeasureMode) {
          this.temperatureTelemetry = telemetry;
          this.mapTelemetryToGridData(this._gridData, telemetry);
        } else {
          this.resistanceTelemetry = telemetry;
          this.mapTelemetryToGridData(this._resistanceGridData, telemetry);
        }
      }
    });

    let hubSub = this._hubService.temperatureRangeUpdated.subscribe((message: LocationKtxTemperatureRangeUpdatedHubMessage) => {
      if (message.updatedRanges.locationId === this._currentBin.locationId) {
        this._currentBin.temperatureRanges = message.updatedRanges.ranges;
        this._currentBin.alarm = message.updatedRanges.alarm;

        this.schemeData.next({
          binScheme: this._currentBin,
          alarm: this._currentBin.alarm,
          temperatureRanges: this._currentBin.temperatureRanges
        });
      };
    });

    let lastTelemetrySub = this._locationKtxDataSourceService.lastTelemetry$.subscribe((telemetry: TelemetrySnapshotViewModel) => {
      if (telemetry) {
        const highLimitValue: number = this._currentBin.alarm && this._currentBin.alarm.highTemperatureValue;

        if (highLimitValue && telemetry.snapshot) {
          telemetry.snapshot.forEach(x => {
            if (x.sensors) {
              x.sensors.forEach(y => {
                y.status = y.value >= highLimitValue ? SensorStatusEnum.HighLimit : y.status;
              })
            }
          })
        }
        this.mapTelemetryToGridData(this._gridData, telemetry);

        if (this._currentBin.displaySchemes) {
          this.telemetry.next(telemetry);
        }
      }
    })
    this.subscriptions.push(telemetrySub, hubSub, lastTelemetrySub);
  }

  ngOnDestroy() {
    this.destroyMainScrollBar();
    this.destroyTableScrollBar();
    this.subscriptions && this.subscriptions.forEach(sub => sub.unsubscribe());
    super.ngOnDestroy();
  }

  ngAfterViewInit() {
    this.reInitScrollBarTable(0);
    this.reInitScrollBar(0);
  }

  onIframeLoad() {
    this._isSchemeLoading = false;
  }

  splitStringBySpace(input: string): string[] {
    return input.split(' ');
  }

  async onClose() {
    this._isHidden = true;
    this._columnsView = [];
    this._gridData = [];
    this._resistanceGridData = [];
    this.temperatureTelemetry = new TelemetrySnapshotViewModel();
    this.resistanceTelemetry = new TelemetrySnapshotViewModel();
    this.isTemperatureMeasureMode = true;
    this._currentBin = new BinSchemeViewModel();
    this.displayScheme.next(false);
    this.receivingTimestamp = null;
    this._isLoading = false;
    this._isNotifcationCurrentData = false;
  }

  onFullScreenClose() {
    this._isSchemeHidden = true;
    const modelHolder = this._el.nativeElement.querySelector('.popup-iframe');

    if (this._is2dDisplayMode) {
      modelHolder.appendChild(this.iframe2d.nativeElement);
      this.iframe2d.nativeElement.setAttribute('height', '300');
      this.iframe2d.nativeElement.setAttribute('width', '300');
    } else {
      this.iframe3d.nativeElement.setAttribute('height', '300');
      this.iframe3d.nativeElement.setAttribute('width', '300');
      modelHolder.appendChild(this.iframe3d.nativeElement);
    }
  }

  public showFullScreen() {
    this._isSchemeHidden = false;
    this._isSchemeLoading = true;
    if (this._is2dDisplayMode) {
      this.fullScreenContainer.nativeElement.appendChild(this.iframe2d.nativeElement);
      this.iframe2d.nativeElement.setAttribute('height', '600');
      this.iframe2d.nativeElement.setAttribute('width', '600');
    } else {
      this.fullScreenContainer.nativeElement.appendChild(this.iframe3d.nativeElement);
      this.iframe3d.nativeElement.setAttribute('height', '600');
      this.iframe3d.nativeElement.setAttribute('width', '600');
    }
  }

  public async show(bin: BinSchemeViewModel) {
    this._isHidden = false;
    this._currentBin = bin;
    this._isShowScheme = bin.displaySchemes;
    this.isFahrenheitMeasureMode = true;

    if (bin.cables) {
      this._resistanceGridData = JSON.parse(JSON.stringify(bin.cables));
      this._gridData = JSON.parse(JSON.stringify(bin.cables));

      const maxTcCount = Math.max.apply(Math, bin.cables.map((o) => o.thermocoupleCount));
      for (let i = 1; i <= maxTcCount; i++) {
        this._columnsView.push({ header: 'TC ' + i, columnType: ColumnTypeEnum.TC, dataField: 'sensors' })
      }

      this.reInitScrollBarTable(0);
    }

    this._isLoading = true;
    const listOfCableIds = bin.cables ? bin.cables.map(x => { return x.id }) : [];
    await this._locationKtxDataSourceService.getTelemetry(bin.id, listOfCableIds);

    this.schemeData.next({
      binScheme: this._currentBin,
      alarm: this._currentBin.alarm,
      temperatureRanges: bin.temperatureRanges
    });

    if (bin.displaySchemes) {
      this.displayScheme.next(bin.displaySchemes);
    }
    this._isLoading = false;

    this._cdr.detectChanges();
  }

  async showWithLoading(locationId: number, binId: number) {
    this._isHidden = false;
    this._isLoading = true;
    this._isNotifcationCurrentData = true;
    this._currentBin.name = 'Loading...'
    this.loadBinSchema(locationId, binId);

  }

  private loadBinSchema(locationId: number, binId: number) {
    this._locationKtxDataSourceService.getBinSchema(locationId, binId).then((value) => {
      if (value instanceof BinSchemeViewModel) {
        this.show(value);
      }
    });
  }

  private reInitScrollBar(timeout?: number) {
    const el = this._el.nativeElement.querySelector('.popup-container');
    this.reInitMainScrollBar(el, timeout);
  }

  reInitScrollBarTable(timeout?: number, withCheck = false) {
    const el = this._el.nativeElement.querySelector('.ui-table-scrollable-view.ui-table-unfrozen-view .ui-table-scrollable-body');
    if (el) {
      this.reInitTableScrollBar(el, timeout);
    }
  }

  onClickViewCables() {
    this.onViewCableDetailsClick.emit(this._currentBin);
  }

  onClickReadSiloData() {
    this.receivingTimestamp = null;
    this._isLoading = true;
    this._locationKtxDataSourceService.ReadSiloData(this._currentBin.id, this.isTemperatureMeasureMode ? MeasureEnum.Temperature : MeasureEnum.Resistance).then(() => {
      this._isLoading = false;
      this.reInitScrollBarTable(0);
    })
  }

  _getSensorColor(cable: CableSchemeViewModel, sensorIndex: number): string {
    const sensor = cable && cable.sensors ? cable.sensors[sensorIndex] as SensorKtxSchemeViewModel : null;

    if (sensor) {
      if (sensor.status === SensorStatusEnum.OpenTC) {
        return this._currentBin.alarm.openTcColorHex;
      }

      else if (sensor.status === SensorStatusEnum.NoResponse) {
        return this._currentBin.alarm.noResponseColorHex;
      }

      else if (this.isTemperatureMeasureMode) {
        if (sensor.status === SensorStatusEnum.HighLimit) {
          return this._currentBin.alarm.highTemperatureColorHex;
        }

        else if (sensor.status === SensorStatusEnum.MaxRise) {
          return this._currentBin.alarm.maxRiseColorHex;
        }

        else if (this._currentBin.temperatureRanges) {
          const temperatureRange = this._currentBin.temperatureRanges.find(x => sensor.value >= x.from && sensor.value <= x.to);
          return temperatureRange == undefined ? '' : temperatureRange.colorHex;
        }

        return '-';
      }
      else {
        if (sensor.status === SensorStatusEnum.HighLimit) {
          return this._currentBin.alarm.highResistanceColorHex;
        }

        return this._currentBin.alarm.normalResistanceColorHex;
      }
    }
    return '-';
  }

  _getSensorValue(cable: CableSchemeViewModel, sensorIndex: number): string | number {
    const sensor = cable && cable.sensors ? cable.sensors[sensorIndex] as SensorKtxSchemeViewModel : null;

    if (sensor) {
      if (sensor.status === SensorStatusEnum.OpenTC) {
        return 'OT';
      }

      if (sensor.status === SensorStatusEnum.NoResponse) {
        return '-';
      }

      if (this.isFahrenheitMeasureMode && this.isTemperatureMeasureMode) {
        const FahrenheitValue = this.onCelsiusToFahrenheit(sensor.value);
        return FahrenheitValue;
      }

      return sensor.value;
    }
    return '';
  }

  onCelsiusToFahrenheit(value: number): string {
    if (value || value === 0) {
      const convertedValue = ConvertDataFunctions.celsiusToFahrenheit(value);
      return convertedValue.toString();
    } else {
      return '-';
    }
  }

  getBackGroundTextColor(cable: CableSchemeViewModel, sensorIndex: number): string {
    const sensor = cable ? cable[sensorIndex] as SensorKtxSchemeViewModel : null;

    if (sensor) {
      if (sensor.status === SensorStatusEnum.OpenTC) {
        return '#FFFFFF';
      }
    }

    return '';
  }

  private mapTelemetryToGridData(gridData: CableSchemeViewModel[], telemetry: TelemetrySnapshotViewModel) {
    if (gridData) {
      gridData.map(cable => {
        if (telemetry.snapshot) {
          if (telemetry.recievingTimestamp) {
            const date = new Date(telemetry.recievingTimestamp * 1000);
            this.receivingTimestamp = date.toLocaleString("en-US");
          }

          var sensor = telemetry.snapshot.find(x => x.cableId == cable.id);
          if (sensor) {
            cable.sensors = sensor.sensors;
          }
        }
      });
    }
  }
}