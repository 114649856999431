import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import PerfectScrollbar from 'perfect-scrollbar';

import { ConvertDataFunctions, LocalStorageService, UserRoleEnum } from '../../../common';
import { LocationsDataSourceService } from '../../services';
import { ColumnTypeEnum, TutorialMenuEnum } from '../../enums';
import { EmailServiceViewModel } from '../../models';
import { EmailServiceDataSourceService } from '../../services';
import { EmailServiceScheduleLable } from '../../constants';
import { EmailServiceAddEditPopupComponent, EmailServiceDeletePopupComponent, TutorialPopupComponent } from '../popups/';

@Component({
  selector: 'greensleeves-email-management-tab',
  templateUrl: './email-management-tab.component.html',
})
export class EmailManagementTabComponent implements OnInit {

  @ViewChild(EmailServiceAddEditPopupComponent, { read: false, static: false })
  _addEditModal: EmailServiceAddEditPopupComponent;

  @ViewChild(EmailServiceDeletePopupComponent, { read: false, static: false })
  _deleteModal: EmailServiceDeletePopupComponent;

  @ViewChild(TutorialPopupComponent, { read: false, static: false })
  private tutorialPopup: TutorialPopupComponent;

  _ps: PerfectScrollbar;
  _sortField = 'emails';
  _sortOrder = -1;
  _loading: boolean = false;
  _role: UserRoleEnum;
  private subscriptions: Subscription[] = [];

  get _hasLocations(): boolean {
    return this._btxLocations.length === 0 && this._ktxLocations.length === 0 && this._itxLocations.length === 0;
  }

  _gridData: EmailServiceViewModel[] = [];
  _columnTypeEnum = ColumnTypeEnum;

  _btxLocations: { label: string, value: number }[] = [];
  _ktxLocations: { label: string, value: number }[] = [];
  _itxLocations: { label: string, value: number }[] = [];

  get _columns() {
    return [
      ...[{ header: 'Emails', columnType: ColumnTypeEnum.ServiceEmail, dataField: 'emails' }],
      ...[{ header: 'BTX Locations', columnType: ColumnTypeEnum.UserLocationNames, dataField: 'btxLocationNames' }],
      ...[{ header: 'KTX Locations', columnType: ColumnTypeEnum.UserLocationNames, dataField: 'ktxLocationNames' }],
      ...[{ header: 'ITX Locations', columnType: ColumnTypeEnum.UserLocationNames, dataField: 'itxLocationNames' }],
      { header: 'Schedule', columnType: ColumnTypeEnum.Schedule, dataField: 'scheduleTime' },
      { header: '', columnType: ColumnTypeEnum.Edit, width: "46" },
      { header: '', columnType: ColumnTypeEnum.Delete, width: "46" },
      { header: '', columnType: ColumnTypeEnum.Expand, width: "46" }
    ];
  }

  constructor(
    private _localStorageService: LocalStorageService,
    private _locationsService: LocationsDataSourceService,
    private _emailServiceDataService: EmailServiceDataSourceService,
    private _el: ElementRef,
    private _zone: NgZone,
  ) { }

  ngOnInit() {
    this._loading = true;
    this.reInitScrollBar(0);
    const info = this._localStorageService.getLoginInfo();
    this._role = info ? info.role : null;

    let subscriptionLocationBtx = this._locationsService.btxLocationsForCompany$.subscribe(data => {
      if (data) {
        this._btxLocations = data.map(x => ({ label: x.locationName, value: x.id }));
      }
    });

    let subscriptionLocationKtx = this._locationsService.ktxLocationsForCompany$.subscribe(data => {
      if (data) {
        this._ktxLocations = data.map(x => ({ label: x.locationName, value: x.id }));
      }
    });

    let subscriptionLocationItx = this._locationsService.itxLocationsForCompany$.subscribe(data => {
      if (data) {
        this._itxLocations = data.map(x => ({ label: x.locationName, value: x.id }));
      }
    });

    this.subscriptions.push(subscriptionLocationBtx);
    this.subscriptions.push(subscriptionLocationKtx);
    this.subscriptions.push(subscriptionLocationItx);

    let subscriptionEmailService = this._emailServiceDataService.emailServices$.subscribe(data => {
      if (data) {
        this._loading = false;
        this._gridData = data;
      }
    });

    this.subscriptions.push(subscriptionEmailService);
    this._locationsService.getKtxBtxItxLocationForCompany(info ? info.companyId : 0);
    this._emailServiceDataService.getAll(info ? info.companyId : 0)
  }

  ngOnDestroy() {
    this.subscriptions && this.subscriptions.forEach(sub => sub.unsubscribe());
    this.subscriptions = [];
  }

  onClickAdd() {
    this._addEditModal.onShow(null);
  }

  onClickEdit(emailService: EmailServiceViewModel) {
    this._addEditModal.onShow(emailService, false);
  }

  onClickDelete(emailService: EmailServiceViewModel) {
    this._deleteModal.onShow(emailService.id);
  }

  getSchedule(emailService: EmailServiceViewModel): string {
    if (emailService) {
      return EmailServiceScheduleLable[emailService.scheduleType] + ' at ' + ConvertDataFunctions.secondsToHHMMString(emailService.scheduleTime);
    }
    return '';
  }

  public reInitScrollBar(timeout?: number, withCheck = false) {
    if (withCheck) {
      return;
    }

    if (this._ps) {
      this._ps.destroy();
    }

    const createScrollbarFunc = () => {
      this._zone.runOutsideAngular(() => {
        try {
          const el = this._el.nativeElement.querySelector('.ui-table-scrollable-view.ui-table-unfrozen-view .ui-table-scrollable-body');
          if (!el) {
            return;
          }

          this._ps = new PerfectScrollbar(el, {
            wheelSpeed: 2,
            wheelPropagation: true,
            minScrollbarLength: 20
          });
        } catch (error) {
        }
      })
    };

    if (timeout) {
      setTimeout(() => {
        createScrollbarFunc();
      }, timeout);
    } else {
      createScrollbarFunc();
    }
  }

  showTutorial() {
    this.tutorialPopup.showPopup(TutorialMenuEnum.EmailManagement);
  }
}
