import PerfectScrollbar from 'perfect-scrollbar';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, OnInit, ElementRef, NgZone, AfterViewInit, Input, OnDestroy, EventEmitter, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { ItxBinViewModel } from './../../../../models';
import { ItxBinDataSourceService } from './../../../../services';
import { ColumnTypeEnum, ItxBinTypeEnum, CapacityUnitEnum, SizeMeasureEnum } from '../../../../enums';
import { ItxBinIconClass, ItxBinTypeLabel, CapacityUnitLabel } from '../../../../constants';
import { ItxListAddRadarPopupComponent, ItxBinDeletePopupComponent } from '@popups';
import { ConvertDataFunctions } from '../../../../../common';

@Component({
  selector: 'greensleeves-itx-bin-grid',
  templateUrl: './itx-bin-grid.html',
})

export class ItxBinGridComponent implements OnInit, OnDestroy, AfterViewInit {
  static STORAGE_NAME_KEY = 'name';
  static STORAGE_TYPE_KEY = 'binType';
  static SORT_ORDER_DEFAULT = 1;

  @ViewChild(ItxBinDeletePopupComponent, { read: false, static: false })
  private deleteModal: ItxBinDeletePopupComponent;

  @Input() _location: { id: number, name: string };

  _columnTypeEnum = ColumnTypeEnum;
  _sortField = ItxBinGridComponent.STORAGE_NAME_KEY;
  _sortOrder = ItxBinGridComponent.SORT_ORDER_DEFAULT;
  _gridData: ItxBinViewModel[];
  _storageNameKey = ItxBinGridComponent.STORAGE_NAME_KEY;
  _searchPhrase: string = '';
  _ps: PerfectScrollbar;
  public onEditClicked = new EventEmitter<ItxBinViewModel>();

  _columnsView = [
    { header: 'Storage Icon', columnType: ColumnTypeEnum.StorageIcon, dataField: 'binType' },
    { header: 'Storage Name', columnType: ColumnTypeEnum.StorageName, dataField: 'name' },
    { header: 'Storage Type', columnType: ColumnTypeEnum.StorageType, dataField: 'binType' },
    { header: 'Total Capacity', columnType: ColumnTypeEnum.Capacity, dataField: 'totalCapacity' },
    { header: 'Capacity Unit', columnType: ColumnTypeEnum.CapacityUnit, dataField: 'capacityUnit' },
    { header: 'Stored Material', columnType: ColumnTypeEnum.StoredMaterial, dataField: 'material' },
    { header: 'Number Of Radars', columnType: ColumnTypeEnum.Bins, dataField: 'countOfRadars' },
  ]

  _frozenColumns = [
    { header: '', columnType: ColumnTypeEnum.Radar, width: 46 },
    { header: '', columnType: ColumnTypeEnum.Edit, width: 46 },
    { header: '', columnType: ColumnTypeEnum.Delete, width: 46 }
  ]

  _columnsViewLength: number = 0;

  _searchForm: FormGroup;
  _lastSearchPhrase: string;
  subscriptions: Subscription[] = [];

  get _frozenWidth() {
    return `${this._frozenColumns.reduce((a, v) => a + v.width, 0)}px`;
  }

  @ViewChild(ItxListAddRadarPopupComponent, { read: false, static: false })
  _radarListComponent: ItxListAddRadarPopupComponent;

  constructor(
    private _el: ElementRef,
    private _zone: NgZone,
    formBuilder: FormBuilder,
    private _itxBinDataSerivece: ItxBinDataSourceService) {
    this._searchForm = formBuilder.group({
      searchPhrase: [''],
    });
  }

  ngOnInit() {
    this.reInitScrollBar(0);
    let itxSub = this._itxBinDataSerivece.itxBin$.subscribe(item => {
      if (item) {
        this._gridData = [];
        item.forEach(bin => {
          let getBin = ItxBinViewModel.ToNewViewModel(bin);
          getBin.totalCapacity = this.getCapacityValue(getBin.totalCapacity, getBin.capacityUnit, getBin.binType);
          getBin.height = this.getSizeValueFromFt(getBin.height, getBin.sizeMeasure);
          getBin.width = this.getSizeValueFromFt(getBin.width, getBin.sizeMeasure);
          getBin.depth = this.getSizeValueFromFt(getBin.depth, getBin.sizeMeasure);
          if (getBin.roofCap != null) {
            getBin.roofCap = this.getSizeValueFromFt(getBin.roofCap, getBin.sizeMeasure);
          }
          this._gridData.push(getBin);
        });
      }
    });
    this._itxBinDataSerivece.getItxBinsByLocationId(this._location.id);
    this.subscriptions.push(itxSub);
    this._columnsViewLength = this._columnsView.length + 1;
  }

  ngOnDestroy() {
    this.subscriptions && this.subscriptions.forEach((sub) => sub.unsubscribe());
    this._ps && this._ps.destroy();
    this._ps = null;
    this._gridData = [];
  }

  ngAfterViewInit() {
    this.reInitScrollBar(0);
  }

  onClickRadar(itxBinViewModel: ItxBinViewModel) {
    this._itxBinDataSerivece.markItxBinRadarForOthersAsInUpdate(itxBinViewModel.id, true);
    this._radarListComponent.show(itxBinViewModel);
  }

  onClickEdit(itxBin: ItxBinViewModel) {
    this.onEditClicked.emit(itxBin);
  }

  onClickDelete(itxBin: ItxBinViewModel) {
    this.deleteModal.show(itxBin);
  }

  onUpdateLastSearch() {
    this._lastSearchPhrase = this._searchForm.controls.searchPhrase.value;
  }

  public reInitScrollBar(timeout?: number, withCheck = false) {
    if (withCheck) {
      return;
    }

    if (this._ps) {
      this._ps.destroy();
    }

    const createScrollbarFunc = () => {
      this._zone.runOutsideAngular(() => {
        try {
          const el = this._el.nativeElement.querySelector('.ui-table-scrollable-view.ui-table-unfrozen-view .ui-table-scrollable-body');
          if (!el) {
            return;
          }

          this._ps = new PerfectScrollbar(el, {
            wheelSpeed: 2,
            wheelPropagation: true,
            minScrollbarLength: 20
          });
        } catch (error) {
        }
      })
    };

    if (timeout) {
      setTimeout(() => {
        createScrollbarFunc();
      }, timeout);
    } else {
      createScrollbarFunc();
    }
  }

  getBinClass(binType: ItxBinTypeEnum): string {
    return ItxBinIconClass.getIconClass(binType);
  }

  getBinTypeLabel(binType: ItxBinTypeEnum): string {
    return ItxBinTypeLabel[binType];
  }

  getCapacityUnitLabel(capasityUnit: CapacityUnitEnum): string {
    return CapacityUnitLabel[capasityUnit];
  }

  private getSizeValueFromFt(value: number, sizeUnit: SizeMeasureEnum): number {
    let result = ConvertDataFunctions.convertSizeFromFt(value, sizeUnit);
    return Math.round(result * 100) / 100;
  }

  getCapacityValue(value: number, capacityUnit: CapacityUnitEnum, binType: ItxBinTypeEnum): number {
    let result: number;
    switch (binType) {
      case ItxBinTypeEnum.LiquidStorageConical:
      case ItxBinTypeEnum.LiquidStorageFlat:
      case ItxBinTypeEnum.LiquidStorageSloped:
        result = ConvertDataFunctions.convertFromGal(value, capacityUnit);
        break;
      default:
        result = ConvertDataFunctions.convertFromFt3(value, capacityUnit);
    }
    return Math.round(result * 100) / 100;
  }
}
