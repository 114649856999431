import { Component, ViewChildren, ElementRef, QueryList, OnInit, OnDestroy, ChangeDetectorRef, EventEmitter, AfterViewChecked, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormGroup, FormBuilder } from '@angular/forms';

import { LocationsBtxDataSourceService } from '../../../services';
import { ErrorModel, ErrorCodeEnum } from '../../../../common';
import { BasePopupBehavior } from '../common';
import { UploadEquipmentPopupComponent } from '../upload-equipment-popup';
import { BTXLocationConfiguration, LocationBtxViewModel } from '@models';

@Component({
  selector: 'greensleeves-upload-btx-location-configuration-file-popup',
  templateUrl: './upload-btx-location-configuration-file-popup.component.html',
  styles: []
})
export class UploadBtxLocationConfigurationFilePopupComponent extends BasePopupBehavior implements OnInit, OnDestroy, AfterViewChecked {
  public onUploadSuccess = new EventEmitter<[string, number]>();
  @ViewChildren('popupConfiguration') popupConfigurationEl: QueryList<ElementRef>;

  @ViewChild(UploadEquipmentPopupComponent, { read: false, static: false })
  private _uploadEquipmentPopupComponent: UploadEquipmentPopupComponent;

  @Output()
  public isNeedToPreventClose = new EventEmitter<boolean>();

  _formGroup: FormGroup;

  _isHidden = true;
  _isNeedToPreventClose = true;
  _submitted = false;
  _isLoading = false;

  _location: LocationBtxViewModel;
  _errorMessage: string = null;

  _isFileUploaded = false;

  get _fileName(): string {
    return this.fileToUpload ? this.fileToUpload.name : '';
  }

  get _isSubmitDisabled(): boolean {
    return this._isLoading;
  }

  private locationId: number;
  private fileToUpload: File;
  private isNeedToreInitScrollbar = false;

  private _subscriptions: Subscription[] = [];

  constructor(
    private _locationsBtxDataSourceService: LocationsBtxDataSourceService,
    private cdRef: ChangeDetectorRef,
    private formBuilder: FormBuilder,
  ) {
    super();
  }

  ngOnInit() {
    this._formGroup = this.formBuilder.group({});
  }

  ngAfterViewChecked() {
    if (this._isFileUploaded && this.isNeedToreInitScrollbar) {
      this.isNeedToreInitScrollbar = false;
    }
  }

  ngOnDestroy() {
    this._subscriptions && this._subscriptions.forEach(sub => sub.unsubscribe());
  }

  async onSubmit() {
    this._submitted = true;

    if (!this.fileToUpload) {
      this._errorMessage = 'File is required';
      this._submitted = false;
      return;
    }

    this.uploadConfigurationFile();
    this._isLoading = false;
  }

  onClickClose() {
    this._isHidden = false;
    this.closePopup();
    this.isNeedToPreventClose.emit(false);
  }

  onSelect(onSelectEvent: any) {
    const uploadFile = onSelectEvent.currentFiles[0] as File;
    if (uploadFile.size == 0) {
      this._errorMessage = 'File size should be more than 0.';
    } else if (!uploadFile.name.includes('.json') || uploadFile.type != 'application/json') {
      this._errorMessage = 'Invalid file format.';
    } else {
      this._errorMessage = null;
      this.fileToUpload = uploadFile;
      this._isFileUploaded = false;
    }
  }

  public show(location: LocationBtxViewModel) {
    this._isLoading = false;
    this.locationId = location.id;
    this._location = location;
    this._submitted = false;
    this._isHidden = false;
    this.cdRef.detectChanges();
  }

  private closePopup() {
    this._isHidden = true;
    this.fileToUpload = null;
    this._errorMessage = null;
    this._isFileUploaded = false;
    this._formGroup = this.formBuilder.group({});
    this.isNeedToPreventClose.emit(false);
  }

  private async uploadConfigurationFile() {
    const result = await this._locationsBtxDataSourceService.uploadToParseConfigurationFile(this.fileToUpload, this.locationId);
    if (result) {
      if (result instanceof ErrorModel) {
        if (result.code == ErrorCodeEnum.InvalidModelState) {
          this._errorMessage = result.errors[0];
        } else {
          this._errorMessage = result.message;
        }
      } else {
        this._isFileUploaded = true;
        this.isNeedToreInitScrollbar = true;
        const equipment = result as BTXLocationConfiguration;
        this._uploadEquipmentPopupComponent.show(this._location, equipment.equipments, equipment.isAnyEquipmentAlreadyExist);
      }
      this._submitted = false;
    }
  }
}