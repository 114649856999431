import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, OnInit, OnDestroy, ElementRef, AfterViewInit, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';

import { BasePopupBehavior } from '../common';
import { BinSchemeViewModel, BinViewModel, LocationKtxSchemeViewModel, LocationKtxViewModel } from '../../../models';
import { BinTypeEnum, ColumnTypeEnum } from '../../../enums';
import { LocationsKtxDataSourceService } from '../../../services';

@Component({
  selector: 'greensleeves-ktx-location-bins-popup',
  templateUrl: './ktx-location-bins-popup.component.html',
})
export class KtxLocationBinsPopupComponent extends BasePopupBehavior implements OnInit, OnDestroy, AfterViewInit {
  static NAME_FIELD = 'name';
  static HEIGHT_FIELD = 'height';
  static DEPTH_FIELD = 'depth';
  static WIDTH_FIELD = 'width';
  static TYPE_FIELD = 'binType';
  _displayScheme = 'displaySchemes';

  _sortField = KtxLocationBinsPopupComponent.NAME_FIELD;
  _isHidden = true;
  _isLoading = false;
  _searchForm: FormGroup;
  _location: LocationKtxViewModel;
  _gridData: BinSchemeViewModel[] = [];
  _sortOrder = 1;
  _columnTypeEnum = ColumnTypeEnum;
  _lastSearchPhrase: string;
  _binTypes = BinTypeEnum;
  scheme: LocationKtxSchemeViewModel;
  subscriptions: Subscription[] = [];

  onDetailsClick = new EventEmitter<BinSchemeViewModel>();
  onUnitDetailsClick = new EventEmitter<BinSchemeViewModel>();

  _columnsView = [
    { header: 'Bin name', columnType: ColumnTypeEnum.BinName, dataField: KtxLocationBinsPopupComponent.NAME_FIELD },
    { header: 'Height', columnType: ColumnTypeEnum.Height, dataField: KtxLocationBinsPopupComponent.HEIGHT_FIELD },
    { header: 'Depth', columnType: ColumnTypeEnum.Depth, dataField: KtxLocationBinsPopupComponent.DEPTH_FIELD },
    { header: 'Width', columnType: ColumnTypeEnum.Width, dataField: KtxLocationBinsPopupComponent.WIDTH_FIELD },
    { header: 'Type', columnType: ColumnTypeEnum.Type, dataField: KtxLocationBinsPopupComponent.TYPE_FIELD },
  ];
  _frozenColumns = [
    { header: 'Details', columnType: ColumnTypeEnum.Details, width: 46 },
  ];

  get _frozenWidth() {
    return `${this._frozenColumns.reduce((a, v) => a + v.width, 0)}px`;
  }

  constructor(
    private _el: ElementRef,
    private _formBuilder: FormBuilder,
    private _locationKtxDataSourceService: LocationsKtxDataSourceService) {
    super();
    this._searchForm = this._formBuilder.group({
      searchPhrase: [''],
    });
  }

  ngOnInit() {
    this.scheme = new LocationKtxSchemeViewModel();
    this._location = new LocationKtxViewModel();
  }

  ngAfterViewInit() {
    this.reInitScrollBar(0);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.subscriptions &&
      this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  public async show(locationId: number) {
    this._location = this._locationKtxDataSourceService.locationsKtx$.getValue().find(x => x.id === locationId);
    this._isHidden = false;
    this._isLoading = true;
    const scheme = await this._locationKtxDataSourceService.getScheme(locationId);
    if (scheme && this._location && scheme.locationId === this._location.id) {
      this._gridData = scheme.binSchemes;
      this.scheme = scheme;
    }
    this._isLoading = false;
  }

  async onClose() {
    this._isHidden = true;
    this._gridData = [];
  }

  onUpdateLastSearch() {
    this._lastSearchPhrase = this._searchForm.controls.searchPhrase.value;
  }

  onClickDetails(bin: BinViewModel) {
    const binScheme = BinViewModel.toSchemeViewModel(bin);

    if (this._location.temperatureRanges && this._location.alarm) {
      binScheme.temperatureRanges = this._location.temperatureRanges;
      binScheme.alarm = this._location.alarm;
    } else {
      this._locationKtxDataSourceService.getTemperatureRanges(this._location.id).then(() => {
        binScheme.temperatureRanges = this._location.temperatureRanges;
        binScheme.alarm = this._location.alarm;
      });
    }

    binScheme.locationId = this.scheme.locationId;
    binScheme.displaySchemes = this._location.displaySchemes;
    binScheme.wasDeviceInitialized = this.scheme.wasDeviceInitialized;
    binScheme.isDeviceInitialized = this._location.isInitialized;

    if (binScheme.binType === BinTypeEnum.Unit) {
      this.onUnitDetailsClick.emit(binScheme);
    } else {
      this.onDetailsClick.emit(binScheme);
    }
  }

  private reInitScrollBar(timeout?: number) {
    const el = this._el.nativeElement.querySelector('.ui-table-scrollable-view.ui-table-unfrozen-view .ui-table-scrollable-body');
    this.reInitMainScrollBar(el, timeout);
  }
}
