import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, HostListener, AfterViewChecked, OnDestroy } from '@angular/core';

import { TemplateFunctions } from '../../../common';
import { BasePopupBehavior } from '../base-popup.behavior';

@Component({
  selector: 'greensleeves-base-popup',
  templateUrl: './base-popup.component.html'
})
export class BasePopupComponent extends BasePopupBehavior
  implements OnInit, AfterViewChecked, AfterViewChecked, OnDestroy {
  @Input()
  public isHidden: boolean;
  @Input()
  public isNeedToPreventClose: boolean;
  @Input()
  public isNeedToHideCloseButton: boolean;
  @Output()
  public isHiddenChange = new EventEmitter<boolean>();
  @Output()
  public popupClosed = new EventEmitter<any>();

  _popupWrapperId = TemplateFunctions.makeid(11);
  _resizeHelperMutationObserver: MutationObserver;
  _popupWrapperElement: any;
  _heightPx = '';

  private isResizeOnShowWereChecked = false;

  constructor(
    private cdRef: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit() {
  }

  ngAfterViewChecked() {
    if (!this.isHidden && !this.isResizeOnShowWereChecked) {
      this.isResizeOnShowWereChecked = true;
      this.checkAndResize();
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  onClose() {
    if (!this.isNeedToPreventClose) {
      this.isHidden = true;
      this.isResizeOnShowWereChecked = false;
      this.isHiddenChange.emit(this.isHidden);
      this.popupClosed.emit();

      this.destroyMainScrollBar();
      this._heightPx = '';
    } else {
      this.popupClosed.emit();
    }
  }

  @HostListener('window: resize', ['$event.target'])
  onResize() {
    if (!this.isHidden) {
      this.checkAndResize();
    }
  }

  private checkAndResize() {
    this._heightPx = '';
    if (!this._popupWrapperElement) {
      this._popupWrapperElement = document.getElementById(this._popupWrapperId);
    }

    const height = this._popupWrapperElement.offsetHeight;
    const documentHeigth = document.documentElement.clientHeight;

    if (height > documentHeigth) {
      // this._heightPx = height - (height - documentHeigth) + 'px';
      this._heightPx = (documentHeigth - 40) + 'px';
      this.cdRef.detectChanges();
      super.reInitMainScrollBar(this._popupWrapperElement);
    } else {
      super.destroyMainScrollBar();
    }
  }
}
