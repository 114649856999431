import { MeasureEnum, SensorStatusEnum } from "../enums";
import { SensorKtxSchemeViewModel } from './sensors';

export class TelemetrySnapshotViewModel {
    binId: number;
    recievingTimestamp: number;
    snapshot: TelemetryViewModel[];
    measureType: MeasureEnum;
}

export class TelemetryViewModel {
    cableId: number;
    state: number;
    sensors: SensorKtxSchemeViewModel[]
}

export class CableSnapshotResponse {
    id: number;
    name: number;
    sensors: SensorSnapshotResponse[];
}

export class SensorSnapshotResponse {
    id: number;
    status: SensorStatusEnum;
    value: number;
}

export class TelemetryBinsGetModel {
    binId: number;
    listOfCableIds: number[];
}

export class HistoricalBinsSnapshots {
    timeStamps: number[];
    binSnapshots: HistoricalBinSnapshots[];
}

export class HistoricalBinSnapshots {
    binId: number;
    cableSnapshotsByPeriods: SnapshotByPeriod[];
}

export class SnapshotByPeriod {
    timeStamp: number;
    cableSnapshots: CableSnapshotResponse[];
}