import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlarmViewModel, BinSchemeViewModel, CableLocationSchemeViewModel, CableSchemeViewModel, CompanyViewModel, LocationKtxSchemeViewModel, SensorKtxSchemeViewModel, TelemetryBinsGetModel, TelemetrySnapshotViewModel, TemperatureColorViewModel, TemperatureRangeViewModel } from '@models';
import { KtxCurrentDataExportReportPopupComponent } from '@popups';
import { LocationsKtxDataSourceService } from '@services';
import { TreeNode } from 'primeng';
import { Subscription } from 'rxjs';

import { SensorStatusEnum } from './../../../../../../projects/components/src/lib/schemes.constants';
import { CommonFunctions, ConvertDataFunctions } from './../../../../common';
import { MainRoutePaths, SensorAlarmLabels } from './../../../constants';
import { ColumnTypeEnum } from 'src/app/main/enums';

@Component({
  selector: 'greensleeves-ktx-location-details-tab',
  templateUrl: './ktx-location-details-tab.component.html',
})
export class KtxLocationDetailsTabComponent implements OnInit, OnDestroy {
  static CABLE_NAME_FIELD = 'cableName';
  static NAME_FIELD = 'binName';
  static SENSORS_FIELD = 'sensors';
  @ViewChild(KtxCurrentDataExportReportPopupComponent, {
    read: false,
    static: false,
  })
  exportReportModal: KtxCurrentDataExportReportPopupComponent;

  _isOpenLocationList = false;
  _ktxCompaniesWithLocationsNodes: TreeNode[] = [];
  _selectedLocationNode: TreeNode;
  _loading: boolean = false;
  _wasLocationDeviceInitialized: boolean = false;

  _columnType = ColumnTypeEnum;
  _columnsView = [];
  _gridData: CableLocationSchemeViewModel[] = [];

  private locationScheme: LocationKtxSchemeViewModel;
  private temperatureRanges: TemperatureRangeViewModel[];
  private alarm: AlarmViewModel;
  private subscriptions: Subscription[] = [];
  private isFahrenheitMeasureMode: boolean = true;
  private lastUpdatingStatus: string;

  _frozenColumns = [
    {
      header: 'Bin',
      columnType: ColumnTypeEnum.BinName,
      dataField: KtxLocationDetailsTabComponent.NAME_FIELD,
      width: 136,
    },
    {
      header: 'Cable',
      columnType: ColumnTypeEnum.CableName,
      dataField: KtxLocationDetailsTabComponent.CABLE_NAME_FIELD,
      width: 60,
    }
  ];

  get _lastUpdatingStatus() {
    return this.lastUpdatingStatus ? this.lastUpdatingStatus : '-';
  }

  get _frozenWidth() {
    return `${this._frozenColumns.reduce((a, v) => a + v.width, 0)}px`;
  }

  get _locationName(): string {
    return this._selectedLocationNode ? this._selectedLocationNode.label : '-';
  }

  get _isFahrenheitMeasureMode() {
    return this.isFahrenheitMeasureMode;
  }

  set _isFahrenheitMeasureMode(value: boolean) {
    this.isFahrenheitMeasureMode = value;
  }

  get _temperatureRanges(): TemperatureColorViewModel[] {
    if (!this.temperatureRanges) {
      return [];
    }

    const temperatures: TemperatureColorViewModel[] = this
      ._isFahrenheitMeasureMode
      ? this.temperatureRanges.map<TemperatureColorViewModel>((tr) => ({
        value: this.onCelsiusToFahrenheit(tr.to),
        colorHex: tr.colorHex,
      }))
      : this.temperatureRanges.map<TemperatureColorViewModel>((tr) => ({
        value: Math.round(tr.to).toString(),
        colorHex: tr.colorHex,
      }));

    const {
      openTcColorHex,
      noResponseColorHex,
      maxRiseColorHex,
      highTemperatureColorHex,
    } = this.alarm;

    return [
      ...temperatures,
      {
        value: SensorAlarmLabels.OpenTc,
        colorHex: openTcColorHex,
      },
      {
        value: SensorAlarmLabels.NoResponse,
        colorHex: noResponseColorHex,
      },
      {
        value: SensorAlarmLabels.MaxRise,
        colorHex: maxRiseColorHex,
      },
      {
        value: SensorAlarmLabels.HighLimit,
        colorHex: highTemperatureColorHex,
      },
    ];
  }

  constructor(
    private _ktxLocationService: LocationsKtxDataSourceService,
    private _router: Router,
    private _route: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    const companies = this._ktxLocationService.companiesWithLocations$.getValue();
    this.initCompanyWithLocationsNodes(companies);

    this._loading = true;
    const locationSchemeSubscription =
      this._ktxLocationService.locationScheme$.subscribe((locationScheme) => {
        this.locationScheme = locationScheme;
        if (!this._ktxCompaniesWithLocationsNodes || this._ktxCompaniesWithLocationsNodes.length == 0 || !location) {
          this.onClickGoBackLink();
        } else {
          this.setLocationNodeById(this.locationScheme.locationId);
          this.loadLocationTelemetry(this.locationScheme);
        }
      });
    this.subscriptions.push(locationSchemeSubscription);
  }

  ngOnDestroy() {
    this._loading = false;
    this.clearTableView();
    this.clearSubscriptions();
  }

  splitStringBySpace(input: string): string[] {
    return input.split(' ');
  }

  getBackGroundTextColor(
    cable: CableSchemeViewModel,
    sensorIndex: number
  ): string {
    const sensor = cable
      ? (cable[sensorIndex] as SensorKtxSchemeViewModel)
      : null;

    if (sensor) {
      if (sensor.status === SensorStatusEnum.OpenTC) {
        return '#FFFFFF';
      }
    }

    return '';
  }

  getSensorColor(cable: CableSchemeViewModel, sensorIndex: number): string {
    const sensor =
      cable && cable.sensors
        ? (cable.sensors[sensorIndex] as SensorKtxSchemeViewModel)
        : null;

    if (!sensor) {
      return '-';
    }

    if (sensor.status === SensorStatusEnum.OpenTC) {
      return this.alarm.openTcColorHex;
    }

    if (sensor.status === SensorStatusEnum.NoResponse) {
      return this.alarm.noResponseColorHex;
    }

    if (sensor.status === SensorStatusEnum.HighLimit) {
      return this.alarm.highTemperatureColorHex;
    }

    if (sensor.status === SensorStatusEnum.MaxRise) {
      return this.alarm
        ? this.alarm.maxRiseColorHex
        : '';
    }

    if (this.temperatureRanges) {
      const temperatureRange = this.temperatureRanges.find(
        (x) => sensor.value >= x.from && sensor.value <= x.to
      );
      return !temperatureRange ? '' : temperatureRange.colorHex;
    }

    return '-';

  }

  getSensorValue(
    cable: CableSchemeViewModel,
    sensorIndex: number
  ): string | number {
    const sensor =
      cable && cable.sensors
        ? (cable.sensors[sensorIndex] as SensorKtxSchemeViewModel)
        : null;

    if (!sensor) {
      return '';
    }

    if (sensor.status === SensorStatusEnum.OpenTC) {
      return SensorAlarmLabels.OpenTc;
    }

    if (sensor.status === SensorStatusEnum.NoResponse) {
      return '-';
    }

    if (this.isFahrenheitMeasureMode) {
      const FahrenheitValue = this.onCelsiusToFahrenheit(sensor.value);
      return FahrenheitValue;
    }

    return sensor.value;
  }

  onSelectLocationNode(event: any) {
    const { node } = event;
    if (node) {
      this.clearTableView();
      this._loading = true;
      this._ktxLocationService.getScheme(node.data, true);
      this._isOpenLocationList = false;
    }
  }

  clearSubscriptions(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.subscriptions = null;
  }

  onClickGoBackLink(): void {
    this._router.navigate([`../${MainRoutePaths.KTX_LOCATION_VIEW}`], {
      relativeTo: this._route,
    });
  }

  onClickExportReport() {
    this.exportReportModal.show(this.locationScheme, this._isFahrenheitMeasureMode ? 1 : 0);
  }

  openLocationList(): void {
    this._isOpenLocationList = !this._isOpenLocationList;
  }

  private async loadLocationTelemetry(location: LocationKtxSchemeViewModel) {
    this.setTemperatureRange(location);
    this._wasLocationDeviceInitialized = location.wasDeviceInitialized;
    const getModel: TelemetryBinsGetModel[] = [];
    location.binSchemes.forEach(bin => {
      this.setTelemetryGetModel(getModel, bin);
    });
    let binsTelemetry: TelemetrySnapshotViewModel[] = [];
    if (location.wasDeviceInitialized) {
      binsTelemetry = await this._ktxLocationService.getBinsTelemetry(getModel) as TelemetrySnapshotViewModel[];
      this.setColumnView(location);
    }
    const lastUpdates: number[] = [];
    this.setTelemetryTable(location.binSchemes.sort((a, b) => CommonFunctions.compareStrings(a.name, b.name)), binsTelemetry, lastUpdates);
    if (lastUpdates.length > 0) {
      this.setLastReadTime(Math.max(...lastUpdates));
    }
    this._loading = false;
  }

  private setTelemetryTable(bins: BinSchemeViewModel[], binsTelemetry: TelemetrySnapshotViewModel[], lastUpdates: number[], unitName: string = null) {
    bins.forEach(bin => {
      if (bin.bins && bin.bins.length > 0) {
        this.setTelemetryTable(bin.bins.sort((a, b) => CommonFunctions.compareStrings(a.name, b.name)), binsTelemetry, lastUpdates, bin.name);
      } else {
        const binTelemetry = binsTelemetry.find(c => c.binId == bin.id);
        if (bin.cables) {
          const cables = bin.cables.sort((a, b) => a.name - b.name);
          cables.forEach(cab => {
            const cableTelemetry = binTelemetry ? binTelemetry.snapshot.find(c => c.cableId == cab.id) : null;
            if (binTelemetry) {
              lastUpdates.push(binTelemetry.recievingTimestamp);
            }
            this._gridData.push({
              binName: unitName ? `${unitName}: ${bin.name}` : bin.name,
              cableName: cab.name.toString(),
              sensors: cableTelemetry ? cableTelemetry.sensors : [],
            } as CableLocationSchemeViewModel);
          });
        }
      }
    });
  }

  private setLastReadTime(time: number) {
    const date = new Date(time * 1000);
    this.lastUpdatingStatus = 'Last updating: ' + date.toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    });
  }

  private setTelemetryGetModel(model: TelemetryBinsGetModel[], bin: BinSchemeViewModel) {
    if (bin.cables && bin.cables.length > 0) {
      model.push(
        {
          binId: bin.id,
          listOfCableIds: bin.cables.map(x => x.id),
        } as TelemetryBinsGetModel
      );
    }
    if (bin.bins && bin.bins.length > 0) {
      bin.bins.forEach(x => this.setTelemetryGetModel(model, x));
    }
  }

  private clearTableView() {
    this._wasLocationDeviceInitialized = false;
    this.lastUpdatingStatus = "-";
    this.temperatureRanges = null;
    this._gridData = [];
    this._columnsView = [];
  }

  private setTemperatureRange(location: LocationKtxSchemeViewModel) {
    this.temperatureRanges = location.temperatureRanges;
    this.alarm = location.alarm;
  }

  private setColumnView(location: LocationKtxSchemeViewModel) {
    const cableCounts: number[] = [];
    location.binSchemes.forEach(bin => {
      if (bin.cables && bin.cables.length > 0) {
        cableCounts.push(...bin.cables.map(c => c.thermocoupleCount));
      }
      if (bin.bins && bin.bins.length > 0) {
        bin.bins.forEach(b => {
          if (b.cables && b.cables.length > 0) {
            cableCounts.push(...b.cables.map(c => c.thermocoupleCount));
          }
        });
      }
    })
    var maxCablesCount = Math.max(...cableCounts);

    this._columnsView = Array.from({ length: maxCablesCount }, (_, index) => ({
      header: 'TC ' + (index + 1),
      columnType: ColumnTypeEnum.TC,
      dataField: KtxLocationDetailsTabComponent.SENSORS_FIELD,
    }));
  }

  private onCelsiusToFahrenheit(value: number): string {
    if (value || value === 0) {
      const convertedValue = ConvertDataFunctions.celsiusToFahrenheit(value);
      return convertedValue.toString();
    } else {
      return '-';
    }
  }

  private initCompanyWithLocationsNodes(companies: CompanyViewModel[]) {
    const companyWithLocationNodes =
      companies &&
      companies
        .map((company) => {
          const companyWithLocationNode: TreeNode = {
            label: company.name,
            selectable: false,
            expanded: false,
          };

          companyWithLocationNode.children = company.locations
            .filter(l => l.bins && l.bins.length > 0)
            .map<TreeNode>((location) => {
              const locationNode: TreeNode = {
                label: location.name,
                data: location.id,
                selectable: true,
                parent: companyWithLocationNode,
              };

              return locationNode;
            })
            .sort(CommonFunctions.compareTreeNodeLabels);

          return companyWithLocationNode;
        })
        .filter(c => c.children && c.children.length > 0)
        .sort(CommonFunctions.compareTreeNodeLabels);

    this._ktxCompaniesWithLocationsNodes = companyWithLocationNodes;
  }

  private setLocationNodeById(locationId: number) {
    const mergedLocations: TreeNode[] =
      this._ktxCompaniesWithLocationsNodes.reduce(
        (result, companyNode) => result.concat(companyNode.children),
        []
      );

    const locationNode = mergedLocations.find((l) => +l.data === locationId);

    if (!locationNode) {
      return;
    }

    this._selectedLocationNode = locationNode;
    locationNode.parent.expanded = true;
  }
}
