import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

import {
  EquipmentDataSourceService,
  LocationsBtxDataSourceService,
  ReportDatasourceService,
} from '@services';
import { TreeNode } from 'primeng';

import { BtxLiveDataExportReportPopupComponent } from '@popups';
import {
  MainRoutePaths,
  equipmentSideLabels,
  sensorPositionLabels,
  sensorTypeLabels,
} from '../../../constants';
import {
  BtxEquipmentWithTelemetryViewModel,
  BtxLiveDataLocationViewModel,
  BtxSensorWithTelemetryViewModel,
  EquipmentNameViewModel,
  EquipmentViewModel,
} from '@models';

@Component({
  selector: 'greensleeves-btx-live-data-equipment-view-tab',
  templateUrl: './btx-live-data-equipment-view-tab.component.html',
})
export class BtxLiveDataEquipmentViewTabComponent implements OnInit, OnDestroy {
  private static readonly ONE_SECOND_MILLISECONDS: number = 1000;

  @ViewChild(BtxLiveDataExportReportPopupComponent, {
    read: false,
    static: false,
  })
  exportReportModal: BtxLiveDataExportReportPopupComponent;

  _equipmentId: number = 0;
  _equipmentWithTelemetry: BtxEquipmentWithTelemetryViewModel;
  _equipmentViewModel: EquipmentViewModel;
  _isIntervalInitialized: boolean = false;
  _isReportRun: boolean = false;
  _locationEquipmentsNodes: TreeNode[] = [];
  _selectedEquipmentNode: TreeNode;
  _isOpenEquipmentsList: boolean = false;
  _liveDataLocations: BtxLiveDataLocationViewModel[] = [];
  _loading: boolean = false;
  _locationName: string;
  _equipmentName: string = '';

  private subscriptions: Subscription[] = [];
  private intervalSubscription: Subscription = null;

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _equipmentService: EquipmentDataSourceService,
    private _btxLocationService: LocationsBtxDataSourceService,
  ) { }

  get _sensors() {
    if (!this._equipmentWithTelemetry) {
      return [];
    }

    return this._equipmentWithTelemetry.sensors;
  }

  get _locationId() {
    if (!this._equipmentWithTelemetry) {
      return -1;
    }

    return this._equipmentWithTelemetry.locationId;
  }

  ngOnInit() {
    this.setSubscriptions();
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }

  setSubscriptions(): void {
    const equipmentIdSubscription =
      this._btxLocationService.selectedEquipmentId$.subscribe(equipmentId => {
        if (!equipmentId) {
          this.onClickGoBackLink();
          return;
        }

        this._equipmentService.getEquipmentWithTelemetry(equipmentId);
      });

    const equipmentWithTelemetrySubscription =
      this._equipmentService.equipmentWithTelemetry$.subscribe(
        equipmentWithTelemetry => {
          if (!equipmentWithTelemetry) {
            return;
          }

          this.setUpEquipment(equipmentWithTelemetry);
          this._loading = false;
        },
      );

    const liveDatalocationsSubscription =
      this._btxLocationService.liveDataLocations$.subscribe(locations => {
        if (!locations) {
          return;
        }

        this._liveDataLocations = locations;
        this.initEquipmentNodes(locations);

      });

    this._btxLocationService.getCompaniesWithLocations(true);
    this._loading = true;
    this.subscriptions.push(
      equipmentIdSubscription,
      equipmentWithTelemetrySubscription,
      liveDatalocationsSubscription,
    );
  }

  setUpEquipment(equipmentWithTelemetry: BtxEquipmentWithTelemetryViewModel) {
    this._equipmentId = equipmentWithTelemetry.id;

    const equipmentWithSortedSensors: BtxEquipmentWithTelemetryViewModel = {
      ...equipmentWithTelemetry,
      sensors: equipmentWithTelemetry.sensors
        .sort(this.compareSensorsWithTelemetryByPosition)
        .map((s, index) => ({ ...s, index: index + 1 })),
    };

    this._equipmentWithTelemetry = equipmentWithSortedSensors;
    this._equipmentViewModel =
      BtxEquipmentWithTelemetryViewModel.toEquipmentViewModel(
        equipmentWithSortedSensors,
      );

    this.initializeInterval();
    this._locationName = this._equipmentWithTelemetry.locationName;
    this._equipmentName = this._equipmentWithTelemetry.name;
  }

  initializeInterval() {
    if (!this._equipmentWithTelemetry || this._isIntervalInitialized) {
      return;
    }

    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
      this.intervalSubscription = null;
    }

    const intervalPeriod: number = BtxLiveDataEquipmentViewTabComponent.ONE_SECOND_MILLISECONDS * this._equipmentWithTelemetry.acquisitionRate;
    this.intervalSubscription = interval(intervalPeriod).subscribe(() => {
      this._equipmentService.getEquipmentWithTelemetry(this._equipmentId);
    });

    this._isIntervalInitialized = true;
  }

  clearSubscriptions(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions = null;

    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
      this.intervalSubscription = null;
    }
    this._equipmentWithTelemetry = null;
    this._equipmentService.equipmentWithTelemetry$.next(null);
  }

  onClickGoBackLink(): void {
    this._equipmentWithTelemetry = null;
    this._router.navigate([`../${MainRoutePaths.BTX_LIVE_DATA}`], {
      relativeTo: this._route,
    });
  }

  onClickExportReport() {
    this.exportReportModal.show(this._liveDataLocations, this._locationId, this._equipmentId);
  }

  compareSensorsWithTelemetryByPosition(
    a: BtxSensorWithTelemetryViewModel,
    b: BtxSensorWithTelemetryViewModel,
  ) {
    const aString = `${sensorTypeLabels[a.type.id]}${sensorPositionLabels[a.position.id]
      }${equipmentSideLabels[a.side.id]}`;
    const bString = `${sensorTypeLabels[b.type.id]}${sensorPositionLabels[b.position.id]
      }${equipmentSideLabels[b.side.id]}`;

    if (aString < bString) {
      return -1;
    }

    if (aString > bString) {
      return 1;
    }

    return 0;
  }

  initEquipmentNodes(locations: BtxLiveDataLocationViewModel[]) {
    const equipmentId = this._btxLocationService.selectedEquipmentId$.value;
    const selectedLocation = locations.find(
      location =>
        location.equipments &&
        location.equipments.some(eq => eq.id === equipmentId),
    );

    const equipmentNodes: TreeNode[] = selectedLocation.equipments.map(
      EquipmentNameViewModel.toBtxTreeNode,
    );

    this._locationEquipmentsNodes = equipmentNodes;
    this._selectedEquipmentNode = equipmentNodes.find(
      eqn => eqn.data.id === equipmentId,
    );

  }

  toggleEquipmentsList(): void {
    this._isOpenEquipmentsList = !this._isOpenEquipmentsList;
  }

  onSelectEquipmentNode(event: any) {
    const { node } = event;

    if (node) {
      const { id } = node.data;

      this._isIntervalInitialized = false;
      this._isOpenEquipmentsList = false;
      this._loading = true;
      this._equipmentWithTelemetry = null;
      this._equipmentViewModel = null;
      this._btxLocationService.selectedEquipmentId$.next(id);
      this._equipmentName = node.label;
    }
  }
}
