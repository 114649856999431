export enum SensorStatusEnum {
    NoError = 0,
    OpenTC = 1,
    NoResponse = 2,
    MaxRise = 3,
    HighLimit = 4,
}

export enum ModeTypesEnum {
    Rotation = 0,
    Click = 1,
}

export enum MeasureTypeEnum {
    Temperature = 0,
    Resistance = 1,
}

export enum BinTypeEnum {
    Circular = 1,
    Rectangle = 2,
    Unit = 3
}

export enum SchemeSizeEnum {
    small = 0,
    large = 1,
    mobile = 2,
}

export const schemesSizeInterval = {
    [SchemeSizeEnum.small]: {
        from: 1,
        to: 350
    },
    [SchemeSizeEnum.large]: {
        from: 351,
        to: 1000
    }
};

export const scheme2DParams = {
    [SchemeSizeEnum.mobile]: {
        fontSizeRatio: 15,
        dotRadiusRatio: 40
    },
    [SchemeSizeEnum.small]: {
        fontSizeRatio: 15,
        dotRadiusRatio: 53
    },
    [SchemeSizeEnum.large]: {
        fontSizeRatio: 25,
        dotRadiusRatio: 65
    },
};

export const scheme3DParams = {
    [SchemeSizeEnum.mobile]: {
        fontScale: 30,
        cableRadiusRatio: 285,
        sensorRadiusRatio: 70
    },
    [SchemeSizeEnum.small]: {
        fontScale: 35,
        cableRadiusRatio: 285,
        sensorRadiusRatio: 67
    },
    [SchemeSizeEnum.large]: {
        fontScale: 50,
        cableRadiusRatio: 400,
        sensorRadiusRatio: 80
    },
};

export const unit2DParams = {
    [SchemeSizeEnum.mobile]: {
        fontSizeRatio: 25,
        dotRadiusRatio: 100
    },
    [SchemeSizeEnum.small]: {
        fontSizeRatio: 20,
        dotRadiusRatio: 90
    },
    [SchemeSizeEnum.large]: {
        fontSizeRatio: 40,
        dotRadiusRatio: 130
    },
};
