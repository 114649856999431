import { HistoricalBinSnapshots } from "./telemetry-snapshot.model";

export class KtxReportHistoricalDataFilterForPeriod {
    public locationId: number;
    public binIds: number[];
    public timestampFrom: number;
    public timestampTo: number;
    public temperatureMeasureType: number;
    public timeZone: string;
    public historySnapshots: HistoricalBinSnapshots[]
}

export class KtxReportFormat {
    label: string;
    value: number;
}

export class KtxReportCurrentDataFilter {
    public locationId: number;
    public binIds: number[];
    public temperatureMeasureType: number;
    public timeZone: string;
}