import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { BasePopupBehavior } from '../common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ErrorCodeEnum, ErrorModel } from 'src/app/common';
import { UpdaterDeviceDatasourceService } from '@services';
import { UploadFirmwareRebasePopupComponent } from '../upload-firmware-rebase-popup';
import { ProjectFirmwareViewModel } from '@models';

@Component({
  selector: 'greensleeves-upload-firmware-popup',
  templateUrl: './upload-firmware-popup.component.html',
  styleUrls: []
})
export class UploadFirmwarePopupComponent extends BasePopupBehavior implements OnInit {
  @ViewChild(UploadFirmwareRebasePopupComponent, { read: false, static: false })
  private rebaseComponent: UploadFirmwareRebasePopupComponent;

  _formGroup: FormGroup;

  _isHidden = true;
  _isNeedToPreventClose = true;
  _submitted = false;
  _isLoading = false;
  _directoryName: string = null;
  _toUpdate = true;
  _isBigFile = false;
  _isFileUploaded = false;
  _isWrongFileType = false;

  _errorMessage: string = null;

  _files: ProjectFirmwareViewModel[];

  get _fileName(): string {
    return this.fileToUpload ? this.fileToUpload.name : '';
  }

  get _isSubmitDisabled(): boolean {
    return this._isLoading;
  }

  private fileToUpload: File;
  private isNeedToreInitScrollbar = false;


  constructor(
    private _updaterDeviceDatasourceService: UpdaterDeviceDatasourceService,
    private cdRef: ChangeDetectorRef,
    private formBuilder: FormBuilder,
  ) {
    super();
  }

  ngOnInit() {
    this._formGroup = this.formBuilder.group({});
  }

  ngAfterViewChecked() {
    if (this._isFileUploaded && this.isNeedToreInitScrollbar) {
      this.isNeedToreInitScrollbar = false;
    }
  }

  async onSubmit() {
    this._submitted = true;

    if (!this.fileToUpload) {
      this._errorMessage = 'File is required';
      this._submitted = false;
      return;
    }

    let confirmationNeeded = false;

    this._files.forEach(f => {
      if (f.name == this.fileToUpload.name) {
        confirmationNeeded = true;
      }
    });

    if (confirmationNeeded) {
      this.rebaseComponent.onShow(this._directoryName, this.fileToUpload.name);
    } else {
      await this.uploadConfigurationFile(true);
    }
  }

  onClickClose() {
    this._isHidden = false;
    this.closePopup();
  }

  async onSelect(onSelectEvent: any) {
    const uploadFile = onSelectEvent.currentFiles[0] as File;

    if (uploadFile.size == 0) {
      this._errorMessage = 'File size should be more than 0.';
    } else if (uploadFile.size >= 30000000) {
      this.fileToUpload = uploadFile;
      this._isBigFile = true;
      this._errorMessage = 'File size should be less than 30 Mb.';
    } else {
      this._errorMessage = null;
      this.fileToUpload = uploadFile;
      this._isFileUploaded = false;
      this._isBigFile = false;
      this._isWrongFileType = false;
    }
  }

  public show(directoryName: string, files: ProjectFirmwareViewModel[]) {
    this._isLoading = false;
    this._submitted = false;
    this._isHidden = false;
    this._files = files;
    this._directoryName = directoryName;
    this.cdRef.detectChanges();
  }

  private closePopup() {
    this._isLoading = false;
    this._isHidden = true;
    this.fileToUpload = null;
    this._errorMessage = null;
    this._isFileUploaded = false;
    this._isBigFile = false;
    this._isWrongFileType = false;
    this._formGroup = this.formBuilder.group({});
  }

  async uploadConfigurationFile(value: boolean) {
    this._toUpdate = value;
    if (!this._toUpdate) {
      this.fileToUpload = null;
    }

    if (this._toUpdate) {
      this._isLoading = true;
      const result = await this._updaterDeviceDatasourceService.uploadFirmware({ directoryName: this._directoryName, insertFile: this.fileToUpload });
      if (result) {
        if (result instanceof ErrorModel) {
          if (result.code == ErrorCodeEnum.InvalidModelState) {
            this._errorMessage = result.errors[0];
          } else {
            this._errorMessage = result.message;
          }
        } else {
          this._isFileUploaded = true;
          this.isNeedToreInitScrollbar = true;
        }
        this._submitted = false;
      }

      this.onClickClose();
    }
  }
}
