import { ErrorModel } from 'src/app/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import { TemplateFunctions } from '../../common';
import { BinConnectionViewModel } from './../../../models';
import { LocationsKtxDataSourceService } from './../../../services';

@Component({
  selector: 'greensleeves-ktx-connection-popup',
  templateUrl: './ktx-connection-popup.component.html',
  styles: []
})
export class KtxConnectionPopupComponent implements OnInit, OnDestroy {
  _isHidden: boolean = true;
  _location: { locationName: string, locationId: number };
  _formGroup: FormGroup = new FormGroup({});
  _binsConnections: BinConnectionViewModel[];
  _submitted: boolean = false;
  _isLoad: boolean = true;
  _isSend: boolean = true;
  private _subscriptions: Subscription;

  constructor(
    private _locationKtxDataSourceService: LocationsKtxDataSourceService
  ) { }

  ngOnInit() {
    this._subscriptions = this._locationKtxDataSourceService.binsConnections$.subscribe(items => {
      if (items) {
        this._binsConnections = items;
        this.reInitForm(items);
      }
      this._isLoad = false;
      this._isSend = false;
    });
  }

  ngOnDestroy(): void {
    this._subscriptions && this._subscriptions.unsubscribe();
  }

  show(location: { locationName: string, locationId: number }) {
    this._location = location;
    this._isLoad = true;
    this._isSend = true;
    this._locationKtxDataSourceService.getBinsForEditConnection(this._location.locationId);
    this._isHidden = false;
  }

  onClose() {
    this._location = null;
    this._isHidden = true;
    this._submitted = false;
    this._binsConnections = null;
    this._formGroup.reset();
    this._isLoad = false;
  }

  async onSubmit() {
    this._submitted = true;
    if (this._formGroup.invalid) {
      return;
    }
    let editedConnections: BinConnectionViewModel[] = [];
    this._binsConnections.forEach(item => {
      if (item.ipAddress != this._formGroup.controls[`ipAddress_Sys${item.sysId}`].value
        || (item.withRemotePort && item.portRemote != this._formGroup.controls[`portRemote_Sys${item.sysId}`].value)
        || item.port != this._formGroup.controls[`port_Sys${item.sysId}`].value) {

        item.ipAddress = this._formGroup.controls[`ipAddress_Sys${item.sysId}`].value;
        item.port = this._formGroup.controls[`port_Sys${item.sysId}`].value;
        item.portRemote = item.withRemotePort ? this._formGroup.controls[`portRemote_Sys${item.sysId}`].value : 0;
        editedConnections.push(item);
      }
    });

    if (editedConnections.length == 0) {
      this.onClose();
      return;
    }

    this._isSend = true;
    let result = await this._locationKtxDataSourceService.editConnections(this._location.locationId, editedConnections);
    this._isSend = false;
    if (!(result instanceof ErrorModel)) {
      this.onClose();
    }
  }

  private reInitForm(binsListsView: BinConnectionViewModel[]) {
    this._formGroup = new FormGroup({});
    binsListsView.forEach(sysGroup => {
      this._formGroup.addControl(`port_Sys${sysGroup.sysId}`, new FormControl(
        sysGroup.port,
        Validators.compose([
          Validators.required,
          Validators.min(0),
          Validators.max(65535)
        ])
      ));

      this._formGroup.addControl(`ipAddress_Sys${sysGroup.sysId}`, new FormControl(
        sysGroup.ipAddress,
        Validators.compose([
          Validators.required,
          TemplateFunctions.ipValidator,
        ])
      ));

      if (sysGroup.withRemotePort) {
        this._formGroup.addControl(`portRemote_Sys${sysGroup.sysId}`, new FormControl(
          sysGroup.portRemote,
          Validators.compose([
            sysGroup.withRemotePort ? Validators.required : null,
            Validators.min(0),
            Validators.max(65535)
          ])
        ));
      }
    });
  }

}
