import { Component, OnInit, OnDestroy, AfterViewChecked, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import { CompaniesDataSourceService, LocationsDataSourceService, RoleService } from '../../../services';
import { TemplateFunctions } from '../../common';
import { ErrorModel, ErrorCodeEnum, UserRoleEnum } from '../../../../common';
import { BasePopupBehavior } from '../common';
import { emailValidator } from 'src/app/common/validators';
import { LocationChangedPopupComponent } from '../location-changed-popup';
import { LocationViewModel } from '../../../models';

@Component({
  selector: 'greensleeves-location-edit-popup',
  templateUrl: './location-edit-popup.component.html',
  styles: []
})
export class LocationEditPopupComponent extends BasePopupBehavior implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChild(LocationChangedPopupComponent, { read: false, static: false })
  _locationChangedPopupComponent: LocationChangedPopupComponent;

  _isHidden = true;
  _submitted = false;

  _location: LocationViewModel;
  _locationForm: FormGroup;

  _companies: { label: string, value: number }[];

  _nameControlKey = 'name';
  _uniquenessErrorKey = 'unique';

  private _subscriptions: Subscription[] = [];

  constructor(
    private _formBuilder: FormBuilder,
    private _locationsService: LocationsDataSourceService,
    private _companiesService: CompaniesDataSourceService,
    private _roleService: RoleService,
  ) {
    super();
  }

  get _isServiceUser() {
    return this._roleService.userHasRole(UserRoleEnum.ServiceUser);
  }

  get _isCompanyAdmin() {
    return this._roleService.userHasRole(UserRoleEnum.CompanyAdmin);
  }

  ngOnInit() {
    super.ngOnInit();

    if (this._isServiceUser) {
      this._companiesService.get();
    }

    this._location = new LocationViewModel();

    const subscription = this._companiesService.companies$.subscribe(data => {
      if (data) {
        this._companies = data.map(x => ({ label: x.name, value: x.id }));
      }
    });

    this._subscriptions.push(subscription);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscriptions && this._subscriptions.forEach(sub => sub.unsubscribe());
    this._subscriptions = [];
  }

  ngAfterViewChecked() {
    super.ngAfterViewChecked();
  }

  async onSubmit() {
    this._submitted = true;
    if (this._locationForm.invalid) {
      return;
    }

    const value = {
      ...this._location,
      ...this._locationForm.value,
      companyName: this._companies ? this._companies.find(x => x.value == this._locationForm.value.companyId).label : ''
    } as LocationViewModel;

    const result = value.id
      ? await this._locationsService.edit(value)
      : await this._locationsService.add(value);

    if (result instanceof ErrorModel) {
      if (result.code === ErrorCodeEnum.NotUniqueLocationName) {
        this._locationForm.controls[this._nameControlKey].setErrors({ [this._uniquenessErrorKey]: 'Name should be unique' });
      }
    } else if (result) {
      this._location = null;
      this._isHidden = true;
      this._submitted = false;
      this._locationsService.markLocationForOthersAsUpdated(value, false);
    }
  }

  async onFocusControl(control: FormControl) {
    control.markAsTouched();
  }

  async onBlurControl(control: FormControl) {
    control.markAsUntouched();
  }

  async onClose() {
    this._locationsService.markLocationForOthersAsUpdated(this._location, false);
  }

  public show(location?: LocationViewModel) {
    this._submitted = false;
    this._location = location ? Object.assign({}, location) : new LocationViewModel();
    this.reInitForm();
    this._isHidden = false;
  }

  private reInitForm() {
    this._locationForm = this._formBuilder.group({
      [this._nameControlKey]: [
        this._location.name,
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50),
        ])
      ],
      address: [
        this._location.address,
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(80),
        ])
      ],
      companyId: [
        this._location.companyId,
        Validators.compose([
          Validators.required,
        ])
      ],
      serviceEmail: [
        this._location.serviceEmail,
        Validators.compose([
          Validators.required,
          emailValidator()
        ])
      ],
      servicePhone: [
        this._location.servicePhone,
        Validators.compose([
          Validators.required,
          TemplateFunctions.phoneValidator,
        ])
      ],
      emergencyPhone: [
        this._location.emergencyPhone,
        Validators.compose([
          Validators.required,
          TemplateFunctions.emergencyPhoneValidator,
        ])
      ],
      sitePhone: [
        this._location.sitePhone,
        Validators.compose([
          Validators.required,
          TemplateFunctions.phoneValidator,
        ])
      ],
      siteEmail: [
        this._location.siteEmail,
        Validators.compose([
          Validators.required,
          emailValidator()
        ])
      ]
    });
  }
}
