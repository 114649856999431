import { Component, ViewChildren, ElementRef, QueryList, OnInit, OnDestroy, ChangeDetectorRef, EventEmitter, AfterViewChecked, ViewChild, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormGroup, Validators, FormBuilder, ValidatorFn } from '@angular/forms';

import { LocationsKtxDataSourceService } from '../../../services';
import { ErrorModel, ErrorCodeEnum } from '../../../../common';
import { BinViewModel, AddEditLocationKtxSchemeViewModel, AddEditLocationKtxSchemePostModel } from '../../../models';
import { SwitchTypeEnum, BinTypeEnum } from '../../../enums';
import { TemplateFunctions } from '../../common';
import { BasePopupBehavior } from '../common';
import { UploadConfigurationCloseConfirmationPopupComponent } from './upload-configuration-close-popup';

interface BinsListsViewInterface {
  sys: number,
  bins: BinViewModel[],
  binsListLabel: string,
  ipAddress: string,
  ipAddressFormControlName: string,
  port: number,
  portFormControlName: string,
  portRemote: number,
  portRemoteFormControlName: string,
  withRemotePort: boolean,
}

@Component({
  selector: 'greensleeves-upload-configuration-file-popup',
  templateUrl: './upload-configuration-file-popup.component.html',
  styles: []
})
export class UploadConfigurationFilePopupComponent extends BasePopupBehavior implements OnInit, OnDestroy, AfterViewChecked {
  public onUploadSuccess = new EventEmitter<[string, number]>();
  @ViewChildren('popupConfiguration') popupConfigurationEl: QueryList<ElementRef>;

  @ViewChild(UploadConfigurationCloseConfirmationPopupComponent, { read: false, static: false })
  _closeModal: UploadConfigurationCloseConfirmationPopupComponent;

  @Output()
  public isNeedToPreventClose = new EventEmitter<boolean>();

  _formGroup: FormGroup;

  _isHidden = true;
  _isNeedToPreventClose = true;
  _submitted = false;
  _isLoading = false;

  _locationName = '';
  _errorMessage: string = null;

  _isFileUploaded = false;
  _binsLists: BinsListsViewInterface[];

  get _fileName(): string {
    return this.fileToUpload ? this.fileToUpload.name : '';
  }

  get _isSubmitDisabled(): boolean {
    return this._isLoading;
  }

  private locationId: number;
  private fileToUpload: File;
  private addEditLocationKtxSchemeViewModel: AddEditLocationKtxSchemeViewModel;
  private isNeedToreInitScrollbar = false;

  private _subscriptions: Subscription[] = [];

  constructor(
    private _locationsKtxDataSourceService: LocationsKtxDataSourceService,
    private cdRef: ChangeDetectorRef,
    private formBuilder: FormBuilder,
  ) {
    super();
  }

  ngOnInit() {
    this._formGroup = this.formBuilder.group({});
  }

  ngAfterViewChecked() {
    if (this._isFileUploaded && this.isNeedToreInitScrollbar) {
      this.isNeedToreInitScrollbar = false;
      this.reInitScrollBar(0);
    }

    this._closeModal.closeConfirmation.subscribe(isNeedToClose => {
      if (isNeedToClose) {
        this.closePopup()
      }
    });
  }

  ngOnDestroy() {
    this._subscriptions && this._subscriptions.forEach(sub => sub.unsubscribe());
  }

  async onSubmit() {
    this._submitted = true;

    if (!this.fileToUpload) {
      this._errorMessage = 'File is required';
      this._submitted = false;
      return;
    }

    if (this._isFileUploaded) {
      if (this._formGroup.invalid) {
        return;
      }
      this._isLoading = true;
      await this.updateLocationScheme();
    } else {
      this._isLoading = true;
      await this.uploadConfigurationFile();
    }

    this._isLoading = false;
  }

  async onClickClose() {
    this._isHidden = false;
    this.isNeedToPreventClose.emit(true);

    if (this._isFileUploaded) {
      this._closeModal.show();
    } else {
      this.closePopup();
      this.isNeedToPreventClose.emit(false);
    }
  }

  onSelect(onSelectEvent: any) {
    const uploadFile = onSelectEvent.currentFiles[0] as File;
    if (uploadFile.size == 0) {
      this._errorMessage = 'File size should be more than 0.';
    } else if (!uploadFile.name.includes('.xml') || uploadFile.type != 'text/xml') {
      this._errorMessage = 'Invalid file format.';
    } else {
      this._errorMessage = null;
      this.fileToUpload = uploadFile;
      this._isFileUploaded = false;
      this._binsLists = null;
    }
  }

  public show(locationId: number, locationName: string) {
    this._isLoading = false;
    this.locationId = locationId;
    this._locationName = locationName;
    this._submitted = false;
    this._isHidden = false;
    this.cdRef.detectChanges();
  }

  private closePopup() {
    this._isHidden = true;
    this.fileToUpload = null;
    this._errorMessage = null;
    this._isFileUploaded = false;
    this._binsLists = null;
    this._formGroup = this.formBuilder.group({});
    this.isNeedToPreventClose.emit(false);
  }

  private async uploadConfigurationFile() {
    const result = await this._locationsKtxDataSourceService.uploadConfigurationFile(this.fileToUpload, this.locationId);
    if (result) {
      if (result instanceof ErrorModel) {
        if (result.code == ErrorCodeEnum.InvalidModelState) {
          this._errorMessage = result.errors[0];
        } else {
          this._errorMessage = result.message;
        }
      } else {
        const binsLists = this.generateBinsListsViewInterfaces(result.bins);
        this.reInitForm(binsLists);
        this._binsLists = binsLists;
        this._locationName = result.locationName;
        this._isFileUploaded = true;
        this.isNeedToreInitScrollbar = true;

        this.addEditLocationKtxSchemeViewModel = result;
      }
      this._submitted = false;
    }
  }

  private async updateLocationScheme() {
    let binsToForeach = this.addEditLocationKtxSchemeViewModel.bins.filter(b => b.binType != BinTypeEnum.Unit);
    this.addEditLocationKtxSchemeViewModel.bins.filter(b => b.binType == BinTypeEnum.Unit).forEach(b => {
      binsToForeach = binsToForeach.concat(b.bins.filter(c => !c.isDecorBin));
    });

    binsToForeach.forEach(bin => {
      const binsListsViewInterface = this._binsLists.find(bL => bL.sys == bin.sysId);

      if (binsListsViewInterface) {
        bin.port = this._formGroup.controls[binsListsViewInterface.portFormControlName].value;
        bin.ipAddress = this._formGroup.controls[binsListsViewInterface.ipAddressFormControlName].value;

        const portRemoteFormControl = this._formGroup.controls[binsListsViewInterface.portRemoteFormControlName];
        if (portRemoteFormControl) {
          bin.portRemote = portRemoteFormControl.value;
        }
      }
    });

    const request = new AddEditLocationKtxSchemePostModel(this.locationId, this.addEditLocationKtxSchemeViewModel);
    const result = await this._locationsKtxDataSourceService.updateLocationScheme(request);
    if (result) {
      if (result instanceof ErrorModel) {
        this._submitted = false;
        if (result.code == ErrorCodeEnum.InvalidModelState) {
          this._errorMessage = result.errors[0];
        } else {
          this._errorMessage = result.message;
        }
      } else {
        this._isHidden = true;
        this.fileToUpload = null;
        const successPopupInfoText = this.generateSuccessPopupInfoText(result.isBinsOrCablesWereAddedOrRemoved);
        this.onUploadSuccess.emit([successPopupInfoText, this.locationId]);
        this.closePopup();
      }
    }

    this._submitted = false;
  }

  private generateBinsListsViewInterfaces(bins: BinViewModel[]): BinsListsViewInterface[] {
    const binsListsViewInterfaces: BinsListsViewInterface[] = [];
    let binsToForeach = bins.filter(b => b.binType != BinTypeEnum.Unit);
    bins.filter(b => b.binType == BinTypeEnum.Unit).forEach(b => {
      binsToForeach = binsToForeach.concat(b.bins.filter(c => !c.isDecorBin));
    });

    binsToForeach.forEach(bin => {
      let binsListsViewInterface = binsListsViewInterfaces.find(bLVI => bLVI.sys == bin.sysId);

      if (!binsListsViewInterface) {
        binsListsViewInterface = {
          sys: bin.sysId,
          portFormControlName: `portFormControl_${bin.sysId}`,
          portRemoteFormControlName: `portRemoteFormControl_${bin.sysId}`,
          ipAddressFormControlName: `ipAddressFormControl_${bin.sysId}`,
          bins: [],
          withRemotePort: bin.switchType != SwitchTypeEnum.MUX && bin.switchType != SwitchTypeEnum.NONE,
        } as BinsListsViewInterface;
        binsListsViewInterfaces.push(binsListsViewInterface);
      }

      binsListsViewInterface.bins.push(bin);
    });

    return binsListsViewInterfaces;
  }

  private reInitForm(binsListsViewInterfaces: BinsListsViewInterface[]) {
    let formGroup = {};
    binsListsViewInterfaces.forEach(bLVI => {
      bLVI.binsListLabel = this.getBinsListLabel(bLVI.bins);
      formGroup[bLVI.portFormControlName] = [
        null,
        Validators.compose([
          Validators.required,
          Validators.min(0),
          Validators.max(65535)
        ])
      ] as ValidatorFn[];

      formGroup[bLVI.ipAddressFormControlName] = [
        null,
        Validators.compose([
          Validators.required,
          TemplateFunctions.ipValidator,
        ])
      ] as ValidatorFn[];

      if (bLVI.withRemotePort) {
        formGroup[bLVI.portRemoteFormControlName] = [
          null,
          Validators.compose([
            Validators.required,
            Validators.min(0),
            Validators.max(65535)
          ])
        ] as ValidatorFn[];
      }
    });

    this._formGroup = this.formBuilder.group(formGroup);
  }

  private getBinsListLabel(binsList: BinViewModel[]): string {
    return binsList.map(bin => bin.name).join(', ');
  }

  private generateSuccessPopupInfoText(isBinsOrCablesWereAddedOrRemoved: boolean) {
    let stringToReturn = `Configuration for ${this._locationName} location was successfully uploaded.`;
    if (isBinsOrCablesWereAddedOrRemoved) {
      stringToReturn = stringToReturn + ' Note, that new config is different than old config, new config has overridden data';
    }

    return stringToReturn;
  }

  private reInitScrollBar(timeout?: number) {
    const el = this.popupConfigurationEl.first.nativeElement;
    this.reInitMainScrollBar(el, timeout);
  }
}
