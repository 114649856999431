import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import {
  CableLocationSchemeViewModel,
  CableSchemeViewModel,
  CompanyViewModel,
  HistoricalBinSnapshots,
  HistoricalBinsSnapshots,
  KtxReportHistoricalDataFilterForPeriod,
  KtxWeeksRangeModel,
  SensorKtxSchemeViewModel,
  TelemetrySnapshotViewModel,
  TemperatureColorViewModel,
  TemperatureRangeAlarmViewModel,
  TemperatureRangeViewModel,
} from '../../models';
import { ColumnTypeEnum, KtxNotificationFilterNodeTypeEnum, SensorStatusEnum, TutorialMenuEnum } from '../../enums';
import { LocationsKtxDataSourceService, ReportDatasourceService, RoleService } from '../../services';
import { CommonFunctions, ConvertDataFunctions, ErrorModel, UserRoleEnum } from '../../../common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TreeNode } from 'primeng';
import { TutorialPopupComponent } from '../popups';
import { SensorAlarmLabels } from '../../constants';

@Component({
  selector: 'greensleeves-ktx-historical-data-tab',
  templateUrl: './ktx-historical-data-tab.component.html',
})
export class KtxHistoricalDataTabComponent implements OnInit {
  @ViewChild(TutorialPopupComponent, { read: false, static: false })
  private tutorialPopup: TutorialPopupComponent

  _companies: CompanyViewModel[] = [];
  _ktxLocationNodes: TreeNode[] = [];
  _nodeSelected: TreeNode[];
  _isOpenFilterList: boolean = false;
  _isOpenDateFilter: boolean = false;
  _selectedTimeStamp: number;
  _temperatureColorRange: TemperatureColorViewModel[];
  _temperatureInfo: TemperatureRangeAlarmViewModel;
  _snapshots: number[] = [];
  _filterFormBuilder: FormGroup;
  _measuring: { label: string, value: number }[] = [];
  _ktxWeeksRange: KtxWeeksRangeModel[];
  _ktxWeeks: { week: string, range: string, value: number }[] = [];
  _gridData: CableLocationSchemeViewModel[] = [];
  _columnType = ColumnTypeEnum;
  _isLoading: boolean;
  _columnsView = [];
  temperatureTelemetry: TelemetrySnapshotViewModel;
  temperatureRanges: TemperatureRangeViewModel[];
  _frozenColumns = [
    { header: 'Bin', columnType: ColumnTypeEnum.BinName, dataField: 'binName', width: 130, },
    { header: 'Cable', columnType: ColumnTypeEnum.CableName, dataField: 'cableName', width: 50 }];
  _loading: boolean = false;
  _needLoadData: boolean = false;
  _generationReport: boolean = false;

  isOpenLocationList = false;

  private currentHistory: HistoricalBinSnapshots[] = null;
  private isFahrenheitMeasureMode: boolean;
  private isTemperatureMeasureMode: boolean = true;
  private subscriptions: Subscription[] = [];

  get _getSelectedFilterWeek() {
    return this._ktxWeeks[this._filterFormBuilder!.controls!.dateRange!.value - 1].week;
  }

  get _getSelectedFilterMeasure() {
    return this._measuring[this._filterFormBuilder!.controls!.measuring!.value].label;
  }

  get _getSelectedFilterDateRange() {
    return this._ktxWeeks[this._filterFormBuilder!.controls!.dateRange!.value - 1].range;
  }

  get _frozenWidth() {
    return `${this._frozenColumns.reduce((a, v) => a + v.width, 0)}px`;
  }

  get _isFahrenheitMeasureMode() {
    return this.isFahrenheitMeasureMode;
  }

  set _isFahrenheitMeasureMode(value: boolean) {
    this.isFahrenheitMeasureMode = value;
  }

  get _isTemperatureMeasureMode() {
    return this.isTemperatureMeasureMode;
  }

  set _isTemperatureMeasureMode(value: boolean) {
    this.isTemperatureMeasureMode = value;
  }

  get isTutorialUser(): boolean {
    return this._roleService.userHasRole(UserRoleEnum.CompanyAdmin) || this._roleService.userHasRole(UserRoleEnum.CompanyUser);
  }

  get _locationName(): string {
    if (this._nodeSelected.length > 0) {
      let node = this._nodeSelected.find(x => x.data.nodeType == KtxNotificationFilterNodeTypeEnum.Bin);
      const name = this.getLocationName(node);
      return name;
    }
    return '';
  }

  get _needLoad(): boolean {
    return this.currentHistory == null;
  }

  get _temperatureRanges(): TemperatureColorViewModel[] {
    if (!this._temperatureInfo || !this._temperatureInfo.temperatureRanges) {
      return [];
    }

    const temperatures: TemperatureColorViewModel[] = this._isFahrenheitMeasureMode
      ? this._temperatureInfo.temperatureRanges.map<TemperatureColorViewModel>((tr) => ({
        value: this.onCelsiusToFahrenheit(tr.to),
        colorHex: tr.colorHex,
      }))
      : this._temperatureInfo.temperatureRanges.map<TemperatureColorViewModel>((tr) => ({
        value: Math.round(tr.to).toString(),
        colorHex: tr.colorHex,
      }));
    const {
      openTcColorHex,
      noResponseColorHex,
      maxRiseColorHex,
      highResistanceColorHex,
      highTemperatureColorHex,
    } = this._temperatureInfo.alarm;

    const hlValue = this._isTemperatureMeasureMode
      ? highTemperatureColorHex
      : highResistanceColorHex;

    return [
      ...temperatures,
      {
        value: SensorAlarmLabels.OpenTc,
        colorHex: openTcColorHex,
      },
      {
        value: SensorAlarmLabels.NoResponse,
        colorHex: noResponseColorHex,
      },
      {
        value: SensorAlarmLabels.MaxRise,
        colorHex: maxRiseColorHex,
      },
      {
        value: SensorAlarmLabels.HighLimit,
        colorHex: hlValue,
      },
    ];
  }

  constructor(
    private _el: ElementRef,
    private _formBuilder: FormBuilder,
    private _reportDatasource: ReportDatasourceService,
    private _locationKtxDataSourceService: LocationsKtxDataSourceService,
    private _ktxLocationService: LocationsKtxDataSourceService,
    private _roleService: RoleService,
  ) {
  }

  async ngOnInit() {
    this.initListOfWeeksRange();
    this.initMeasuring();

    this._filterFormBuilder = this._formBuilder.group({
      dateRange: [this._ktxWeeks[0].value],
      measuring: [this._measuring[1].value],
    });
    this._isFahrenheitMeasureMode = this._filterFormBuilder.controls.measuring.value == 1;
    this.temperatureTelemetry = new TelemetrySnapshotViewModel();
    this.setSubscriptions();
  }

  ngAfterViewInit() {
    this.reInitScrollBarTable(0);
    this.reInitScrollBar(0);
    this._el.nativeElement.querySelector('.ui-table-scrollable-view.ui-table-unfrozen-view .ui-table-scrollable-body').classList.add('is-scrollable-y', 'is-scrollable-x');
  }

  ngOnDestroy() {
    this.subscriptions && this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  openDateFilter() {
    this._isOpenDateFilter = !this._isOpenDateFilter;
    if (this._isOpenDateFilter) {
      this._isOpenFilterList = false;
      this.isOpenLocationList = false;
    }
  }

  openLocationList(): void {
    this.isOpenLocationList = !this.isOpenLocationList;
    if (this.isOpenLocationList) {
      this._isOpenDateFilter = false;
      this._isOpenFilterList = false;
    }
  }

  openFilterList(): void {
    this._isOpenFilterList = !this._isOpenFilterList;
    if (this._isOpenFilterList) {
      this._isOpenDateFilter = false;
      this.isOpenLocationList = false;
    }
  }

  markAsSelectedDateRange(index: number) {
    this._filterFormBuilder.controls.dateRange.setValue([this._ktxWeeks[index].value]);
    this.openDateFilter();
    this.clearView();
  }

  markAsSelectedMeasuring(index: number) {
    this._filterFormBuilder.controls.measuring.setValue([this._measuring[index].value]);
    this._isFahrenheitMeasureMode = this._filterFormBuilder.controls.measuring.value == 1;
    this.openFilterList();
  }

  fetchLocationDetails() {
    this._locationKtxDataSourceService.getTemperatureRangesForLocation(this.getLocationIdFromSelected()).then((result) => {
      this._temperatureInfo = result as TemperatureRangeAlarmViewModel;
      this._temperatureColorRange = TemperatureRangeViewModel.toTemperatureColorViewModels(this._temperatureInfo.temperatureRanges);
    });
  }

  fetchListOfSnapshots() {
    if (this._filterFormBuilder && this._filterFormBuilder.controls['dateRange'].value && this.getBinIdsFromSelected().length > 0) {
      this._gridData = [];
      this._columnsView = [];
      this.currentHistory = null;
      let weekFromTS = Math.floor(this._ktxWeeksRange[this._filterFormBuilder!.controls!.dateRange!.value - 1].weekFrom.getTime() / 1000);
      let weeksToTS = Math.floor(this._ktxWeeksRange[this._filterFormBuilder!.controls!.dateRange!.value - 1].weekTo.getTime() / 1000);

      this._locationKtxDataSourceService.getHistoricalBinsSnapshotForThePeriod(
        this.getBinIdsFromSelected(),
        weekFromTS,
        weeksToTS).then((x) => {
          let result = x as HistoricalBinsSnapshots;
          this.currentHistory = result.binSnapshots;
          this._snapshots = result.timeStamps && result.timeStamps.sort(function (a, b) { return b - a; });
          if (this._snapshots && this._snapshots.length > 0) {
            this._selectedTimeStamp = this._snapshots[0];
            this.fetchHistoricalSnapshotByTimeStamp(this._selectedTimeStamp);
          }
          else {
            this._loading = false;
          }
          this._needLoadData = false;
        });
    }
    else {
      this._loading = false;
    }
  }

  fetchHistoricalSnapshotByTimeStamp(timestamp: number) {
    this._gridData = [];
    let sensorCount: number[] = [1];
    const selectedBins = this._nodeSelected.filter(x => x.data.nodeType == KtxNotificationFilterNodeTypeEnum.Bin);
    let bins: { id: number, name: string }[] = selectedBins.map(x => {
      return {
        id: x.data.id,
        name: x.parent && x.parent.data.nodeType == KtxNotificationFilterNodeTypeEnum.Unit ? `${x.parent.label}: ${x.label}` : x.label,
      }
    });
    bins = bins.sort((a, b) => CommonFunctions.compareStrings(a.name, b.name));
    bins.forEach(bin => {
      const binSnapshots = this.currentHistory.find(x => x.binId == bin.id);
      if (binSnapshots) {
        let snapshots = binSnapshots.cableSnapshotsByPeriods.find(x => x.timeStamp == timestamp);
        if (snapshots) {
          let cableData = snapshots.cableSnapshots.sort((a, b) => a.name - b.name);
          cableData.forEach(cable => {
            this._gridData.push({
              binName: bin.name,
              cableName: cable.name.toString(),
              sensors: cable.sensors,
            })
            sensorCount.push(cable.sensors.length);
          });
        } else {
          this._gridData.push({
            binName: bin.name,
            cableName: '-',
            sensors: [],
          })
        }
      } else {
        this._gridData.push({
          binName: bin.name,
          cableName: '-',
          sensors: [],
        })
      }
    });
    this._columnsView = [];
    let maxSensorCount = Math.max(...sensorCount);
    for (let i = 1; i <= maxSensorCount; i++) {
      this._columnsView.push({ header: 'TC ' + i, columnType: ColumnTypeEnum.TC, dataField: 'sensors' })
    }
    this._loading = false;
  }

  getDate(unix: number) {
    var date = new Date(unix * 1000);
    let result = date.toLocaleString('default', { day: 'numeric' }) + ' ' + date.toLocaleString('default', { month: 'short' }) + ' ' + date.toLocaleString('default', { year: 'numeric' });
    return result;
  }

  getTime(unix: number) {
    var date = new Date(unix * 1000);
    let result = date.toLocaleTimeString();
    return result;
  }

  async onClickUnselectBinFilter(event) {
    if (this._nodeSelected.length == 0) {
      this.clearView();
      return;
    }

    this.fetchHistoricalSnapshotByTimeStamp(this._selectedTimeStamp);
  }

  private getLocationIdFromSelected(): number {
    const locationNode = this._nodeSelected.find(x => x.data.nodeType == KtxNotificationFilterNodeTypeEnum.Location);
    if (locationNode) {
      return locationNode.data.id;
    } else {
      return this._nodeSelected[0].data.locationId;
    }
  }

  private getBinIdsFromSelected(): number[] {
    const bins = this._nodeSelected.filter(x => x.data.nodeType == KtxNotificationFilterNodeTypeEnum.Bin);
    return bins.map(x => x.data.id);
  }

  private makeParentsPartialSelected(node: TreeNode, selected: boolean = true) {
    do {
      if (!node.parent) return;
      node.parent.partialSelected = selected;
      node = node.parent;
    } while (node.parent);
  }

  private selectChildren(node: TreeNode) {
    node.children.forEach(x => {
      this._nodeSelected.push(x);
      if (x.children) {
        x.expanded = true;
        this._nodeSelected.push(...x.children);
      }
    });
  }

  private makeParentsPartialUnSelected(nodes: TreeNode[]) {
    nodes.forEach(x => { if (x.parent) x.parent.partialSelected = false });
  }

  async onClickSelectBinFilter(event) {
    if (!event.node.expanded) {
      event.node.expanded = true;
    }
    if (event.node.data.nodeType == KtxNotificationFilterNodeTypeEnum.Location) {
      this.makeParentsPartialUnSelected(this._nodeSelected);
      this._nodeSelected = [];
      this._nodeSelected = [event.node];
      this.selectChildren(event.node);
      this.makeParentsPartialSelected(event.node);
      this.clearView();
    }

    if (event.node.data.nodeType == KtxNotificationFilterNodeTypeEnum.Unit) {
      if (this._nodeSelected.length > 0) {
        let locationIds = this._nodeSelected.filter(x => x.data.nodeType == KtxNotificationFilterNodeTypeEnum.Bin).map(x => x.data.locationId).filter((value, index, array) => array.indexOf(value) === index);
        if (locationIds.length > 1) {
          this.makeParentsPartialUnSelected(this._nodeSelected);
          this._nodeSelected = [];
          this._nodeSelected = [event.node];
          this.selectChildren(event.node);
          this.makeParentsPartialSelected(event.node);
          this.clearView();
        } else {
          var unitBins = event.node.children.map(x => x.data.id);
          let currentHistoryHasAllUnitBins: boolean = true;
          unitBins.forEach(bin => {
            if (!this.currentHistory || !this.currentHistory.find(x => x.binId == bin)) {
              currentHistoryHasAllUnitBins = false;
            }
          });
          if (currentHistoryHasAllUnitBins) {
            this.fetchHistoricalSnapshotByTimeStamp(this._selectedTimeStamp);
          } else {
            this._needLoadData = true;
          }
        }
      }
    }

    if (event.node.data.nodeType == KtxNotificationFilterNodeTypeEnum.Bin) {
      if (this._nodeSelected.length > 0) {
        let locationIds = this._nodeSelected.filter(x => x.data.nodeType == KtxNotificationFilterNodeTypeEnum.Bin).map(x => x.data.locationId).filter((value, index, array) => array.indexOf(value) === index);
        if (locationIds.length > 1) {
          this.makeParentsPartialUnSelected(this._nodeSelected);
          this._nodeSelected = [];
          this._nodeSelected = [event.node];
          this.makeParentsPartialSelected(event.node);
          this.clearView();
        }
        if (this.currentHistory && this.currentHistory.find(x => x.binId == event.node.data.id)) {
          this.fetchHistoricalSnapshotByTimeStamp(this._selectedTimeStamp);
        } else {
          this._needLoadData = true;
        }
      }
    }
  }

  onClickTimeStamp(timestamp: number) {
    if (timestamp) {
      this._selectedTimeStamp = timestamp;
      this.fetchHistoricalSnapshotByTimeStamp(timestamp);
    }
  }

  async onClickExportReport() {
    if (this._filterFormBuilder && this._filterFormBuilder.controls['dateRange'].value && this.getBinIdsFromSelected().length > 0) {
      const filter = {
        locationId: this.getLocationIdFromSelected(),
        binIds: this.getBinIdsFromSelected(),
        temperatureMeasureType: this._measuring[this._filterFormBuilder!.controls!.measuring!.value].value,
        timestampFrom: Math.floor(this._ktxWeeksRange[this._filterFormBuilder!.controls!.dateRange!.value - 1].weekFrom.getTime() / 1000),
        timestampTo: Math.floor(this._ktxWeeksRange[this._filterFormBuilder!.controls!.dateRange!.value - 1].weekTo.getTime() / 1000),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        historySnapshots: [],
      } as KtxReportHistoricalDataFilterForPeriod;

      if (this.getBinIdsFromSelected().length == 1) {
        filter.historySnapshots = this.currentHistory.filter(x => x.binId == this._nodeSelected[0].data.id);
      } else {
        filter.binIds.forEach(bin => {
          let binHistory = this.currentHistory.find(x => x.binId == bin);
          if (binHistory) {
            let snapshots = binHistory.cableSnapshotsByPeriods.find(x => x.timeStamp == this._selectedTimeStamp);
            if (snapshots) {
              filter.historySnapshots.push({ binId: bin, cableSnapshotsByPeriods: [snapshots] });
            }
          }
        });
      }

      const locationName = this._locationName;
      this._generationReport = true;
      let result = await this._reportDatasource.downloadKtxHistoricalReportFile(filter);
      if (!(result instanceof ErrorModel)) {
        let fileName = locationName + '';
        fileName += this.getBinIdsFromSelected().length == 1
          ? this._nodeSelected[0].label + ' ' + this._ktxWeeksRange[this._filterFormBuilder!.controls!.dateRange!.value - 1].weekFrom.toLocaleDateString() + ' - ' + this._ktxWeeksRange[this._filterFormBuilder!.controls!.dateRange!.value - 1].weekTo.toLocaleDateString()
          : new Date(this._selectedTimeStamp * 1000).toLocaleDateString() + '_' + new Date(this._selectedTimeStamp * 1000).toLocaleTimeString();

        const url = URL.createObjectURL(result as Blob);
        let downloadLink = document.createElement('a');
        downloadLink.href = url;
        downloadLink.download = fileName;
        downloadLink.click();
      }
      this._generationReport = false;
    }
  }

  tryFetchSnapshotList() {
    this._loading = true;
    //this._gridData = [];
    this.fetchListOfSnapshots();
    if ((this._temperatureInfo && this._temperatureInfo.locationId != this.getLocationIdFromSelected()) || !this._temperatureInfo) {
      this.fetchLocationDetails();
    }
  }

  splitStringBySpace(input: string): string[] {
    return input.split(' ');
  }

  private clearView() {
    this._gridData = [];
    this._columnsView = [];
    this.currentHistory = null;
    this._temperatureInfo = null;
    this._temperatureColorRange = null;
    this._snapshots = [];
    this._needLoadData = true;
  }

  private reInitScrollBar(timeout?: number) {
    const el = this._el.nativeElement.querySelector('.popup-container');
  }

  reInitScrollBarTable(timeout?: number, withCheck = false) {
    const el = this._el.nativeElement.querySelector('.ui-table-scrollable-view.ui-table-unfrozen-view .ui-table-scrollable-body');
    if (el) {
    }
  }

  private isAnyChild(treeNode: TreeNode): boolean {
    return treeNode.children && treeNode.children.length > 0;
  }

  _getSensorColor(cable: CableSchemeViewModel, sensorIndex: number): string {
    const sensor = cable && cable.sensors ? cable.sensors[sensorIndex] as SensorKtxSchemeViewModel : null;
    if (sensor) {
      if (sensor.status === SensorStatusEnum.OpenTC) {
        return this._temperatureInfo.alarm.openTcColorHex;
      }

      else if (sensor.status === SensorStatusEnum.NoResponse) {
        return this._temperatureInfo.alarm.noResponseColorHex;
      }

      else {
        if (sensor.status === SensorStatusEnum.HighLimit) {
          return this._temperatureInfo.alarm.highTemperatureColorHex;
        }

        else if (sensor.status === SensorStatusEnum.MaxRise) {
          return this._temperatureInfo.alarm.maxRiseColorHex;
        }

        else if (this._temperatureInfo && this._temperatureInfo.temperatureRanges) {
          const temperatureRange = this._temperatureInfo.temperatureRanges.find(x => sensor.value >= x.from && sensor.value <= x.to);
          return temperatureRange == undefined ? '' : temperatureRange.colorHex;
        }

        return '-';
      }
    }
    return '-';
  }

  _getSensorValue(cable: CableSchemeViewModel, sensorIndex: number): string | number {
    const sensor = cable && cable.sensors ? cable.sensors[sensorIndex] as SensorKtxSchemeViewModel : null;
    if (sensor) {
      if (sensor.status === SensorStatusEnum.OpenTC) {
        return 'OT';
      }

      if (sensor.status === SensorStatusEnum.NoResponse) {
        return '-';
      }

      if (this.isFahrenheitMeasureMode) {
        const FahrenheitValue = this.onCelsiusToFahrenheit(sensor.value);
        return FahrenheitValue;
      }

      return sensor.value;
    }

    if (cable && cable.sensors && cable.sensors.length == 0) {
      return '-';
    }

    return '';
  }

  onCelsiusToFahrenheit(value: number): string {
    if (value || value === 0) {
      const convertedValue = ConvertDataFunctions.celsiusToFahrenheit(value);
      return convertedValue.toString();
    } else {
      return '-';
    }
  }

  getBackGroundTextColor(cable: CableSchemeViewModel, sensorIndex: number): string {
    const sensor = cable ? cable[sensorIndex] as SensorKtxSchemeViewModel : null;

    if (sensor) {
      if (sensor.status === SensorStatusEnum.OpenTC) {
        return '#FFFFFF';
      }
    }

    return '';
  }

  getWeekByIndex(index: number) {
    return this._ktxWeeks[index].week;
  }

  getDateRangeByIndex(index: number) {
    return this._ktxWeeks[index].range;
  }

  getMeasureByIndex(index: number) {
    return this._measuring[index].label;
  }

  setSubscriptions(): void {
    const companiesSubscription =
      this._ktxLocationService.companiesWithLocations$.subscribe(
        (companies) => {
          this._companies = companies;
          this.initCompanyWithLocationsNodes(companies);
        }
      );

    const withBins = true;
    this._ktxLocationService.getCompaniesWithLocations(withBins);
    this.subscriptions.push(companiesSubscription);
  }

  private initCompanyWithLocationsNodes(companies: CompanyViewModel[]) {
    this._nodeSelected = [];
    const companyWithLocationNodes =
      companies && companies
        .map(CompanyViewModel.toKtxTreeNode)
        .filter(c => c.children && c.children.length > 0)
        .sort(CommonFunctions.compareTreeNodeLabels);

    companyWithLocationNodes.forEach(x => x.selectable = false);
    this._ktxLocationNodes = companyWithLocationNodes;
  }

  private initListOfWeeksRange() {
    const weeks: KtxWeeksRangeModel[] = [];
    const currentDate = new Date();
    for (let i = 1; i <= 78; i++) {
      const weekStartDate = new Date(currentDate);
      weekStartDate.setDate(currentDate.getDate() - i * 7);
      weekStartDate.setHours(0, 0, 0, 0);

      const weekEndDate = new Date(currentDate);
      weekEndDate.setDate(currentDate.getDate() - i * 7 + 7);
      weekEndDate.setHours(23, 59, 59, 999);

      const model = new KtxWeeksRangeModel(i, weekStartDate, weekEndDate);
      this._ktxWeeks.push({ week: i + ' week ago ', range: weekStartDate.toLocaleDateString() + ' - ' + weekEndDate.toLocaleDateString(), value: i });
      weeks.push(model);
    }

    this._ktxWeeksRange = weeks;
  }

  private initMeasuring() {
    this._measuring.push({ label: 'C', value: 0 });
    this._measuring.push({ label: 'F', value: 1 });
  }

  showTutorial() {
    this.tutorialPopup.showPopup(TutorialMenuEnum.KtxHistoricalData);
  }

  private getLocationName(node: TreeNode) {
    if (node.parent) {
      if (node.parent.data.nodeType == KtxNotificationFilterNodeTypeEnum.Unit) {
        return this.getLocationName(node.parent);
      }
      if (node.parent.data.nodeType == KtxNotificationFilterNodeTypeEnum.Location) {
        return node.parent.label;
      }
    }
    return '';
  }
}
