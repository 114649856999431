import { Component, Input } from '@angular/core';
import { SortEvent } from 'primeng';

import { BtxSensorWithTelemetryViewModel } from '@models';

import { ColumnTypeEnum, SensorValuesStatusEnum } from '../../../../enums';
import { CommonFunctions } from '../../../../../common';
import { NameViewModel } from '../../../../models/common.model';

@Component({
  selector: 'greensleeves-btx-equipment-sensors-grid',
  templateUrl: './btx-equipment-sensors-grid.component.html',
})
export class BtxEquipmentSensorsGridComponent {
  @Input() sensors: BtxSensorWithTelemetryViewModel[];
  @Input() loading: boolean;

  _columnTypeEnum = ColumnTypeEnum;
  _columnsView = [
    {
      header: '',
      columnType: ColumnTypeEnum.SensorIndex,
      dataField: 'index',
      getLabel: (sensor: BtxSensorWithTelemetryViewModel) => sensor.index,
    },
    {
      header: 'Sensor Type',
      columnType: ColumnTypeEnum.Type,
      dataField: 'type',
      isSortable: true,
      getLabel: (sensor: BtxSensorWithTelemetryViewModel) => sensor.type.name,
    },
    {
      header: 'Position',
      columnType: ColumnTypeEnum.SensorPosition,
      dataField: 'position',
      isSortable: true,
      getLabel: (sensor: BtxSensorWithTelemetryViewModel) =>
        sensor.position.name,
    },
    {
      header: 'Side',
      columnType: ColumnTypeEnum.SensorSide,
      dataField: 'side',
      isSortable: true,
      getLabel: (sensor: BtxSensorWithTelemetryViewModel) => sensor.side.name,
    },
    {
      header: 'Value',
      columnType: ColumnTypeEnum.SensorValue,
      dataField: 'value',
      getLabel: (sensor: BtxSensorWithTelemetryViewModel) =>
        sensor.value !== null
          ? `${sensor.value} ${sensor.sensorMeasure}`
          : 'No data',
    },
    {
      header: 'Status',
      columnType: ColumnTypeEnum.Status,
      dataField: 'status',
      getLabel: (sensor: BtxSensorWithTelemetryViewModel) =>
        sensor.eventType ? sensor.eventType.name : sensor.status.name,
    },
  ];

  constructor() { }

  getSensorColor(sensorId: number) {
    let sensor = this.sensors.find(x => x.id == sensorId);

    if (!sensor) {
      return 'white';
    }

    const status = sensor.status.id;

    switch (status) {
      case SensorValuesStatusEnum.Norm:
        return 'green';
      case SensorValuesStatusEnum.Alarm:
        return 'red';
      case SensorValuesStatusEnum.Warning:
        return 'yellow';
      case SensorValuesStatusEnum.Error:
        return 'grey';
    }
  }

  customSort(event: SortEvent) {
    const field = event.field;
    const order = event.order;

    this.sensors.sort((sensorA, sensorB) => {
      const valueA = sensorA[field];
      const valueB = sensorB[field];

      let result = null;

      if (valueA == null && valueB != null) {
        result = -1;
      } else if (valueA != null && valueB == null) {
        result = 1;
      } else if (valueA == null && valueB == null) {
        result = 0;
      } else if (typeof valueA === 'string' && typeof valueB === 'string') {
        result = CommonFunctions.compareStrings(valueA, valueB);
      } else if (
        valueA instanceof NameViewModel &&
        valueB instanceof NameViewModel
      ) {
        result = CommonFunctions.compareStrings(valueA.name, valueB.name);
      } else {
        result = valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
      }

      return result * order;
    });
  }
}
