import { AfterViewChecked, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CommonFunctions, ErrorModel } from '../../../../../common';
import { SideAB, equipmentSchemes, equipmentSensorMapping, equipmentSideLabels, sensorPositionLabels, sensorTypeLabels } from '../../../../constants';
import { EquipmentSideEnum, EquipmentSubTypeEnum, EquipmentTypeEnum, SensorPositionEnum, SensorTypeEnum, SensorValuesStatusEnum } from '../../../../enums';

import { EquipmentViewModel, SensorViewModel } from '../../../../models';
import { LastTelemetrySensorResponse } from '../../../../models/lastTelemetrySensorResponse.model';
import { LocationsBtxDataSourceService } from '../../../../services';
import { BasePopupBehavior } from '../../common';


@Component({
  selector: 'greensleeves-equipment-view-data',
  templateUrl: './equipment-view-data.component.html',
  styles: []
})
export class EquipmentViewDataPopupComponent extends BasePopupBehavior implements OnInit, OnDestroy, AfterViewChecked {
  _schema: Array<{ className: string, markers: Array<{ className: string, sensorType: SensorTypeEnum, sensorPosition: SensorPositionEnum, sensorId: number, sensorValueData: string, sensorMarkerData: string, sensorMarkerColor: string, sensorTableData: string, tooltip: string }> }> = [];

  _view = 'view';
  _isHidden = true;
  _sensorPosition = 'sensorPosition';
  _equipmentSide = 'equipmentSide';
  _activeEquipment: EquipmentViewModel;
  _currentLocationId: number;
  _submitted = false;
  _sensorForm: FormGroup;
  _equipmentSensors: LastTelemetrySensorResponse[];
  _receivingTimestamp: string;
  _leftSchemeSideName: string = null;
  _rightSchemeSideName: string = null;
  _sensorTypeEnum = SensorTypeEnum;

  _sensorTypes: Array<{ label: string, value: number }> = [];
  _sensorPositions: Array<{ label: string, value: number }> = [];
  _equipmentSides: Array<{ label: string, value: number }> = [];

  _sensorTempTable: Array<{ label: string, value: string, sensorMarkerColor: string, isRightSide: boolean }> = [];

  _sensorTempTableForLeftSchemeLeftColumn: Array<{ label: string, value: string, sensorMarkerColor: string }> = [];
  _sensorTempTableForLeftSchemeRightColumn: Array<{ label: string, value: string, sensorMarkerColor: string }> = [];
  _sensorTempTableForRightSchemeLeftColumn: Array<{ label: string, value: string, sensorMarkerColor: string }> = [];
  _sensorTempTableForRightSchemeRightColumn: Array<{ label: string, value: string, sensorMarkerColor: string }> = [];
  _sensorTempTableOnePerEquipmentSensors: Array<{ label: string, value: string, sensorMarkerColor: string, sensorType: SensorTypeEnum }> = [];

  constructor(
    private _el: ElementRef,
    private _locationBtxService: LocationsBtxDataSourceService,) {
    super();
  }

  ngAfterViewInit() {
    this.reInitScrollBar(0);
  }

  public show(equipment: EquipmentViewModel, locationId: number) {
    this._schema = [];
    this._sensorTempTable = [];
    this._sensorTempTableForLeftSchemeLeftColumn = [];
    this._sensorTempTableForLeftSchemeRightColumn = [];
    this._sensorTempTableForRightSchemeLeftColumn = [];
    this._sensorTempTableForRightSchemeRightColumn = [];
    this._sensorTempTableOnePerEquipmentSensors = [];
    this._receivingTimestamp = '';
    this._activeEquipment = equipment;
    this._currentLocationId = locationId;
    this.getLastDataForEquipment();
    this.buildSchema();
    this._isHidden = false;
  }

  onClickClose() {
    this._isHidden = true;
    this._activeEquipment = null;
    this._equipmentSensors = [];
  }

  buildSchema() {
    this._leftSchemeSideName = null;
    this._rightSchemeSideName = null;

    if (!this._activeEquipment) {
      this._schema = [];
      return;
    }

    this.sortSensors();
    const mapping = equipmentSensorMapping[this._activeEquipment.type][this._activeEquipment.subType];

    let selectedSideA: EquipmentSideEnum;
    let selectedSideB: EquipmentSideEnum;

    if (this._activeEquipment.sensors && this._activeEquipment.sensors.length > 0 && (this._activeEquipment.sensors[0].side as EquipmentSideEnum === EquipmentSideEnum.North ||
      this._activeEquipment.sensors[0].side as EquipmentSideEnum === EquipmentSideEnum.South)) {
      selectedSideA = EquipmentSideEnum.South;
      selectedSideB = EquipmentSideEnum.North;
    } else {
      selectedSideA = EquipmentSideEnum.East;
      selectedSideB = EquipmentSideEnum.West;
    }

    this._leftSchemeSideName = EquipmentSideEnum[selectedSideA];
    this._rightSchemeSideName = EquipmentSideEnum[selectedSideB];

    const sides = [
      equipmentSchemes[this._activeEquipment.type][this._activeEquipment.subType][0],
      equipmentSchemes[this._activeEquipment.type][this._activeEquipment.subType][selectedSideA],
      equipmentSchemes[this._activeEquipment.type][this._activeEquipment.subType][selectedSideB]
    ];
    let sensorTempTableOnePerEquipmentSensors: Array<{ label: string, value: string, sensorMarkerColor: string, sensorType: SensorTypeEnum }> = [];
    this._sensorTempTableOnePerEquipmentSensors = [];

    const shouldAddNonSided = Object.keys(sides).some(x => Number(x) as EquipmentSideEnum === EquipmentSideEnum.None);
    this._schema = Object.entries(sides)
      .filter(([side, schemaClassName]) => !!schemaClassName)
      .map(([side, schemaClassName]) => ({ side: Number(side) as EquipmentSideEnum, schemaClassName: schemaClassName as string }))
      .map(({ side, schemaClassName: className }) => {
        const markers = this._activeEquipment.sensors
          .map((sensor, index) => ({ ...sensor, index: index + 1 }))
          .filter(sensor => sensor.side === side || sensor.side === SideAB[side] || (shouldAddNonSided && sensor.side === EquipmentSideEnum.None))
          .map(sensor => ({
            sensorId: sensor.id,
            sensorType: sensor.type,
            sensorPosition: sensor.position,
            sensorTableData: sensor.position == SensorPositionEnum.OnePerEquipment ? sensorTypeLabels[sensor.type] : sensorPositionLabels[sensor.position] + ',\n' + sensorTypeLabels[sensor.type],
            sensorValueData: this.getSensorValue(sensor.id, sensor.type),
            sensorMarkerData: sensor.type != SensorTypeEnum.Vibration ? sensorTypeLabels[sensor.type] : sensorPositionLabels[sensor.position] + ',\n' + sensorTypeLabels[sensor.type],
            sensorMarkerColor: this.getSensorColor(sensor.id),
            className: mapping[sensor.type][sensor.position][sensor.side],
            tooltip: sensor.index + ' - ' + sensorPositionLabels[sensor.position] + '\n' + sensorTypeLabels[sensor.type],
          }))
          .filter(marker => !!marker.className);
        return {
          className,
          markers
        };
      });

    if (this._equipmentSensors && this._equipmentSensors.length > 0) {
      const date = new Date(this._equipmentSensors[0].timestamp * 1000);
      this._receivingTimestamp = 'Last updating timestamp: ' + date.toLocaleString("en-US");
    }

    this._sensorTempTable = [];

    if (this._activeEquipment) {
      let isRightTableSide = false;
      this._schema.forEach(s => {
        s.markers.forEach(m => {
          if (this._activeEquipment.subType === EquipmentSubTypeEnum.Belt) {
            if (!this.isThisSensorForConveyorBeltScheme(m.sensorType, m.sensorPosition) && (m.sensorPosition != SensorPositionEnum.OnePerEquipment && m.sensorType != SensorTypeEnum.Vibration)) {
              this._sensorTempTable.push({ label: m.sensorTableData, value: m.sensorValueData, isRightSide: isRightTableSide, sensorMarkerColor: m.sensorMarkerColor })
            }
          }
          if (this._activeEquipment.type != EquipmentTypeEnum.Fan && (m.sensorPosition == SensorPositionEnum.OnePerEquipment || m.sensorType == SensorTypeEnum.Vibration)) {
            sensorTempTableOnePerEquipmentSensors.push({ label: m.sensorTableData, value: m.sensorValueData, sensorMarkerColor: m.sensorMarkerColor, sensorType: m.sensorType })
          }
        }), isRightTableSide = true
      });
      if (this._activeEquipment.subType === EquipmentSubTypeEnum.Belt) {
        this._sensorTempTableForRightSchemeLeftColumn = this._sensorTempTable.filter(stt => stt.isRightSide).filter((a, i) => i % 2 == 0);
        this._sensorTempTableForRightSchemeRightColumn = this._sensorTempTable.filter(stt => stt.isRightSide).filter((a, i) => i % 2 !== 0);

        this._sensorTempTableForLeftSchemeLeftColumn = this._sensorTempTable.filter(stt => !stt.isRightSide).filter((a, i) => i % 2 === 0);
        this._sensorTempTableForLeftSchemeRightColumn = this._sensorTempTable.filter(stt => !stt.isRightSide).filter((a, i) => i % 2 !== 0);

        this._schema.forEach(sc => sc.markers = sc.markers.filter(st => this.isThisSensorForConveyorBeltScheme(st.sensorType, st.sensorPosition)));
      }
      if (sensorTempTableOnePerEquipmentSensors.length > 0) {
        this._schema.forEach(sc => sc.markers = sc.markers.filter(st => st.sensorPosition != SensorPositionEnum.OnePerEquipment && st.sensorType != SensorTypeEnum.Vibration));
        sensorTempTableOnePerEquipmentSensors.forEach(s => {
          if (!this._sensorTempTableOnePerEquipmentSensors.some(stf => stf.label == s.label)) {
            this._sensorTempTableOnePerEquipmentSensors.push({ label: s.label, value: s.value, sensorMarkerColor: s.sensorMarkerColor, sensorType: s.sensorType });
          }
        });
      }
    }
  }

  private isThisSensorForConveyorBeltScheme(sensorType: SensorTypeEnum, sensorPosition: SensorPositionEnum) {
    let isThisSensorForConveyorBeltScheme = (sensorType == SensorTypeEnum.BearingTemperature && sensorPosition == SensorPositionEnum.Head) ||
      (sensorType == SensorTypeEnum.RubBlockTemperature && sensorPosition == SensorPositionEnum.Head) ||
      (sensorType == SensorTypeEnum.BearingTemperature && sensorPosition == SensorPositionEnum.BendHead) ||
      (sensorType == SensorTypeEnum.RubBlockTemperature && sensorPosition == SensorPositionEnum.BendHead) ||
      (sensorType == SensorTypeEnum.BearingTemperature && sensorPosition == SensorPositionEnum.Tail) ||
      (sensorType == SensorTypeEnum.RubBlockTemperature && sensorPosition == SensorPositionEnum.Tail) ||
      (sensorType == SensorTypeEnum.BearingTemperature && sensorPosition == SensorPositionEnum.BendTail) ||
      (sensorType == SensorTypeEnum.RubBlockTemperature && sensorPosition == SensorPositionEnum.BendTail)

    return isThisSensorForConveyorBeltScheme;
  }

  private reInitScrollBar(timeout?: number) {
    const el = this._el.nativeElement.querySelector('.popup-container');
    this.reInitMainScrollBar(el, timeout);
  }

  private getSensorValue(sensorId: number, sensorType: SensorTypeEnum) {
    let sensor = this._equipmentSensors.find(x => x.id == sensorId);
    if (sensor) {
      return sensor.value.toString() + this.getMeasure(sensorType);
    } else {
      return 'No data'
    }
  }

  private getSensorColor(sensorId: number) {
    let sensor = this._equipmentSensors.find(x => x.id == sensorId);
    if (sensor) {
      if (sensor.status === SensorValuesStatusEnum.Norm) { return 'white'; }
      if (sensor.status === SensorValuesStatusEnum.Alarm) { return 'red'; }
      if (sensor.status === SensorValuesStatusEnum.Warning) { return 'yellow'; }
      if (sensor.status === SensorValuesStatusEnum.Error) { return 'grey'; }
    } else {
      return 'white';
    }
  }

  private sortSensors() {
    if (!this._activeEquipment) {
      return;
    }

    const sortByPosition = (a: SensorViewModel, b: SensorViewModel) => {
      const aString = `${sensorTypeLabels[a.type]}${sensorPositionLabels[a.position]}${equipmentSideLabels[a.side]}`;
      const bString = `${sensorTypeLabels[b.type]}${sensorPositionLabels[b.position]}${equipmentSideLabels[b.side]}`;
      if (aString < bString) {
        return -1;
      }
      if (aString > bString) {
        return 1;
      }
      return 0;
    };
    this._activeEquipment.sensors = this._activeEquipment.sensors.sort(sortByPosition);
  }

  private async getLastDataForEquipment() {
    this._equipmentSensors = [];
    let equipmentSensorIds = this._activeEquipment.sensors.map(x => x.id);
    const result = await this._locationBtxService.getLastData(this._currentLocationId, this._activeEquipment.id, equipmentSensorIds);
    if (result instanceof ErrorModel) {
    } else {
      this._equipmentSensors = result;
    }

    this.buildSchema();
  }

  private getMeasure(sensorType: SensorTypeEnum): string {
    return CommonFunctions.btxGetSensorMeasure(sensorType);
  }
}