import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { EquipmentViewDataPopupComponent } from '@popups';

import {
  EquipmentSchemaViewModel,
  EquipmentViewModel,
} from '@models';
import {
  equipmentSchemes,
  equipmentSensorMapping,
  sensorPositionLabels,
  sensorTypeLabels,
} from '../../../../constants';
import { EquipmentSideEnum } from '../../../../enums';

@Component({
  selector: 'greensleeves-btx-equipment-schema',
  templateUrl: './btx-equipment-schema.component.html',
})
export class BtxEquipmentSchemaComponent implements OnChanges {
  @Input() activeEquipment: EquipmentViewModel;
  @Input() locationId: number;

  _equipmentSchemas: EquipmentSchemaViewModel[] = [];
  _receivingTimestamp: string = '';

  @ViewChild(EquipmentViewDataPopupComponent, { read: false, static: false })
  private _equipmentViewDataModal: EquipmentViewDataPopupComponent;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    this._receivingTimestamp = '';
    if (changes.activeEquipment) {
      this.buildSchema();
    }
  }

  buildSchema() {
    if (!this.activeEquipment) {
      this._equipmentSchemas = [];
      return;
    }

    const mapping =
      equipmentSensorMapping[this.activeEquipment.type][
      this.activeEquipment.subType
      ];

    let selectedSideA: EquipmentSideEnum;
    let selectedSideB: EquipmentSideEnum;

    if (
      this.activeEquipment.sensors &&
      this.activeEquipment.sensors.length > 0 &&
      ((this.activeEquipment.sensors[0].side as EquipmentSideEnum) ===
        EquipmentSideEnum.North ||
        (this.activeEquipment.sensors[0].side as EquipmentSideEnum) ===
        EquipmentSideEnum.South)
    ) {
      selectedSideA = EquipmentSideEnum.South;
      selectedSideB = EquipmentSideEnum.North;
    } else {
      selectedSideA = EquipmentSideEnum.East;
      selectedSideB = EquipmentSideEnum.West;
    }

    const sides = [
      equipmentSchemes[this.activeEquipment.type][
      this.activeEquipment.subType
      ][0],
      equipmentSchemes[this.activeEquipment.type][this.activeEquipment.subType][
      selectedSideA
      ],
      equipmentSchemes[this.activeEquipment.type][this.activeEquipment.subType][
      selectedSideB
      ],
    ];

    const shouldAddNonSided = Object.keys(sides).some(
      x => (Number(x) as EquipmentSideEnum) === EquipmentSideEnum.None,
    );
    this._equipmentSchemas = Object.entries(sides)
      .filter(([side, schemaClassName]) => !!schemaClassName)
      .map(([side, schemaClassName]) => ({
        side: Number(side) as EquipmentSideEnum,
        schemaClassName: schemaClassName as string,
      }))
      .map(({ side, schemaClassName: className }) => {
        const markers = this.activeEquipment.sensors
          .map((sensor, index) => ({ ...sensor, index: index + 1 }))
          .filter(
            sensor =>
              sensor.side === side ||
              sensor.side === side + 2 ||
              (shouldAddNonSided && sensor.side === EquipmentSideEnum.None),
          )
          .map(sensor => ({
            index: sensor.index,
            tooltip:
              sensor.index +
              ' - ' +
              sensorPositionLabels[sensor.position] +
              '\n' +
              sensorTypeLabels[sensor.type],
            className: mapping[sensor.type][sensor.position][sensor.side],
          }))
          .filter(marker => !!marker.className);

        const date = new Date(this.activeEquipment.timestamp * 1000);
        const receivingTimestamp = !this.activeEquipment.timestamp ? 'No data' : date.toLocaleString('en-US')
        this._receivingTimestamp = receivingTimestamp;

        return {
          className,
          markers,
        };
      });
  }

  onClickViewData(equipment: EquipmentViewModel) {
    this._equipmentViewDataModal.show(equipment, this.locationId);
  }
}
