import { TreeNode } from 'primeng';
import { BinNameGetModel, BinNameViewModel } from '../bin.model';
import { ModelsTypes } from '../../constants';
import { BtxNotificationFilterNodeTypeEnum, EquipmentSideEnum, EquipmentSubTypeEnum, EquipmentTypeEnum, KtxNotificationFilterNodeTypeEnum, SensorPositionEnum, SensorTypeEnum } from '../../enums';
import { NotificationFilterTreeNodeData } from '../notifications.model';
import { CommonFunctions } from '../../../common';
import { EquipmentNameGetModel, EquipmentNameViewModel } from '../equipment-btx.model';
import { ModbussAddress } from '../../components';

export class LocationViewModel {
    public id: number;
    public name: string;
    public companyId: number;
    public companyName: string;
    public serviceEmail: string;
    public servicePhone: string;
    public emergencyPhone: string;
    public sitePhone: string;
    public siteEmail: string;
    public address: string;
    public hasActiveDevice: boolean;

    //User behavior lock fields
    public isSomeoneUpdateLocation: boolean;
    public isDeviceInInitialization: boolean;

    //Infrastructure fields
    modelType = ModelsTypes.locationViewModel;
}

export class LocationGetModel {
    public id: number;
    public name: string;
    public companyId: number;
    public companyName: string;
    public serviceEmail: string;
    public servicePhone: string;
    public emergencyPhone: string;
    public sitePhone: string;
    public siteEmail: string;
    public address: string;
    public hasActiveDevice: boolean;

    public static toViewModel(getModel: LocationGetModel): LocationViewModel {
        const viewModel = new LocationViewModel();
        viewModel.id = getModel.id;
        viewModel.name = getModel.name;
        viewModel.companyId = getModel.companyId;
        viewModel.companyName = getModel.companyName;
        viewModel.serviceEmail = getModel.serviceEmail;
        viewModel.servicePhone = getModel.servicePhone;
        viewModel.emergencyPhone = getModel.emergencyPhone;
        viewModel.sitePhone = getModel.sitePhone;
        viewModel.siteEmail = getModel.siteEmail;
        viewModel.address = getModel.address;
        viewModel.hasActiveDevice = getModel.hasActiveDevice;

        return viewModel;
    }
}

export class LocationPostModel {
    public id: number;
    public name: string;
    public companyId: number;
    public companyName: string;
    public serviceEmail: string;
    public servicePhone: string;
    public emergencyPhone: string;
    public sitePhone: string;
    public siteEmail: string;
    public address: string;
    public hasActiveDevice: boolean;

    constructor(viewModel: LocationViewModel) {
        this.id = viewModel.id;
        this.name = viewModel.name;
        this.companyId = viewModel.companyId;
        this.serviceEmail = viewModel.serviceEmail;
        this.servicePhone = viewModel.servicePhone;
        this.emergencyPhone = viewModel.emergencyPhone;
        this.sitePhone = viewModel.sitePhone;
        this.siteEmail = viewModel.siteEmail;
        this.address = viewModel.address;
        this.hasActiveDevice = viewModel.hasActiveDevice;
    }
}

export class LocationNameViewModel {
    id: number;
    name: string;
    bins: BinNameViewModel[];
    equipments: EquipmentNameViewModel[];

    static toKtxTreeNode(location: LocationNameViewModel): TreeNode {
        const data: NotificationFilterTreeNodeData<KtxNotificationFilterNodeTypeEnum> = {
            id: location.id,
            nodeType: KtxNotificationFilterNodeTypeEnum.Location,
            locationId: location.id,
        };

        const locationWithBinNodes: TreeNode = {
            label: location.name,
            data,
            selectable: true,
            key: data.nodeType + '_' + location.id,
            children: location.bins && location.bins
                .map<TreeNode>(x => BinNameViewModel.toKtxTreeNode(x, location.id))
                .sort(CommonFunctions.compareTreeNodeLabels)
        };

        return locationWithBinNodes;
    }

    static toBtxTreeNode(location: LocationNameViewModel): TreeNode {
        const data: NotificationFilterTreeNodeData<BtxNotificationFilterNodeTypeEnum> = {
            id: location.id,
            nodeType: BtxNotificationFilterNodeTypeEnum.Location,
            locationId: location.id,
        };

        const locationWithEquipmentNodes: TreeNode = {
            label: location.name,
            data,
            selectable: true,
            children: location.equipments && location.equipments
                .map<TreeNode>(x => EquipmentNameViewModel.toBtxTreeNode(x, location.id))
                .sort((a, b) => a.label.localeCompare(b.label)),
        };

        return locationWithEquipmentNodes;
    }
}

export class LocationNameGetModel {
    id: number;
    name: string;
    bins: BinNameGetModel[];
    equipments: EquipmentNameGetModel[];

    public static toViewModel(getModel: LocationNameGetModel): LocationNameViewModel {
        const viewModel = new LocationNameViewModel();

        viewModel.id = getModel.id;
        viewModel.name = getModel.name;

        if (getModel.bins) {
            viewModel.bins = getModel.bins.map(BinNameGetModel.toViewModel);
        }

        if (getModel.equipments) {
            viewModel.equipments = getModel.equipments.map(EquipmentNameGetModel.toViewModel);
        }

        return viewModel;
    }
}

export class BTXLocationConfiguration {
    locationId: number;
    isAnyEquipmentAlreadyExist: boolean;
    deviceId: string;
    equipments: BtxLocationEquipmentConfiguration[];
}

export class BtxLocationEquipmentConfiguration {
    index: number;
    type: EquipmentTypeEnum;
    subType: EquipmentSubTypeEnum;
    name: string;
    totalRunHoursFirstRegisterAddress: number;
    totalRunHoursSecondRegisterAddress: number;
    timeTillMaintenanceAddress: number;
    shutDownAlarmAddress: number;
    shutDownAlarmAddressBit: number;
    maintenanceRequiredWarningAddress: number;
    maintenanceRequiredWarningAddressBit: number;
    maintenaceCountDown: number;
    totalRunHours: number;
    validation: ModbussAddress[];
    isAnyValidationIssuesActive: boolean;
    sensors: BtxLocationSensorConfiguration[];
}

export class BtxLocationSensorConfiguration {
    index: number;
    type: SensorTypeEnum;
    position: SensorPositionEnum;
    side: EquipmentSideEnum;
    modbusAddress: number;
    warningAddress: number;
    warningAddressBit: number;
    alarmAddress: number;
    alarmAddressBit: number;
    rateOfChangeWarningAddress: number;
    rateOfChangeWarningAddressBit: number;
    rateOfChangeAlarmAddress: number;
    rateOfChangeAlarmAddressBit: number;
    validation: ModbussAddress[];
}