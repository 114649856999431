import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, ViewChild, Output } from '@angular/core';

import { AlarmViewModel, LocationKtxViewModel, TemperatureRangePostModel, TemperatureRangeViewModel } from '../../../models';
import { ColumnTypeEnum } from '../../../enums';
import { BasePopupBehavior } from '../common';
import { LocationsKtxDataSourceService } from '../../../services';
import { ConvertDataFunctions, ErrorModel } from '../../../../common';

@Component({
  selector: 'greensleeves-ktx-temperature-ranges-popup',
  templateUrl: './ktx-temperature-ranges-popup.component.html'
})
export class KtxTemperatureRangesPopupComponent extends BasePopupBehavior implements OnInit, OnDestroy, AfterViewInit {
  static FROM_FIELD = 'from';
  static TO_FIELD = 'to';
  static COLOR_HEX_FIELD = 'colorHex';
  static CELSIUS_DELTA_VALUE = 0.54;
  public customPatterns = { '9': { pattern: new RegExp('\\d') } };

  @Output() onClosePopup = new EventEmitter();
  _isHidden: boolean = true;
  _columnTypes = ColumnTypeEnum;
  _rangeForm: FormGroup;
  _alarmForm: FormGroup;
  _alarmSubmitted: boolean;
  _rangeSubmitted: boolean;
  _subscriptions: Subscription[] = [];
  _gridData: TemperatureRangeViewModel[];
  _alarm: AlarmViewModel;
  _uniqueRangeError: boolean;
  _isSubsequentError: boolean;
  _locationId: number;
  _rangeToAdd: TemperatureRangeViewModel;
  _isLoading: boolean;
  _sortField: string = 'from';
  onClickRangeDelete = new EventEmitter<TemperatureRangeViewModel>();

  _columnsView = [
    { header: '#', columnType: ColumnTypeEnum.Number },
    { header: 'From', columnType: ColumnTypeEnum.From, dataField: KtxTemperatureRangesPopupComponent.FROM_FIELD },
    { header: 'To', columnType: ColumnTypeEnum.To, dataField: KtxTemperatureRangesPopupComponent.TO_FIELD },
    { header: 'Color', columnType: ColumnTypeEnum.Color, dataField: KtxTemperatureRangesPopupComponent.COLOR_HEX_FIELD },
    { header: '', columnType: ColumnTypeEnum.Delete },
  ];

  constructor(
    private _formBuilder: FormBuilder,
    private _el: ElementRef,
    private _locationKtxDataSourceService: LocationsKtxDataSourceService) {
    super();
  }


  ngOnInit() {
    this._alarm = new AlarmViewModel();
    this._rangeToAdd = new TemperatureRangeViewModel();
    this.setAlarmValue(this._alarm);

    this._rangeForm = this._formBuilder.group({
      from: [0, Validators.required],
      to: [0, Validators.required],
      colorHex: ['', Validators.required]
    });

    this._alarmForm = this._formBuilder.group({
      highTemperature: [0, [Validators.required, Validators.min(1), Validators.max(250)]],
      highResistance: [0, [Validators.required, Validators.min(1), Validators.max(10000)]],
      maxRise: [0, [Validators.required, Validators.min(1), Validators.max(200)]],
      normalResistanceColor: ['', Validators.required],
      highTemperatureColor: ['', Validators.required],
      maxRiseColor: ['', Validators.required]
    });
  }

  ngAfterViewInit() {
    this.reInitScrollBar(0);
  }

  onClose() {
    this._gridData = [];
    this._rangeSubmitted = false;
    this._alarmSubmitted = false;
    this._isSubsequentError = false;
    this._uniqueRangeError = false;
    this._alarm = new AlarmViewModel();
    this._rangeToAdd = new TemperatureRangeViewModel();
    this.onClosePopup.emit();
  }

  show(location: LocationKtxViewModel) {
    this._isHidden = false;
    this._locationId = location.id;
    this._isLoading = true;

    this._locationKtxDataSourceService.getTemperatureRanges(location.id).then(() => {
      this._isLoading = false;

      this._gridData = location.temperatureRanges ? JSON.parse(JSON.stringify(location.temperatureRanges)) : [];
      this._alarm = JSON.parse(JSON.stringify(location.alarm));
      this.setAlarmValue(this._alarm);

      this._gridData.forEach(x => {
        x.from = ConvertDataFunctions.celsiusToFahrenheit(x.from);
        x.to = ConvertDataFunctions.celsiusToFahrenheit(x.to - KtxTemperatureRangesPopupComponent.CELSIUS_DELTA_VALUE);
      });
    })
  }

  onFocusRange() {
    if (this._uniqueRangeError) {
      this._uniqueRangeError = false;
    }
    if (this._isSubsequentError) {
      this._isSubsequentError = false;
    }
  }

  ngOnDestroy() {
    this.destroyMainScrollBar();
    this._subscriptions && this._subscriptions.forEach(x => x.unsubscribe());
    super.ngOnDestroy();
  }

  onClickDelete(range: TemperatureRangeViewModel) {
    this.onClickRangeDelete.emit(range);
  }

  removeRange(range: TemperatureRangeViewModel) {
    this._gridData.splice(this._gridData.indexOf(range), 1);
  }

  reInitScrollBar(timeout: number) {
    const el = this._el.nativeElement.querySelector('.ui-table-scrollable-view .ui-table-scrollable-body');
    this.reInitMainScrollBar(el, timeout);
  }

  onRangeSubmit() {
    this._rangeSubmitted = true;
    if (this._rangeForm.invalid) {
      return;
    }

    this._rangeToAdd.from = +this._rangeToAdd.from;
    this._rangeToAdd.to = +this._rangeToAdd.to;

    if (this._rangeToAdd.from >= this._rangeToAdd.to) {
      this._isSubsequentError = true;
      return;
    }

    if (this._gridData.some(x => x.from === this._rangeToAdd.from && x.to === this._rangeToAdd.to)) {
      this._uniqueRangeError = true;
      return;
    }

    if (this._gridData.some(x => (
      (this._rangeToAdd.from >= x.from && this._rangeToAdd.from <= x.to)
      || (this._rangeToAdd.to >= x.from && this._rangeToAdd.to <= x.to)
      || (x.from > this._rangeToAdd.from && x.to < this._rangeToAdd.to)))) {

      this._isSubsequentError = true;
      return;
    }

    this._rangeSubmitted = false;
    this._gridData.push({ ...this._rangeToAdd, id: 0 } as TemperatureRangeViewModel);
    let tempRange: Array<TemperatureRangeViewModel> = this._gridData.map(x => x);
    this._gridData = [];
    this._gridData = tempRange.sort((a, b) => a.from - b.from);
    this._rangeToAdd = new TemperatureRangeViewModel();
    this._rangeToAdd.colorHex = "#FF0000";
  }

  onSubmit() {
    this._alarmSubmitted = true;
    if (this._alarmForm.invalid) {
      return;
    }
    const editModel = {
      locationId: this._locationId,
      ranges: JSON.parse(JSON.stringify(this._gridData)),
      alarm: Object.create(this._alarm)
    } as TemperatureRangePostModel;
    this.validateEditModel(editModel);

    this._locationKtxDataSourceService.editTemperatureRange(editModel).then((result) => {
      if (result instanceof ErrorModel) {
        console.error(result);
      } else {
        this.onClose();
        this._isHidden = true;
      }
    })
  }
  private validateEditModel(model: TemperatureRangePostModel) {
    model.alarm.maxRiseValue = ConvertDataFunctions.deltaFahrenheitToDeltaCelsius(+model.alarm.maxRiseValue);
    model.alarm.highTemperatureValue = ConvertDataFunctions.fahrenheitToCelsius(+model.alarm.highTemperatureValue);
    model.alarm.highResistanceValue = +model.alarm.highResistanceValue;

    model.alarm.highResistanceColorHex = model.alarm.highResistanceColorHex === null ? '' : model.alarm.highResistanceColorHex;
    model.alarm.maxRiseColorHex = model.alarm.maxRiseColorHex === null ? '' : model.alarm.maxRiseColorHex;
    model.alarm.highTemperatureColorHex = model.alarm.highTemperatureColorHex === null ? '' : model.alarm.highTemperatureColorHex;
    model.alarm.normalResistanceColorHex = model.alarm.normalResistanceColorHex === null ? '' : model.alarm.normalResistanceColorHex;

    model.alarm.highResistanceColorHex = model.alarm.highTemperatureColorHex;
    model.ranges.forEach(x => {
      x.from = ConvertDataFunctions.fahrenheitToCelsius(x.from);
      x.to = ConvertDataFunctions.fahrenheitToCelsius(x.to);
    })
  }

  private setAlarmValue(alarm: AlarmViewModel) {
    this._alarm.highResistanceValue = this._alarm.highResistanceValue
      ? alarm.highResistanceValue
      : this._alarm.highResistanceValue;

    this._alarm.maxRiseValue = this._alarm.maxRiseValue
      ? ConvertDataFunctions.deltaCelsiusToDeltaFahrenheit(alarm.maxRiseValue)
      : this._alarm.maxRiseValue;

    this._alarm.highTemperatureValue = this._alarm.highTemperatureValue
      ? ConvertDataFunctions.celsiusToFahrenheit(alarm.highTemperatureValue)
      : this._alarm.highTemperatureValue;

    this._alarm.highTemperatureColorHex = (alarm.highTemperatureColorHex === '' || typeof alarm.highTemperatureColorHex === undefined || alarm.highTemperatureColorHex == null)
      ? "#FFFF00"
      : alarm.highTemperatureColorHex;

    this._alarm.normalResistanceColorHex = (alarm.normalResistanceColorHex === '' || typeof alarm.normalResistanceColorHex === undefined || alarm.normalResistanceColorHex == null)
      ? "#ffffff"
      : alarm.normalResistanceColorHex;

    this._alarm.maxRiseColorHex = (alarm.maxRiseColorHex === '' || typeof alarm.maxRiseColorHex == undefined || alarm.maxRiseColorHex == null)
      ? "#FF0000"
      : alarm.maxRiseColorHex;

    this._rangeToAdd.colorHex = "#FF0000";
  }
}
