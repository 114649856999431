import { UserRoleEnum } from '../../common';
import {
  NavigationItemsInfoInterface,
  RouteToRoleMapInterface,
  RoutesForRolesInterface,
} from '../models';
import { ProjectEnum } from '../enums';

export const MainRoutePaths = {
  LOCATIONS: 'locations',
  COMPANIES: 'companies',
  COMPANY_ADMINS: 'company-admins',
  COMPANY_USERS: 'company-users',
  SERVICE_USERS: 'service-users',
  BTX_MANAGEMENT: 'btx-management',
  BTX_LIVE_DATA: 'btx-live-data',
  BTX_LIVE_DATA_EQUIPMENT_VIEW: 'btx-live-data-equipment-view',
  BTX_HISTORICAL_DATA: 'btx-historical-data',
  BTX_NOTIFICATIONS: 'btx-notifications',
  KTX_MANAGEMENT: 'ktx-management',
  KTX_LOCATION_DETAILS: 'ktx-location-details',
  KTX_BIN_DETAILS: 'ktx-bin-details',
  KTX_LOCATION_VIEW: 'ktx-location-view',
  KTX_HISTORICAL_DATA: 'ktx-historical-data',
  KTX_NOTIFICATIONS: 'ktx-notifications',
  BINS: 'bins',
  ITX_MANAGEMENT: 'itx-management',
  ITX_VIEWS: 'itx-storages-view',
  ITX_STORAGES_MANAGEMENT: 'itx-storages-management',
  ITX_REPORTS: 'itx-reports',
  ITX_ALERT: 'itx-alerts',
  VENDORS: 'vendors',
  SALES_USERS: 'sales-users',
  AQS_ROUND_STORAGE: 'round-storage',
  AQS_FLAT_STORAGE: 'flat-storage',
  AQS_COMPUTATION_SHEET: 'computation_sheet',
  AQS_QUOTES: 'quotes',
  EMAIL_MANAGEMENT: 'email-management',
  FIRMWARE_MANAGEMENT: 'firmware-management',
};

export const NavigationItemsInfo: NavigationItemsInfoInterface = {
  [MainRoutePaths.LOCATIONS]: {
    label: 'Locations',
    icon: 'navigation-icon-location',
  },
  [MainRoutePaths.COMPANY_ADMINS]: {
    label: 'Company Admins',
    icon: 'navigation-icon-admin',
  },
  [MainRoutePaths.COMPANIES]: {
    label: 'Companies',
    icon: 'navigation-icon-company',
  },
  [MainRoutePaths.SALES_USERS]: {
    label: 'Sales Users',
    icon: 'navigation-icon-user',
  },
  [MainRoutePaths.BINS]: {
    label: 'Bins',
    icon: 'navigation-icon-bins',
  },
  [MainRoutePaths.COMPANY_USERS]: {
    label: 'Company Users',
    icon: 'navigation-icon-user',
  },
  [MainRoutePaths.SERVICE_USERS]: {
    label: 'Service Users',
    icon: 'navigation-icon-service',
  },
  [MainRoutePaths.VENDORS]: {
    label: 'Vendors',
    icon: 'navigation-icon-vendor',
  },
  [MainRoutePaths.AQS_ROUND_STORAGE]: {
    label: 'Quote Round Storage',
    icon: 'navigation-icon-round-storage',
  },
  [MainRoutePaths.AQS_FLAT_STORAGE]: {
    label: 'Quote Flat Storage',
    icon: 'navigation-icon-flat-storage',
  },
  [MainRoutePaths.AQS_COMPUTATION_SHEET]: {
    label: 'Computation Sheet',
    icon: 'navigation-icon-computation-sheet',
  },
  [MainRoutePaths.AQS_QUOTES]: {
    label: 'Saved Quotes',
    icon: 'navigation-icon-saved-quotes',
  },
  [MainRoutePaths.BTX_MANAGEMENT]: {
    label: 'BTX Management',
    icon: 'navigation-icon-btx-location',
    childrenRoutes: [
      MainRoutePaths.BTX_LIVE_DATA,
      MainRoutePaths.BTX_HISTORICAL_DATA,
      MainRoutePaths.BTX_NOTIFICATIONS,
    ]
  },
  [MainRoutePaths.BTX_LIVE_DATA]: {
    label: 'Live Data',
    icon: 'navigation-icon-itx-bins-location',
    dependentRoutes: [MainRoutePaths.BTX_LIVE_DATA_EQUIPMENT_VIEW],
  },
  [MainRoutePaths.BTX_HISTORICAL_DATA]: {
    label: 'Historical Data',
    icon: 'navigation-icon-historical',
  },
  [MainRoutePaths.BTX_NOTIFICATIONS]: {
    label: 'Notifications',
    icon: 'navigation-icon-alert',
  },
  [MainRoutePaths.KTX_MANAGEMENT]: {
    label: 'KTX Management',
    icon: 'navigation-icon-ktx-location',
    childrenRoutes: [
      MainRoutePaths.KTX_LOCATION_VIEW,
      MainRoutePaths.KTX_HISTORICAL_DATA,
      MainRoutePaths.KTX_NOTIFICATIONS,
    ],
  },
  [MainRoutePaths.KTX_LOCATION_VIEW]: {
    label: 'Current Data',
    icon: 'navigation-icon-itx-bins-location',
    dependentRoutes: [
      MainRoutePaths.KTX_LOCATION_DETAILS,
      MainRoutePaths.KTX_BIN_DETAILS,
    ],
  },
  [MainRoutePaths.KTX_HISTORICAL_DATA]: {
    label: 'Historical Data',
    icon: 'navigation-icon-historical',
  },
  [MainRoutePaths.KTX_NOTIFICATIONS]: {
    label: 'Notifications',
    icon: 'navigation-icon-alert',
  },
  [MainRoutePaths.ITX_MANAGEMENT]: {
    label: 'ITX Management',
    icon: 'navigation-icon-itx-location',
    childrenRoutes: [
      MainRoutePaths.ITX_VIEWS,
      MainRoutePaths.ITX_ALERT,
      MainRoutePaths.ITX_REPORTS,
    ],
    dependentRoutes: [MainRoutePaths.ITX_STORAGES_MANAGEMENT],
  },
  [MainRoutePaths.ITX_VIEWS]: {
    label: 'Live Data',
    icon: 'navigation-icon-itx-bins-location',
  },
  [MainRoutePaths.ITX_ALERT]: {
    label: 'Alerts',
    icon: 'navigation-icon-alert',
  },
  [MainRoutePaths.ITX_REPORTS]: {
    label: 'Reports',
    icon: 'navigation-icon-reports',
  },
  [MainRoutePaths.EMAIL_MANAGEMENT]: {
    label: 'E-mail Management',
    icon: 'navigation-icon-email',
  },
  [MainRoutePaths.FIRMWARE_MANAGEMENT]: {
    label: 'Firmware Management',
    icon: 'navigation-icon-firmware',
  }
};

export const RoutesForRoles: RoutesForRolesInterface = {
  [UserRoleEnum.CompanyAdmin]: [
    { route: MainRoutePaths.COMPANY_ADMINS },
    { route: MainRoutePaths.COMPANY_USERS },
    { route: MainRoutePaths.VENDORS, project: ProjectEnum.Itx },
    { route: MainRoutePaths.EMAIL_MANAGEMENT },
    { route: MainRoutePaths.LOCATIONS },
    {
      route: MainRoutePaths.BTX_MANAGEMENT,
      isDisabled: true,
      project: ProjectEnum.Btx,
    },
    {
      route: MainRoutePaths.KTX_MANAGEMENT,
      isDisabled: true,
      project: ProjectEnum.Ktx,
    },
    {
      route: MainRoutePaths.ITX_MANAGEMENT,
      isDisabled: true,
      project: ProjectEnum.Itx,
    },
  ],
  [UserRoleEnum.ServiceUser]: [
    { route: MainRoutePaths.FIRMWARE_MANAGEMENT },
    { route: MainRoutePaths.SERVICE_USERS },
    { route: MainRoutePaths.SALES_USERS },
    { route: MainRoutePaths.COMPANIES },
    { route: MainRoutePaths.COMPANY_ADMINS },
    { route: MainRoutePaths.LOCATIONS },
    { route: MainRoutePaths.BTX_MANAGEMENT },
    { route: MainRoutePaths.KTX_MANAGEMENT },
    { route: MainRoutePaths.ITX_MANAGEMENT },
  ],
  [UserRoleEnum.Vendor]: [
    { route: MainRoutePaths.ITX_VIEWS },
    { route: MainRoutePaths.ITX_REPORTS },
  ],
  [UserRoleEnum.CompanyUser]: [
    {
      route: MainRoutePaths.BTX_MANAGEMENT,
      isDisabled: true,
      project: ProjectEnum.Btx,
    },
    {
      route: MainRoutePaths.KTX_MANAGEMENT,
      isDisabled: true,
      project: ProjectEnum.Ktx,
    },
    {
      route: MainRoutePaths.ITX_MANAGEMENT,
      isDisabled: true,
      project: ProjectEnum.Itx,
    },
  ],
  [UserRoleEnum.SalesUser]: [
    { route: MainRoutePaths.AQS_QUOTES },
    { route: MainRoutePaths.AQS_ROUND_STORAGE },
    { route: MainRoutePaths.AQS_FLAT_STORAGE },
    { route: MainRoutePaths.AQS_COMPUTATION_SHEET },
  ],
};

export const BACK_DOOR = '/back-door';

export const BackDoorRouteToRoleMap: RouteToRoleMapInterface = {
  '/back-door/admin': UserRoleEnum.CompanyAdmin,
  '/back-door/company-user': UserRoleEnum.CompanyUser,
  '/back-door/vendor': UserRoleEnum.Vendor,
  '/back-door/aqs': UserRoleEnum.SalesUser,
};
