import { Component, OnInit, Input } from '@angular/core';

import { TemperatureColorViewModel } from '@models';
import { SensorAlarmLabels } from 'src/app/main/constants';

@Component({
  selector: 'greensleeves-ktx-temperature-ranges',
  templateUrl: './ktx-temperature-ranges.component.html',
})
export class KtxTemperatureRangesComponent implements OnInit {
  @Input()
  temperatureRanges: TemperatureColorViewModel[];
  constructor() { }

  ngOnInit() { }

  getRange(value: string): string {
    switch (value) {
      case SensorAlarmLabels.HighLimit:
      case SensorAlarmLabels.MaxRise:
      case SensorAlarmLabels.NoResponse:
      case SensorAlarmLabels.OpenTc:
      case SensorAlarmLabels.Unknown:
        return '-';
      default: return '<';
    }
  }
}
