import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { TreeNode } from 'primeng';

import {
  KtxCurrentDataExportReportPopupComponent,
  KtxUnitFullscreenPopupComponent,
} from '@popups';
import { HubService, LocationsKtxDataSourceService } from '@services';
import { ConvertDataFunctions, ErrorModel } from '../../../../common';

import {
  AlarmViewModel,
  BinSchemeViewModel,
  CableSchemeViewModel,
  LocationKtxSchemeViewModel,
  LocationKtxTemperatureRangeUpdatedHubMessage,
  SensorKtxSchemeViewModel,
  TelemetrySnapshotViewModel,
  TemperatureRangeViewModel,
  TemperatureColorViewModel,
} from '@models';
import { MainRoutePaths, SensorAlarmLabels } from '../../../constants';
import {
  BinTypeEnum,
  ColumnTypeEnum,
  MeasureEnum,
  SensorStatusEnum,
} from '../../../enums';

@Component({
  selector: 'greensleeves-ktx-bin-details-tab',
  templateUrl: './ktx-bin-details-tab.component.html',
})
export class KtxBinDetailsTabComponent implements OnInit, OnDestroy, AfterViewInit {
  static WINDOW_FUNCTION_GET_SCHEME_NAME = 'getScheme';
  static WINDOW_FUNCTION_GET_TELEMETRY_NAME = 'getTelemetry';
  static WINDOW_FIELD_IS_WEB_NAME = 'isWeb';
  static WINDOW_FUNCTION_DISPLAY_SCHEME_NAME = 'displayScheme';
  static NAME_FIELD = 'name';
  static MUX_OR_DEVICE_FIELD = 'multiplexerId';
  static SENSORS_FIELD = 'sensors';

  @ViewChild('model2d', { static: false }) iframe2d: ElementRef;
  @ViewChild('model3d', { static: false }) iframe3d: ElementRef;

  @ViewChild('fullScreenContainer', { static: false })
  fullScreenContainer: ElementRef;

  @ViewChild(KtxUnitFullscreenPopupComponent, { static: false })
  ktxUnitFullscreen: KtxUnitFullscreenPopupComponent;

  @ViewChild(KtxCurrentDataExportReportPopupComponent, {
    read: false,
    static: false,
  })
  exportReportModal: KtxCurrentDataExportReportPopupComponent;

  _locationScheme: LocationKtxSchemeViewModel;
  _isSchemeHidden = true;
  _isSchemeLoading = true;
  _isShowScheme: boolean = false;
  _isLoading: boolean;
  _gridData: CableSchemeViewModel[] = [];
  _resistanceGridData: CableSchemeViewModel[] = [];
  _is2dDisplayMode = true;
  _currentBin: BinSchemeViewModel;
  _columnType = ColumnTypeEnum;
  _binTypeEnum = BinTypeEnum;
  onViewCableDetailsClick = new EventEmitter<BinSchemeViewModel>();
  _columnsView = [];
  _sortField = 'name';
  temperatureTelemetry: TelemetrySnapshotViewModel;
  resistanceTelemetry: TelemetrySnapshotViewModel;
  _binId: number;
  _locationBinsNodes: TreeNode[] = [];
  _selectedBinNode: TreeNode;
  _isOpenBinsList = false;
  temperatureRanges: TemperatureRangeViewModel[];

  _frozenColumns = [
    {
      header: 'Cable',
      columnType: ColumnTypeEnum.CableName,
      dataField: KtxBinDetailsTabComponent.NAME_FIELD,
      width: 60,
    },
    {
      header: 'Device',
      columnType: ColumnTypeEnum.MUXOrDevice,
      dataField: KtxBinDetailsTabComponent.MUX_OR_DEVICE_FIELD,
      width: 60,
    },
  ];

  _lastUpdatingStatus: string;
  private subscriptions: Subscription[] = [];
  private schemeData$ = new BehaviorSubject<{
    binScheme: BinSchemeViewModel;
    alarm: AlarmViewModel;
    temperatureRanges: TemperatureRangeViewModel[];
  }>(null);
  private telemetry$ = new BehaviorSubject<TelemetrySnapshotViewModel>(null);
  private displayScheme$ = new BehaviorSubject<boolean>(false);
  private isFahrenheitMeasureMode: boolean;
  private isTemperatureMeasureMode: boolean = true;
  private receivingTimestamp: string;

  get _isFahrenheitMeasureMode() {
    return this.isFahrenheitMeasureMode;
  }

  set _isFahrenheitMeasureMode(value: boolean) {
    this.isFahrenheitMeasureMode = value;
  }

  get _isTemperatureMeasureMode() {
    return this.isTemperatureMeasureMode;
  }

  set _isTemperatureMeasureMode(value: boolean) {
    this.isTemperatureMeasureMode = value;

    if (this._currentBin.displaySchemes) {
      if (value) {
        this.telemetry$.next(this.temperatureTelemetry);
      } else {
        this.telemetry$.next(this.resistanceTelemetry);
      }
    }
  }
  get _frozenWidth() {
    return `${this._frozenColumns.reduce((a, v) => a + v.width, 0)}px`;
  }

  get _locationName(): string {
    return this._locationScheme ? this._locationScheme.locationName : '';
  }

  get _selectedBinName(): string {
    if (!this._selectedBinNode) {
      return '';
    }

    const { label, parent } = this._selectedBinNode;

    return parent ? `${parent.label}: ${label}` : label;
  }

  get _temperatureRanges(): TemperatureColorViewModel[] {
    if (!this.temperatureRanges) {
      return [];
    }

    const temperatures: TemperatureColorViewModel[] = this
      ._isFahrenheitMeasureMode
      ? this.temperatureRanges.map<TemperatureColorViewModel>((tr) => ({
        value: this.onCelsiusToFahrenheit(tr.to),
        colorHex: tr.colorHex,
      }))
      : this.temperatureRanges.map<TemperatureColorViewModel>((tr) => ({
        value: Math.round(tr.to).toString(),
        colorHex: tr.colorHex,
      }));

    const {
      openTcColorHex,
      noResponseColorHex,
      maxRiseColorHex,
      highResistanceColorHex,
      highTemperatureColorHex,
    } = this._currentBin.alarm;

    const hlValue = this._isTemperatureMeasureMode
      ? highTemperatureColorHex
      : highResistanceColorHex;

    return [
      ...temperatures,
      {
        value: SensorAlarmLabels.OpenTc,
        colorHex: openTcColorHex,
      },
      {
        value: SensorAlarmLabels.NoResponse,
        colorHex: noResponseColorHex,
      },
      {
        value: SensorAlarmLabels.MaxRise,
        colorHex: maxRiseColorHex,
      },
      {
        value: SensorAlarmLabels.HighLimit,
        colorHex: hlValue,
      },
    ];
  }

  get _binHasUnit(): boolean {
    return this._selectedBinNode && !!this._selectedBinNode.parent;
  }

  constructor(
    private _ktxLocationService: LocationsKtxDataSourceService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _el: ElementRef,
    private _hubService: HubService,
    private _cdr: ChangeDetectorRef,
    private _zone: NgZone
  ) { }

  ngOnInit() {
    this._currentBin = new BinSchemeViewModel();
    this.temperatureTelemetry = new TelemetrySnapshotViewModel();
    this.resistanceTelemetry = new TelemetrySnapshotViewModel();

    window[KtxBinDetailsTabComponent.WINDOW_FUNCTION_GET_SCHEME_NAME] = () => {
      return this.schemeData$;
    };
    window[KtxBinDetailsTabComponent.WINDOW_FUNCTION_GET_TELEMETRY_NAME] =
      () => {
        return this.telemetry$;
      };
    window[KtxBinDetailsTabComponent.WINDOW_FIELD_IS_WEB_NAME] = true;
    window[KtxBinDetailsTabComponent.WINDOW_FUNCTION_DISPLAY_SCHEME_NAME] =
      () => {
        return this.displayScheme$;
      };

    this.setSubscriptions();
  }

  ngAfterViewInit(): void {
    const detailsSubscription = this.ktxUnitFullscreen.onDetailsClick.subscribe(
      (bin: BinSchemeViewModel) => {
        this._zone.run(() => {
          this._router.navigate([`../${bin.id}`], {
            relativeTo: this._route,
          });
        });
      }
    );

    this.subscriptions.push(detailsSubscription);
    this._el.nativeElement.querySelector('.ui-table-scrollable-view.ui-table-unfrozen-view .ui-table-scrollable-body').classList.add('is-scrollable-y', 'is-scrollable-x');
  }

  ngOnDestroy() {
    this.clearSubscriptions();
    this._ktxLocationService.companiesWithLocations$.next([]);
  }

  onClickGoBackLink(): void {
    this._router.navigate([`../../${MainRoutePaths.KTX_LOCATION_VIEW}`], {
      relativeTo: this._route,
    });
  }

  setSubscriptions(): void {
    const locationSchemeSubscription =
      this._ktxLocationService.locationScheme$.subscribe((locationScheme) => {
        this._locationScheme = locationScheme;
      });

    const telemetrySubscription = this._ktxLocationService.telemetry$.subscribe(
      (telemetry) => {
        if (telemetry) {
          if (this._currentBin.displaySchemes) {
            this.telemetry$.next(telemetry);
          }

          if (this.isTemperatureMeasureMode) {
            this.temperatureTelemetry = telemetry;
            this.mapTelemetryToGridData(this._gridData, telemetry);
          } else {
            this.resistanceTelemetry = telemetry;
            this.mapTelemetryToGridData(this._resistanceGridData, telemetry);
          }
        }
      }
    );

    const hubSubscription = this._hubService.temperatureRangeUpdated.subscribe(
      (message: LocationKtxTemperatureRangeUpdatedHubMessage) => {
        if (message.updatedRanges.locationId === this._currentBin.locationId) {
          this._currentBin.temperatureRanges = message.updatedRanges.ranges;
          this._currentBin.alarm = message.updatedRanges.alarm;

          this.schemeData$.next({
            binScheme: this._currentBin,
            alarm: this._currentBin.alarm,
            temperatureRanges: this._currentBin.temperatureRanges,
          });
        }
      }
    );

    const lastTelemetrySubscription =
      this._ktxLocationService.lastTelemetry$.subscribe(
        (telemetry: TelemetrySnapshotViewModel) => {
          if (telemetry) {
            const highLimitValue: number =
              this._currentBin.alarm &&
              this._currentBin.alarm.highTemperatureValue;

            if (highLimitValue && telemetry.snapshot) {
              telemetry.snapshot.forEach((x) => {
                if (x.sensors) {
                  x.sensors.forEach((y) => {
                    y.status =
                      y.value >= highLimitValue
                        ? SensorStatusEnum.HighLimit
                        : y.status;
                  });
                }
              });
            }
            this.mapTelemetryToGridData(this._gridData, telemetry);

            if (this._currentBin.displaySchemes) {
              this.telemetry$.next(telemetry);
            }
          }
        }
      );

    const binIdSubscription = this._route.paramMap.subscribe((params) => {
      if (!this._locationScheme) {
        this.onClickGoBackLink();
      }

      const binId = Number(params.get('id'));

      this._binId = binId;
      this.initLocationBinsNodes();
      this.setBinNodeById(binId);
      this.loadBinById(binId);
    });

    this.subscriptions.push(
      locationSchemeSubscription,
      telemetrySubscription,
      hubSubscription,
      lastTelemetrySubscription,
      binIdSubscription
    );
  }

  clearSubscriptions(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.subscriptions = null;
  }

  onIframeLoad() {
    this._isSchemeLoading = false;
  }

  splitStringBySpace(input: string): string[] {
    return input.split(' ');
  }

  onFullScreenClose() {
    this._isSchemeHidden = true;
    const modelHolder = this._el.nativeElement.querySelector('.popup-iframe');

    if (this._is2dDisplayMode) {
      modelHolder.appendChild(this.iframe2d.nativeElement);
      this.iframe2d.nativeElement.setAttribute('height', '300');
      this.iframe2d.nativeElement.setAttribute('width', '300');
    } else {
      this.iframe3d.nativeElement.setAttribute('height', '300');
      this.iframe3d.nativeElement.setAttribute('width', '300');
      modelHolder.appendChild(this.iframe3d.nativeElement);
    }
  }

  public showFullScreen() {
    this._isSchemeHidden = false;
    this._isSchemeLoading = true;
    if (this._is2dDisplayMode) {
      this.fullScreenContainer.nativeElement.appendChild(
        this.iframe2d.nativeElement
      );
      this.iframe2d.nativeElement.setAttribute('height', '600');
      this.iframe2d.nativeElement.setAttribute('width', '600');
    } else {
      this.fullScreenContainer.nativeElement.appendChild(
        this.iframe3d.nativeElement
      );
      this.iframe3d.nativeElement.setAttribute('height', '600');
      this.iframe3d.nativeElement.setAttribute('width', '600');
    }
  }

  public async show(bin: BinSchemeViewModel) {
    this._currentBin = bin;
    this._isShowScheme = bin.displaySchemes;
    this.isFahrenheitMeasureMode = true;

    if (bin.cables) {
      this._resistanceGridData = JSON.parse(JSON.stringify(bin.cables));
      this._gridData = JSON.parse(JSON.stringify(bin.cables));

      const maxTcCount = Math.max(
        ...bin.cables.map((o) => o.thermocoupleCount)
      );

      this._columnsView = Array.from({ length: maxTcCount }, (_, index) => ({
        header: 'TC ' + (index + 1),
        columnType: ColumnTypeEnum.TC,
        dataField: KtxBinDetailsTabComponent.SENSORS_FIELD,
      }));
    }

    this._isLoading = true;
    const listOfCableIds = bin.cables
      ? bin.cables.map((x) => {
        return x.id;
      })
      : [];

    await this._ktxLocationService.getTelemetry(bin.id, listOfCableIds);

    const binScheme = {
      binScheme: this._currentBin,
      alarm: this._currentBin.alarm,
      temperatureRanges: bin.temperatureRanges,
    };
    this.schemeData$.next(binScheme);

    if (bin.displaySchemes) {
      this.displayScheme$.next(bin.displaySchemes);
    }


    this._cdr.detectChanges();
  }

  loadBinById(binId: number) {
    const binScheme = this.findBinById(binId);

    if (!binScheme) {
      return;
    }

    const { locationId } = this._locationScheme;

    const location = this._ktxLocationService.locationsKtx$
      .getValue()
      .find((l) => l.id === locationId);

    if (location.temperatureRanges && location.alarm) {
      binScheme.temperatureRanges = location.temperatureRanges;
      binScheme.alarm = location.alarm;
      this.temperatureRanges = location.temperatureRanges;
    } else {
      this._ktxLocationService.getTemperatureRanges(locationId).then(() => {
        binScheme.temperatureRanges = location.temperatureRanges;
        binScheme.alarm = location.alarm;
        this.temperatureRanges = location.temperatureRanges;
      });
    }

    binScheme.locationId = locationId;
    binScheme.displaySchemes = this._locationScheme.displayScheme;
    binScheme.wasDeviceInitialized = this._locationScheme.wasDeviceInitialized;

    this.show(binScheme);
  }

  findBinById(binId: number): BinSchemeViewModel {
    if (!this._locationScheme || !this._locationScheme.binSchemes) {
      return null;
    }

    const bins: BinSchemeViewModel[] = this._locationScheme.binSchemes.reduce(
      (result, bin) =>
        !bin.bins || bin.bins.length === 0
          ? [...result, bin]
          : result.concat(bin.bins),
      []
    );

    const bin = bins.find((b) => b.id === binId);

    return bin;
  }

  findUnitByBinId(binId: number): BinSchemeViewModel {
    const unit = this._locationScheme.binSchemes
      .filter((b) => b.bins && b.bins.length > 0)
      .find((u) => u.bins.map((b) => b.id).includes(binId));

    return unit;
  }

  onClickViewUnit() {
    const unit: BinSchemeViewModel = this.findUnitByBinId(this._currentBin.id);

    if (!unit) {
      return;
    }

    this.ktxUnitFullscreen.show(unit);
  }

  async onClickReadSiloData() {
    this.receivingTimestamp = null;
    this._lastUpdatingStatus = '';
    this._isLoading = true;
    this._ktxLocationService
      .ReadSiloData(
        this._currentBin.id,
        this.isTemperatureMeasureMode
          ? MeasureEnum.Temperature
          : MeasureEnum.Resistance
      )
      .then((result) => {
        if (result instanceof ErrorModel) {
          this._lastUpdatingStatus = result.message;
        }
        this._isLoading = false;
      });
  }

  _getSensorColor(cable: CableSchemeViewModel, sensorIndex: number): string {
    const sensor =
      cable && cable.sensors
        ? (cable.sensors[sensorIndex] as SensorKtxSchemeViewModel)
        : null;

    if (!sensor) {
      return '-';
    }

    if (sensor.status === SensorStatusEnum.OpenTC) {
      return this._currentBin.alarm.openTcColorHex;
    }

    if (sensor.status === SensorStatusEnum.NoResponse) {
      return this._currentBin.alarm.noResponseColorHex;
    }

    if (this.isTemperatureMeasureMode) {
      if (sensor.status === SensorStatusEnum.HighLimit) {
        return this._currentBin.alarm.highTemperatureColorHex;
      }

      if (sensor.status === SensorStatusEnum.MaxRise) {
        return this._currentBin.alarm
          ? this._currentBin.alarm.maxRiseColorHex
          : '';
      }

      if (this._currentBin.temperatureRanges) {
        const temperatureRange = this._currentBin.temperatureRanges.find(
          (x) => sensor.value >= x.from && sensor.value <= x.to
        );

        return !temperatureRange ? '' : temperatureRange.colorHex;
      }

      return '-';
    } else {
      if (sensor.status === SensorStatusEnum.HighLimit) {
        return this._currentBin.alarm.highResistanceColorHex;
      }

      return this._currentBin.alarm.normalResistanceColorHex;
    }
  }

  _getSensorValue(
    cable: CableSchemeViewModel,
    sensorIndex: number
  ): string | number {
    const sensor =
      cable && cable.sensors
        ? (cable.sensors[sensorIndex] as SensorKtxSchemeViewModel)
        : null;

    if (!sensor) {
      return '';
    }

    if (sensor.status === SensorStatusEnum.OpenTC) {
      return SensorAlarmLabels.OpenTc;
    }

    if (sensor.status === SensorStatusEnum.NoResponse) {
      return '-';
    }

    if (this.isFahrenheitMeasureMode && this.isTemperatureMeasureMode) {
      const FahrenheitValue = this.onCelsiusToFahrenheit(sensor.value);
      return FahrenheitValue;
    }

    return sensor.value;
  }

  onCelsiusToFahrenheit(value: number): string {
    if (value || value === 0) {
      const convertedValue = ConvertDataFunctions.celsiusToFahrenheit(value);
      return convertedValue.toString();
    } else {
      return '-';
    }
  }

  getBackGroundTextColor(
    cable: CableSchemeViewModel,
    sensorIndex: number
  ): string {
    const sensor = cable
      ? (cable[sensorIndex] as SensorKtxSchemeViewModel)
      : null;

    if (sensor) {
      if (sensor.status === SensorStatusEnum.OpenTC) {
        return '#FFFFFF';
      }
    }

    return '';
  }

  private mapTelemetryToGridData(
    gridData: CableSchemeViewModel[],
    telemetry: TelemetrySnapshotViewModel
  ) {
    if (gridData) {
      gridData.map((cable) => {
        if (telemetry.snapshot) {
          if (telemetry.recievingTimestamp) {
            const date = new Date(telemetry.recievingTimestamp * 1000);
            this.receivingTimestamp = date.toLocaleString('en-US');
          }

          telemetry.snapshot.map((snap) => {
            if (cable.id === snap.cableId) {
              cable.sensors = snap.sensors;
            }
          });
        }
      });
    }
    this._isLoading = false;
    this.sertastUpdatingStatus();
  }

  reInitScrollBarTable(timeout?: number, withCheck = false) {
    const el = this._el.nativeElement.querySelector('.ui-table-scrollable-view.ui-table-unfrozen-view .ui-table-scrollable-body');
    if (el) {
    }
  }

  initLocationBinsNodes() {
    this._locationBinsNodes = LocationKtxSchemeViewModel.toBinsTreeNode(
      this._locationScheme
    );
  }

  onSelectBinNode(event: any) {
    const { node } = event;

    if (node) {
      this.loadBinById(node.data);
      this._isOpenBinsList = false;
    }
  }

  setBinNodeById(binId: number) {
    if (!this._locationBinsNodes) {
      return;
    }

    const bins: TreeNode[] = this._locationBinsNodes.reduce(
      (result, bin) =>
        !bin.children || bin.children.length === 0
          ? [...result, bin]
          : result.concat(bin.children),
      []
    );

    const binNode = bins.find((l) => +l.data === binId);

    if (!binNode) {
      return;
    }

    this._selectedBinNode = binNode;
  }

  toggleBinsList(): void {
    this._isOpenBinsList = !this._isOpenBinsList;
  }

  onClickExportReport() {
    this.exportReportModal.show(this._locationScheme, this._isFahrenheitMeasureMode ? 1 : 0);
  }

  private sertastUpdatingStatus() {
    let statusText = 'Last updating: ';
    if (this._isLoading) {
      this._lastUpdatingStatus = statusText + 'Receiving data';
    } else if (this.receivingTimestamp && !this._isLoading) {
      this._lastUpdatingStatus = statusText + this.receivingTimestamp;
    } else {
      this._lastUpdatingStatus = '';
    }
  }
}
