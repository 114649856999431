import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Router } from '@angular/router';

import { ErrorModel } from '../../../common';
import {
  LocationKtxDeletedHubMessage,
  LocationKtxInUpdateHubMessage,
  LocationKtxDeviceInInitializationHubMessage,
  LocationKtxDeviceInitializedHubMessage,
  LocationKtxBinsInUpdateHubMessage,
  LocationKtxBinsUpdatedHubMessage,
  LocationKtxAddedHubMessage,
  LocationKtxUpdatedHubMessage,
  TemperatureRangePostModel,
  LocationKtxSchemeViewModel,
  LocationKtxViewModel,
  LocationKtxPostModel,
  TelemetrySnapshotViewModel,
  AddEditLocationKtxSchemePostModel,
  AddEditLocationKtxSchemeResultModel,
  LocationKtxTemperatureRangeUpdatedHubMessage,
  TemperatureRangeAlarmViewModel,
  TemperatureRangeGetModel,
  AddEditLocationKtxSchemeViewModel,
  AddEditLocationKtxSchemeGetModel,
  BinConnectionViewModel,
  EditBinConnectionPostModel,
  CompanyViewModel,
  BinSchemeViewModel,
  TelemetryBinsGetModel,
  HistoricalBinsSnapshots,
} from "../../models";
import { HubService, LocationsKtxApiService } from "../http";
import { HubMethods } from "../../constants";
import { BinTypeEnum, MeasureEnum, SwitchTypeEnum } from "../../enums";

@Injectable()
export class LocationsKtxDataSourceService {
  public locationsKtx$: BehaviorSubject<LocationKtxViewModel[]> = new BehaviorSubject([]);
  public companiesWithLocations$: BehaviorSubject<CompanyViewModel[]> = new BehaviorSubject([]);
  public errors$: BehaviorSubject<ErrorModel> = new BehaviorSubject(null);
  public telemetry$: BehaviorSubject<TelemetrySnapshotViewModel> = new BehaviorSubject(null);
  public lastTelemetry$: BehaviorSubject<TelemetrySnapshotViewModel> = new BehaviorSubject(null);
  public readSiloDataTelemetry$: BehaviorSubject<TelemetrySnapshotViewModel> = new BehaviorSubject(null);
  public temperatureRanges$: BehaviorSubject<TemperatureRangeAlarmViewModel> = new BehaviorSubject(null);
  public binsConnections$: BehaviorSubject<BinConnectionViewModel[]> = new BehaviorSubject(null);
  public locationScheme$: BehaviorSubject<LocationKtxSchemeViewModel> = new BehaviorSubject(null);

  constructor(
    private _locationsKtxApi: LocationsKtxApiService,
    private _hubService: HubService,
    private _router: Router,
  ) {
    this.subscribeToHubService();
  }

  public async get() {
    try {
      const cacheData = this.locationsKtx$.getValue();
      if (!cacheData || cacheData.length == 0) {
        const data = await this._locationsKtxApi.getAll();
        this.locationsKtx$.next(data as LocationKtxViewModel[]);
        this.errors$.next(null);
      } else {
        this.locationsKtx$.next(cacheData as LocationKtxViewModel[]);
      }
    } catch (error) {
      this.errors$.next(error);
    }
  }

  public async getScheme(locationId: number, isLocationView: boolean = false): Promise<LocationKtxSchemeViewModel> {
    try {
      const schema = this.locationScheme$.getValue();
      if (!schema || schema.locationId != locationId || (!schema.forLocationView && isLocationView == true)) {
        const data = await this._locationsKtxApi.getScheme(locationId, isLocationView) as LocationKtxSchemeViewModel;

        if (data) {
          this.locationScheme$.next(data);
          this.errors$.next(null);

          return data;
        }
      } else {
        this.locationScheme$.next(schema);
        return schema;
      }

      return null;
    } catch (error) {
      this.errors$.next(error);
    }
  }

  public async getTemperatureRangesForLocation(locationId: number): Promise<TemperatureRangeAlarmViewModel | ErrorModel> {
    try {
      const result = await this._locationsKtxApi.getTemperatureRanges(locationId) as TemperatureRangeAlarmViewModel;
      return result;
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      } else {
        this.errors$.next(error);
      }
    }
  }

  public async getHistoricalBinsSnapshotForThePeriod(binIds: number[], timestampFrom: number, timestampTo: number): Promise<HistoricalBinsSnapshots | ErrorModel> {
    try {
      const result = await this._locationsKtxApi.getHistoricalBinsSnapshotForThePeriod(binIds, timestampFrom, timestampTo) as HistoricalBinsSnapshots;
      return result;
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      } else {
        this.errors$.next(error);
      }
    }
  }

  public async getBinsTelemetry(getModel: TelemetryBinsGetModel[]): Promise<TelemetrySnapshotViewModel[] | ErrorModel> {
    try {
      const result = await this._locationsKtxApi.getBinsTelemetry(getModel) as TelemetrySnapshotViewModel[];
      return result;
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      } else {
        this.errors$.next(error);
      }
    }
  }

  public async getTelemetry(binId: number, cablesIds: number[]) {
    try {
      const result = await this._locationsKtxApi.getTelemetry(binId, cablesIds) as TelemetrySnapshotViewModel;

      if (result) {
        this.lastTelemetry$.next(result);
      }
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      } else {
        this.errors$.next(error);
      }
    }
  }

  public async getBinSchema(locationId: number, binId: number): Promise<BinSchemeViewModel | ErrorModel> {
    try {
      const result = await this._locationsKtxApi.getBinSchema(locationId, binId) as BinSchemeViewModel;
      return result;
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      } else {
        this.errors$.next(error);
      }
    }
  }

  public async ReadSiloData(binId: number, measure: MeasureEnum) {
    try {
      const result = await this._locationsKtxApi.readSiloData(binId, measure) as TelemetrySnapshotViewModel;

      if (result) {
        this.telemetry$.next(result);
      }
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      } else {
        this.errors$.next(error);
      }
    }
  }

  public async getTemperatureRanges(locationId: number) {
    try {
      const result = await this._locationsKtxApi.getTemperatureRanges(locationId) as TemperatureRangeAlarmViewModel;

      if (result) {
        const location = this.locationsKtx$.getValue().find(x => x.id === result.locationId);
        location.temperatureRanges = result.temperatureRanges;
        location.alarm = result.alarm;
        location.alarm.highResistanceColorHex = location.alarm.highTemperatureColorHex;
      }
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      } else {
        this.errors$.next(error);
      }
    }
  }

  public async add(location: LocationKtxViewModel): Promise<LocationKtxViewModel | ErrorModel> {
    try {
      const locationPostModel = new LocationKtxPostModel(location);
      const result = (await this._locationsKtxApi.add(
        locationPostModel
      )) as LocationKtxViewModel;
      if (result) {
        this._hubService.sendToAllServiceUsers(
          HubMethods.LocationKtx.locationKtxAdded,
          new LocationKtxAddedHubMessage(result)
        );
        this.locationsKtx$.next([...this.locationsKtx$.getValue(), result]);
        return Promise.resolve(result);
      }
      return null;
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      } else {
        this.errors$.next(error);
      }
    }
  }

  public async edit(
    location: LocationKtxViewModel
  ): Promise<LocationKtxViewModel | ErrorModel> {
    try {
      const locationPutModel = new LocationKtxPostModel(location);
      const result = (await this._locationsKtxApi.edit(
        locationPutModel
      )) as LocationKtxViewModel;
      if (result) {
        this._hubService.sendToAllServiceUsers(
          HubMethods.LocationKtx.locationKtxUpdated,
          new LocationKtxUpdatedHubMessage(result, true)
        );
        this.locationsKtx$.next(
          this.locationsKtx$
            .getValue()
            .map((l) => (l.id === result.id ? result : l))
        );
        return Promise.resolve(result);
      }
      return null;
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      } else {
        this.errors$.next(error);
      }
    }
  }

  public async editTemperatureRange(editTemperaturemodel: TemperatureRangePostModel): Promise<TemperatureRangeGetModel | ErrorModel> {
    try {
      const result = await this._locationsKtxApi.editTemperatureRange(editTemperaturemodel) as TemperatureRangeGetModel
      if (result) {
        const location = this.locationsKtx$.getValue().find(l => l.id == result.locationId);
        if (location) {
          location.isInitialized = result.isInitialized;
          location.canDeviceBeInitialized = result.canDeviceBeInitialized;
          location.temperatureRanges = result.temperatureRanges;
          location.alarm = result.alarm;
        }
        const schema = this.locationScheme$.getValue();
        if (schema && schema.locationId == editTemperaturemodel.locationId && schema.forLocationView) {

          schema.alarm = result.alarm;
          schema.temperatureRanges = result.temperatureRanges;

          this.locationScheme$.next(schema);
        }
        await this._hubService.sendToAll(HubMethods.LocationKtx.locationKtxTemperatureRangeUpdated, new LocationKtxTemperatureRangeUpdatedHubMessage(editTemperaturemodel));
      }

      return result;
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      } else {
        this.errors$.next(error);
      }
    }
  }

  public async initializeDeviceConfiguration(
    locationId: number
  ): Promise<boolean | ErrorModel> {
    try {
      await this._hubService.sendToAllServiceUsers(
        HubMethods.LocationKtx.locationKtxDeviceInInitialization,
        new LocationKtxDeviceInInitializationHubMessage(locationId)
      );
      const result = await this._locationsKtxApi.initializeDeviceConfiguration(locationId);
      if (result) {
        this._hubService.sendToAllServiceUsers(
          HubMethods.LocationKtx.locationKtxDeviceInitialized,
          new LocationKtxDeviceInitializedHubMessage(locationId)
        );

        const location = this.locationsKtx$.getValue().find(x => x.id === locationId);
        if (location) {
          location.isInitialized = true;
        }

        return Promise.resolve(true);
      }

      return false;
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      } else {
        this.errors$.next(error);
      }
    }
  }

  public async downloadConnectionFile(
    locationId: number
  ): Promise<Blob | ErrorModel> {
    try {
      const result = await this._locationsKtxApi.downloadConnectionFile(
        locationId
      );

      return Promise.resolve(
        new Blob([result as BinaryType], { type: "application/zip" })
      );
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      } else {
        this.errors$.next(error);
      }
    }
  }

  public async delete(locationId: number): Promise<boolean | ErrorModel> {
    try {
      const result = await this._locationsKtxApi.delete(locationId);

      if (result) {
        this._hubService.sendToAllServiceUsers(
          HubMethods.LocationKtx.locationKtxDeleted,
          new LocationKtxDeletedHubMessage(locationId)
        );
        const locations = this.locationsKtx$
          .getValue()
          .filter((l) => l.id !== locationId);
        this.locationsKtx$.next(locations);
        return Promise.resolve(true);
      }
      return false;
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      } else {
        this.errors$.next(error);
      }
    }
  }

  public async uploadConfigurationFile(file: File, locationId: number): Promise<AddEditLocationKtxSchemeViewModel | ErrorModel> {
    if (this._router.url.indexOf('/back-door') > -1) {
      return this.getFakeAddEditLocationKtxSchemeViewModel();
    }

    try {
      const uploadForm = new FormData();
      uploadForm.append('configurationFile', file, file.name);
      uploadForm.append('locationId', locationId.toString());

      const result = await this._locationsKtxApi.uploadConfigurationFile(uploadForm) as AddEditLocationKtxSchemeGetModel;
      if (result) {
        return AddEditLocationKtxSchemeGetModel.toViewModel(result);
      }
      return null;
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      } else {
        this.errors$.next(error);
      }
    }
  }

  public async updateLocationScheme(requestModel: AddEditLocationKtxSchemePostModel) {
    try {
      const result = await this._locationsKtxApi.addEditLocationScheme(requestModel) as AddEditLocationKtxSchemeResultModel;
      if (result) {
        const location = this.locationsKtx$
          .getValue()
          .find((l) => l.id === requestModel.locationId);
        if (location) {
          location.isInitialized = !result.isNeedToInitializeDevice;
          location.canDeviceBeInitialized = result.canDeviceBeInitialized;
          location.locationName = result.locationName;
          location.displaySchemes = result.displayScheme;
          location.countOfBins = result.countOfBins;
        }
        return result;
      }
      return null;
    } catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      } else {
        this.errors$.next(error);
      }
    }
  }

  public async markLocationKtxForOthersAsInUpdate(
    locationId: number
  ): Promise<any> {
    this._hubService.sendToAllServiceUsers(
      HubMethods.LocationKtx.locationKtxInUpdate,
      new LocationKtxInUpdateHubMessage(locationId)
    );
  }

  public async markLocationKtxForOthersAsUpdated(
    location: LocationKtxViewModel,
    withChanges: boolean
  ): Promise<any> {
    this._hubService.sendToAllServiceUsers(
      HubMethods.LocationKtx.locationKtxUpdated,
      new LocationKtxUpdatedHubMessage(location, withChanges)
    );
  }

  public async markLocationKtxBinsForOthersAsInUpdate(
    deviceId: string
  ): Promise<any> {
    this._hubService.sendToAllServiceUsers(
      HubMethods.LocationKtx.locationKtxBinsInUpdate,
      new LocationKtxBinsInUpdateHubMessage(deviceId)
    );
  }

  public async markLocationKtxBinsForOthersAsUpdated(
    deviceId: string
  ): Promise<any> {
    this._hubService.sendToAllServiceUsers(
      HubMethods.LocationKtx.locationKtxBinsUpdated,
      new LocationKtxBinsInUpdateHubMessage(deviceId)
    );
  }

  public async getBinsForEditConnection(locationId: number) {
    try {
      const data = await this._locationsKtxApi.getBinsForEditConnection(locationId);
      this.binsConnections$.next(data as BinConnectionViewModel[]);
      this.errors$.next(null);
    } catch (error) {
      this.errors$.next(error);
    }
  }

  public async editConnections(locationid: number, binsConnections: BinConnectionViewModel[]) {
    try {
      let binConnectionsPost = new EditBinConnectionPostModel(locationid, binsConnections);
      const result = await this._locationsKtxApi.editConnections(binConnectionsPost);
      if (!(result instanceof ErrorModel)) {
        this.locationsKtx$.next([...this.locationsKtx$.getValue().filter(i => i.id != locationid), result]);
        this.markLocationKtxForOthersAsUpdated(result, true);
      }
    }
    catch (error) {
      if (error instanceof ErrorModel) {
        return error;
      } else {
        this.errors$.next(error);
      }
    }
  }

  public async getCompaniesWithLocations(withBins = false) {
    try {
      const locations = this.companiesWithLocations$.getValue();
      if (!locations || locations && locations.length == 0) {
        const data = await this._locationsKtxApi.getCompaniesWithLocations(withBins);
        this.companiesWithLocations$.next(data as CompanyViewModel[]);
        this.errors$.next(null);
      }
      else this.companiesWithLocations$.next(locations);
    } catch (error) {
      this.errors$.next(error);
    }
  }

  private subscribeToHubService() {
    this._hubService.locationKtxAdded.subscribe(
      (hubMessage: LocationKtxAddedHubMessage) => {
        if (hubMessage && hubMessage.newLocationKtx) {
          this.locationsKtx$.next([
            ...this.locationsKtx$.getValue(),
            hubMessage.newLocationKtx,
          ]);
        }
      }
    );

    this._hubService.locationKtxInUpdate.subscribe(
      (hubMessage: LocationKtxInUpdateHubMessage) => {
        if (hubMessage) {
          const location = this.locationsKtx$
            .getValue()
            .find((l) => l.id === hubMessage.locationId);
          if (location) {
            location.isSomeoneUpdateLocation = true;
          }
        }
      }
    );
    this._hubService.locationKtxUpdated.subscribe(
      (hubMessage: LocationKtxUpdatedHubMessage) => {
        if (hubMessage && hubMessage.updatedLocationKtx) {
          if (hubMessage.withCahnges) {
            hubMessage.updatedLocationKtx.isSomeoneUpdateLocation = undefined;
            this.locationsKtx$.next(
              this.locationsKtx$.getValue().map((l) => {
                if (l.id === hubMessage.updatedLocationKtx.id) {
                  return hubMessage.updatedLocationKtx;
                } else {
                  return l;
                }
              })
            );
          } else {
            const location = this.locationsKtx$
              .getValue()
              .find((l) => l.id == hubMessage.updatedLocationKtx.id);
            location.isSomeoneUpdateLocation = false;
          }
        }
      }
    );
    this._hubService.locationDeleted.subscribe(
      (hubMessage: LocationKtxDeletedHubMessage) => {
        if (hubMessage) {
          const locations = this.locationsKtx$
            .getValue()
            .filter((l) => l.id !== hubMessage.locationId);
          this.locationsKtx$.next(locations);
        }
      }
    );
    this._hubService.locationKtxBinsUpdated.subscribe(
      (hubMessage: LocationKtxBinsUpdatedHubMessage) => {
        if (hubMessage) {
          const location = this.locationsKtx$
            .getValue()
            .find((l) => l.id === hubMessage.locationId);
          if (location) {
            location.isSomeoneUpdateLocation = false;
          }
        }
      }
    );
    this._hubService.locationKtxDeviceInInitialization.subscribe(
      (hubMessage: LocationKtxDeviceInInitializationHubMessage) => {
        if (hubMessage) {
          const location = this.locationsKtx$
            .getValue()
            .find((l) => l.id === hubMessage.locationId);
          if (location) {
            location.isDeviceInInitialization = true;
          }
        }
      }
    );
    this._hubService.locationKtxDeviceInitialized.subscribe(
      (hubMessage: LocationKtxDeviceInitializedHubMessage) => {
        if (hubMessage) {
          const location = this.locationsKtx$
            .getValue()
            .find((l) => l.id === hubMessage.locationId);
          if (location) {
            location.isDeviceInInitialization = false;
          }
        }
      }
    );
    this._hubService.temperatureRangeUpdated.subscribe((hubMessage: LocationKtxTemperatureRangeUpdatedHubMessage) => {
      if (hubMessage) {
        const location = this.locationsKtx$.getValue().find(x => x.id === hubMessage.updatedRanges.locationId);
        if (location) {
          location.temperatureRanges = hubMessage.updatedRanges.ranges;
          location.alarm = hubMessage.updatedRanges.alarm;
        }
      }
    });
  }

  private getFakeAddEditLocationKtxSchemeViewModel(): AddEditLocationKtxSchemeViewModel {
    const binsSchemes = [
      {
        name: 'bin 1',
        sysId: 1,
        switchType: SwitchTypeEnum.MUX
      },
      {
        name: 'bin 2',
        sysId: 1,
        switchTypeEnum: SwitchTypeEnum.MUX
      },
      {
        name: 'bin 3',
        sysId: 2,
        switchTypeEnum: SwitchTypeEnum.BCS_RT
      },
      {
        name: 'bin 4',
        sysId: 2,
        switchTypeEnum: SwitchTypeEnum.BCS_IICS
      },
      {
        name: 'bin 5',
        sysId: 3,
        switchTypeEnum: SwitchTypeEnum.BCS_AR
      },
    ];

    const locationScheme = {
      bins: binsSchemes,
    } as AddEditLocationKtxSchemeViewModel;

    return locationScheme;
  }

  clearBehaveCache() {
    this.companiesWithLocations$.next([]);
    this.locationsKtx$.next([]);
  }
}
