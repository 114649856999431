import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TreeNode } from 'primeng';

import {
  LocationsKtxDataSourceService,
  RoleService,
} from '@services';
import {
  KtxCurrentDataExportReportPopupComponent,
  KtxLocationAddEditPopupComponent,
  KtxRangeDeletePopupComponent,
  KtxTemperatureRangesPopupComponent,
  TutorialPopupComponent,
} from '@popups';
import { CommonFunctions, ConvertDataFunctions, UserRoleEnum } from '../../../common';

import {
  AlarmViewModel,
  BinSchemeViewModel,
  CompanyViewModel,
  LocationKtxSchemeViewModel,
  LocationKtxViewModel,
  TemperatureColorViewModel,
  TemperatureRangeViewModel,
} from '@models';
import { BinTypeEnum, SensorStatusEnum, TutorialMenuEnum } from '../../enums';
import {
  MainRoutePaths,
  SensorAlarmLabels,
} from '../../constants';

@Component({
  selector: 'greensleeves-ktx-current-data-tab',
  templateUrl: './ktx-current-data-tab.component.html',
})
export class KtxCurrentDataTabComponent
  implements OnInit, AfterViewInit, OnDestroy {
  static WINDOW_FUNCTION_GET_LOCATION_NAME = 'getLocation';
  static WINDOW_FUNCTION_SHOW_SCHEME_NAME = 'showScheme';
  static WINDOW_FUNCTION_BIN_DETAILS_CLICK_NAME = 'binDetailsClick';
  static WINDOW_LOCATION_BACKGROUND_COLOR = 'locationBackgroundColor';
  static WINDOW_FIELD_IS_WEB_NAME = 'isWeb';
  static BIN_UNKNOWN_COLOR = '#808080';

  public ktxCompaniesWithLocationsNodes: TreeNode[] = [];
  public selectedLocationNode: TreeNode;
  public locationBinsNodes: TreeNode[] = [];
  public selectedLocationBinNode: TreeNode;
  public isOpenLocationList = false;
  public locationScheme: LocationKtxSchemeViewModel;
  public isSchemeLoading = true;
  public isSchemeHidden = true;

  private locations: LocationKtxViewModel[];
  private width: number;
  private height: number;
  private locationBackgroundColor = '#f5f5f5';
  private subscriptions: Subscription[] = [];
  private destroy$ = new Subject<void>();
  private showScheme$ = new BehaviorSubject<boolean>(false);
  private onBinDetailsClick$ = new BehaviorSubject<number>(0);
  private onDetailsClick = new EventEmitter<BinSchemeViewModel>();

  @ViewChild('locationModel', { static: false })
  locationModel: ElementRef;
  @ViewChild('fullScreenContainer', { static: false })
  fullScreenContainer: ElementRef;
  @ViewChild('locationFrame', { static: false })
  locationFrame: ElementRef;
  @ViewChild(KtxTemperatureRangesPopupComponent, { read: false, static: false })
  private _temperatureRangesModal: KtxTemperatureRangesPopupComponent;
  @ViewChild(KtxRangeDeletePopupComponent, { read: false, static: false })
  private _rangeDeleteModal: KtxRangeDeletePopupComponent;
  @ViewChild(KtxLocationAddEditPopupComponent, { read: false, static: false })
  private _addEditModal: KtxLocationAddEditPopupComponent;
  @ViewChild(KtxCurrentDataExportReportPopupComponent, {
    read: false,
    static: false,
  })
  exportReportModal: KtxCurrentDataExportReportPopupComponent;
  @ViewChild(TutorialPopupComponent, { read: false, static: false })
  private tutorialPopup: TutorialPopupComponent;
  _loading: Boolean = false;
  _temperatureRanges: TemperatureColorViewModel[];
  get isTutorialUser(): boolean {
    return this._roleService.userHasRole(UserRoleEnum.CompanyAdmin) || this._roleService.userHasRole(UserRoleEnum.CompanyUser);
  }
  constructor(
    private _el: ElementRef,
    private _ktxLocationService: LocationsKtxDataSourceService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _zone: NgZone,
    private _roleService: RoleService,
  ) { }

  get currentLocationName(): string {
    return this.locationScheme ? this.locationScheme.locationName : '';
  }

  get currentLocationNameWithCompany(): string {
    if (!this.selectedLocationNode) {
      return '';
    }

    const { label, parent } = this.selectedLocationNode;

    if (!this.isServiceUser) {
      return label;
    }

    return parent ? `${parent.label}: ${label}` : label;
  }

  get isCompanyAdmin(): boolean {
    return this._roleService.userHasRole(UserRoleEnum.CompanyAdmin);
  }

  get isServiceUser(): boolean {
    return this._roleService.userHasRole(UserRoleEnum.ServiceUser);
  }

  get isDisplayableLocationScheme(): boolean {
    if (!this.locationScheme) {
      return false;
    }
    const { width, depth, displayScheme } = this.locationScheme;

    if (!width || !depth) {
      return false;
    }

    return displayScheme;
  }

  ngOnInit() {
    this.setSubscriptions();

    window[KtxCurrentDataTabComponent.WINDOW_FUNCTION_GET_LOCATION_NAME] =
      () => {
        return this._ktxLocationService.locationScheme$;
      };
    window[KtxCurrentDataTabComponent.WINDOW_FUNCTION_SHOW_SCHEME_NAME] =
      () => {
        return this.showScheme$;
      };

    window[KtxCurrentDataTabComponent.WINDOW_FUNCTION_BIN_DETAILS_CLICK_NAME] =
      () => {
        return this.onBinDetailsClick$;
      };

    window[KtxCurrentDataTabComponent.WINDOW_FIELD_IS_WEB_NAME] = true;

    window[KtxCurrentDataTabComponent.WINDOW_LOCATION_BACKGROUND_COLOR] =
      this.locationBackgroundColor;

    this.onBinDetailsClick$
      .pipe(takeUntil(this.destroy$))
      .subscribe((id: number) => {
        if (id) {
          let bins: BinSchemeViewModel[] = [];

          this.locationScheme.binSchemes.forEach(
            (binScheme: BinSchemeViewModel) => {
              if (binScheme.binType === BinTypeEnum.Unit) {
                binScheme.bins.forEach((bin) => bins.push(bin));
              } else {
                bins.push(binScheme);
              }
            }
          );

          const bin = bins.find((x) => x.id === id);
          if (!bin.isDecorBin) {
            this.onClickDetails(bin);
          }
        }
      });
  }

  ngAfterViewInit() {
    this.setPopupSubscriptions();

    this.width = this.locationFrame.nativeElement.clientWidth;
    this.height = this.locationFrame.nativeElement.clientHeight;

    this.locationModel.nativeElement.setAttribute('width', this.width);
    this.locationModel.nativeElement.setAttribute('height', this.height);
  }

  onIframeLoad() {
    this.isSchemeLoading = false;
  }

  showFullScreen() {
    const percentageCorrelation = 128.9;
    const height = Math.floor((this.height * percentageCorrelation) / 100);
    const width = Math.floor((this.width * percentageCorrelation) / 100);

    this.isSchemeLoading = true;
    this.isSchemeHidden = false;
    this.fullScreenContainer.nativeElement.appendChild(
      this.locationModel.nativeElement
    );
    this.locationModel.nativeElement.setAttribute('height', height);
    this.locationModel.nativeElement.setAttribute('width', width);
  }

  onFullScreenClose() {
    this.isSchemeHidden = true;
    this.isSchemeLoading = true;
    const modelHolder =
      this._el.nativeElement.querySelector('.location-iframe');
    modelHolder.appendChild(this.locationModel.nativeElement);
    this.locationModel.nativeElement.setAttribute('height', this.height);
    this.locationModel.nativeElement.setAttribute('width', this.width);
  }

  onClickDetails(binScheme: BinSchemeViewModel) {
    this._zone.run(() => {
      this.redirectToBin(binScheme);
      this.onDetailsClick.emit(binScheme);
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.clearSubscriptions();
  }

  setFirstLocationNode(): void {
    if (!this.ktxCompaniesWithLocationsNodes) {
      return;
    }

    const companyNodeWithLocations = this.ktxCompaniesWithLocationsNodes.find(
      (companyNode) => companyNode.children && companyNode.children.length > 0
    );

    if (!companyNodeWithLocations) {
      return;
    }

    this.selectedLocationNode = companyNodeWithLocations.children[0];
    this.selectedLocationNode.parent.expanded = true;
  }

  setLocationNodeById(locationId: number) {
    if (!this.ktxCompaniesWithLocationsNodes) {
      return;
    }

    const mergedLocations: TreeNode[] =
      this.ktxCompaniesWithLocationsNodes.reduce(
        (result, companyNode) => result.concat(companyNode.children),
        []
      );

    const locationNode = mergedLocations.find((l) => +l.data === locationId);

    if (!locationNode) {
      return;
    }

    this.selectedLocationNode = locationNode;
    locationNode.parent.expanded = true;
  }

  loadLocationScheme(locationId: number) {
    this._loading = true;
    this.locationScheme = null;
    this.showScheme$.next(false);
    this.locationBinsNodes = [];
    this._ktxLocationService
      .getScheme(locationId, true)
      .then((_) => {
        this._loading = false;
        this.showScheme$.next(true);
      });
  }

  initLocationBinsNodes(locationScheme: LocationKtxSchemeViewModel) {
    this.locationBinsNodes =
      LocationKtxSchemeViewModel.toBinsTreeNode(locationScheme);
  }

  initCompanyWithLocationsNodes(companies: CompanyViewModel[]) {
    const companyWithLocationNodes =
      companies &&
      companies
        .map((company) => {
          const companyWithLocationNode: TreeNode = {
            label: company.name,
            selectable: false,
            expanded: false,
          };

          companyWithLocationNode.children = company.locations
            .filter(l => l.bins && l.bins.length > 0)
            .map<TreeNode>((location) => {
              const locationNode: TreeNode = {
                label: location.name,
                data: location.id,
                selectable: true,
                parent: companyWithLocationNode,
              };

              return locationNode;
            })
            .sort(CommonFunctions.compareTreeNodeLabels);

          return companyWithLocationNode;
        })
        .filter(c => c.children && c.children.length > 0)
        .sort(CommonFunctions.compareTreeNodeLabels);

    this.ktxCompaniesWithLocationsNodes = companyWithLocationNodes;
  }

  onClickLocationDetails() {
    this._router.navigate(
      [`../${MainRoutePaths.KTX_LOCATION_DETAILS}`],
      {
        relativeTo: this._route,
      }
    );
  }

  redirectToBin(binScheme: BinSchemeViewModel) {
    this._router.navigate(
      [`../${MainRoutePaths.KTX_BIN_DETAILS}`, binScheme.id],
      {
        relativeTo: this._route,
      }
    );
  }

  onSelectLocationNode(event: any) {
    const { node } = event;

    if (node) {
      this.loadLocationScheme(node.data);
      this.isOpenLocationList = false;
    }
  }

  onClickLocationBins(event: any) {
    const { node } = event;
    let binScheme: BinSchemeViewModel;

    binScheme = this.locationScheme.binSchemes.find(
      (bs) => bs.id === Number(node.data)
    );

    if (!binScheme) {
      const mergedBinSchemes: BinSchemeViewModel[] =
        this.locationScheme.binSchemes.reduce(
          (result, bs) => result.concat(bs.bins ? bs.bins : []),
          []
        );

      binScheme = mergedBinSchemes.find(
        (bs) => bs.id === Number(node.data)
      );
    }

    this.redirectToBin(binScheme);
  }

  openLocationList(): void {
    this.isOpenLocationList = !this.isOpenLocationList;
  }

  clearSubscriptions(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.subscriptions = null;
  }

  setSubscriptions(): void {
    const locationSchemeSubscription =
      this._ktxLocationService.locationScheme$.subscribe((locationScheme) => {
        this.initLocationBinsNodes(locationScheme);
        this.locationScheme = (locationScheme != null && locationScheme.displayScheme ? this.fillBinAverageColorForSchema(locationScheme) : locationScheme);
        if (this.locationScheme) {
          this._temperatureRanges = this.setTemperatureColorRanges(locationScheme.temperatureRanges, locationScheme.alarm);
        }
        else {
          this._temperatureRanges = [];
        }
      });

    const companiesSubscription =
      this._ktxLocationService.companiesWithLocations$.subscribe(
        (companies) => {
          this.initCompanyWithLocationsNodes(companies);
          if (this.locationScheme) {
            this.setLocationNodeById(this.locationScheme.locationId);
          }

          if (!this.selectedLocationNode) {
            this.setFirstLocationNode();
          }

          if (this.selectedLocationNode) {
            this.loadLocationScheme(this.selectedLocationNode.data);
            this.isOpenLocationList = false;
          }
        }
      );

    const locationsSubscription =
      this._ktxLocationService.locationsKtx$.subscribe((locations) => {
        this.locations = locations;
      });

    const withBins = true;
    if (!this.ktxCompaniesWithLocationsNodes || this.ktxCompaniesWithLocationsNodes.length == 0) {
      this._ktxLocationService.getCompaniesWithLocations(withBins);
    }
    this._ktxLocationService.get();

    this.subscriptions.push(
      companiesSubscription,
      locationSchemeSubscription,
      locationsSubscription
    );
  }

  setPopupSubscriptions() {
    const rangeDeleteSubscription =
      this._temperatureRangesModal.onClickRangeDelete.subscribe(
        (range: TemperatureRangeViewModel) => {
          this._rangeDeleteModal.show(range);
        }
      );

    const rangeDeletedSubscription = this._rangeDeleteModal.onSubmit$.subscribe(
      (range: TemperatureRangeViewModel) => {
        this._temperatureRangesModal.removeRange(range);
      }
    );

    this.subscriptions.push(rangeDeleteSubscription, rangeDeletedSubscription);
  }

  showEdit() {
    const currentLocation = this.getCurrentLocation();

    this._addEditModal.showEdit(currentLocation);
  }

  showTemperatureRanges() {
    const currentLocation = this.getCurrentLocation();
    this.showScheme$.next(false);
    this._temperatureRangesModal.show(currentLocation);
  }

  getCurrentLocation(): LocationKtxViewModel | null {
    if (!this.locationScheme) {
      return null;
    }

    const { locationId } = this.locationScheme;
    const currentLocation = this.locations.find((l) => l.id === locationId);

    return currentLocation;
  }

  onClickExportReport() {
    this.exportReportModal.show(this.locationScheme, 1);
  }

  showTutorial() {
    this.tutorialPopup.showPopup(TutorialMenuEnum.KtxCurrentData);
  }

  onClosePopup() {
    this.showScheme$.next(true);
  }

  private fillBinAverageColorForSchema(schema: LocationKtxSchemeViewModel): LocationKtxSchemeViewModel {
    schema.binSchemes.forEach(x => {
      this.fillBinColor(x, schema.temperatureRanges, schema.alarm);
    });
    return schema;
  }

  private fillBinColor(bin: BinSchemeViewModel, temperatureRanges: TemperatureRangeViewModel[], alarm: AlarmViewModel) {
    if (bin.binType == BinTypeEnum.Unit) {
      bin.bins.forEach(x => this.fillBinColor(x, temperatureRanges, alarm));
    }
    else {
      if (bin.cablesAverageAlarm != null && bin.cablesAverageAlarm == SensorStatusEnum.HighLimit) {
        bin.binColor = alarm.highTemperatureColorHex;
      }
      else if (bin.averageBinTemperature != null) {
        const color = temperatureRanges.find(c => bin.averageBinTemperature >= c.from && bin.averageBinTemperature <= c.to);
        bin.binColor = color ? color.colorHex : KtxCurrentDataTabComponent.BIN_UNKNOWN_COLOR;
      }
    }
  }

  private onCelsiusToFahrenheit(value: number): string {
    if (value || value === 0) {
      const convertedValue = ConvertDataFunctions.celsiusToFahrenheit(value);
      return convertedValue.toString();
    } else {
      return '-';
    }
  }

  private setTemperatureColorRanges(temperatureRanges: TemperatureRangeViewModel[], alarm: AlarmViewModel): TemperatureColorViewModel[] {
    if (!temperatureRanges) {
      return [];
    }

    const temperatures: TemperatureColorViewModel[] = temperatureRanges.map<TemperatureColorViewModel>((tr) => ({
      value: this.onCelsiusToFahrenheit(tr.to),
      colorHex: tr.colorHex,
    }))

    return [
      {
        colorHex: KtxCurrentDataTabComponent.BIN_UNKNOWN_COLOR,
        value: SensorAlarmLabels.Unknown,
      },
      ...temperatures,
      {
        value: SensorAlarmLabels.HighLimit,
        colorHex: alarm.highTemperatureColorHex,
      },
    ];
  }
}
