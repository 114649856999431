import jwt_decode from 'jwt-decode';
import { TreeNode } from 'primeng';
import { UserRoleEnum } from '../enums';
import { ProjectEnum, SensorTypeEnum } from '../../main/enums';


export class CommonFunctions {
    public static checkIfTimeNotExpired(expiresAt: number): boolean {
        const dateTimeNowInSeconds = Date.now() / 1000;
        return expiresAt - 60 > dateTimeNowInSeconds;
    }

    public static getRoleFromInvitationToken(token: string): UserRoleEnum {
        try {
            const tokenInfo = jwt_decode(token);
            return this.convertRoleStringToUserRoleEnum(tokenInfo['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']);
        }
        catch (Error) {
            console.log(Error);
            return null;
        }
    }

    public static containsProject(projects: number, project: ProjectEnum): boolean {
        return (projects & project) === project;
    }

    public static compareTreeNodeLabels(a: TreeNode, b: TreeNode): number {
        return a.label.localeCompare(b.label, undefined, {
            numeric: true,
            sensitivity: 'base',
        });
    }

    public static compareStrings(a: string, b: string): number {
        return a.localeCompare(b, undefined, {
            numeric: true,
            sensitivity: 'base',
        });
    }

    private static convertRoleStringToUserRoleEnum(role: string): UserRoleEnum {
        if (role === 'Vendor') {
            return UserRoleEnum.Vendor;
        }
        else if (role === 'CompanyAdmin') {
            return UserRoleEnum.CompanyAdmin;
        }
        else if (role === 'CompanyUser') {
            return UserRoleEnum.CompanyUser;
        }
        else if (role === 'ServiceUser') {
            return UserRoleEnum.ServiceUser;
        }
        else if (role === 'SalesUser') {
            return UserRoleEnum.SalesUser
        }
    }

    public static btxGetSensorMeasure(sensorType: SensorTypeEnum): string {
        switch (sensorType) {
            case SensorTypeEnum.CurrentDesignLoad:
                return ' %';
            case SensorTypeEnum.CurrentAmpDraw:
                return ' A';
            case SensorTypeEnum.Vibration:
                return ' in/sec';
            case SensorTypeEnum.Speed:
                return ' rpm'
            case SensorTypeEnum.GrainDepth:
                return ' ft';
            default:
                return ' °F';
        }
    }
}